import { CommonUtilities } from "commonUtils";
import Tooltip from "rc-tooltip";
import React, { Suspense, useState, useEffect } from "react";
import { Modal } from 'react-responsive-modal';
//import DspPagination from "./DspPagination";


export default function YoutubeScraping({ onSelectItem }: any) {

    const [showtab, setShowTab] = useState(false);
    const [apiKey, setApiKey] = useState('AIzaSyDVgU_LoNqub0cTeT9pVvueMJRAxK0sabc');
    const [resourceCall, setResourceCall] = useState('https://www.googleapis.com/youtube/v3/search?part=snippet&type=video&q=');
    const [requestJSON, setRequestJSON] = useState('Taylor Swift');
    const [resourceResponse, setResourceResponse] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [nextPageToken, setNextPageToken] = useState('');

    //part should be always snippet
    //type can be either channel, playlist or video

    async function searchData() {
        const url = `${resourceCall}${encodeURIComponent(requestJSON)}&key=${apiKey}&pageToken=${nextPageToken}`;
    
        const youtubeParameters = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        };
    
        const data: any = await fetch(url, youtubeParameters)
          .then((response) => response.json())
          .then((data) => YouTubeResponse(data));
    
        if (data && data.pageInfo) {
         
          const totalItems = data.pageInfo.totalResults || 0;
          setTotalItems(totalItems);
        }
    
        if (data && data.nextPageToken) {
          setNextPageToken(data.nextPageToken);
        } else {
          setNextPageToken('');
        }
      }

    const YouTubeResponse = (data: any) => {
        if (typeof data.items !== 'undefined') {
            setResourceResponse(
                data.items.map((item: any) => ({
                    title: item.snippet.title,
                    thumbnail: item.snippet.thumbnails.default.url,
                    channelTitle: item.snippet.channelTitle,
                    publishedAt: item.snippet.publishedAt,
                    description: item.snippet.description
                }))
            );
        } else {
            setResourceResponse([]);
        }
    };

    const handleLinkButtonClick = (item: any) => {

        onSelectItem(item);
    };

    const handlePageChange = () => {
        setCurrentPage(currentPage + 1);
        searchData();
      };



    return (
        <div className="pull-left ws-12">
            <div className="box">
                <div className="box-header">
                    Youtube
                </div>
                <div className="box-content mtl ptl">

                    <input type="text" className="ws-12 mtl" value={resourceCall} onChange={(e) => setResourceCall(e.target.value)} />

                    <input type="text" className="ws-12 mtll" value={requestJSON} onChange={(e) => setRequestJSON(e.target.value)} />

                </div>

                {resourceResponse && (
                    <div>
                        {resourceResponse.map((item: any, index: any) => (
                            <div className="creatorImg pm pull-left" key={index}>

                                <div className="box shadowCoverPicture shadow miniCreatorImg">
                                    <div className="coverPicture">
                                        <div className="labelOverlay mt">
                                            <i onClick={() => handleLinkButtonClick(item)} className="icon-LinkMade text-m white text-Shadow ml"></i>
                                        </div>

                                        <Tooltip placement="right" overlayStyle={{ width: '400px', height: '50px' }} trigger={['hover']} overlay={
                                            <div className='box'>
                                                <div className='box-header'>
                                                    Track Details
                                                </div>
                                                <div className='box-content'>
                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>Track Title:</span> {item.title}
                                                    </div>
                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>Channel Title:</span>{item.channelTitle}
                                                    </div>
                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>Published on:</span>{item.publishedAt}
                                                    </div>

                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>Description:</span>{item.description}
                                                    </div>

                                                </div>
                                            </div>}>

                                            <i className="icon-info1 pull-right positionAbsolute rightReset mr mt text-m white text-Shadow"> </i>

                                        </Tooltip>

                                        <img src={item.thumbnail} alt={item.title} />

                                        <div className="bottomOverlayLabel">
                                            <label className="control-label ws-12">{CommonUtilities.longCharacter(item.title, 18)}</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}  
                            {/* <DspPagination onChange={handlePageChange} totalRows={totalItems} /> */}
                    </div>
                    
                )}


                <div className="box-footer">
                    <button className="btn-success" onClick={() => searchData()}>
                        Request
                    </button>
                </div>
            </div>
        </div>
    )
}

import { CommonUtilities } from '../../commonUtils';

export default class commonServices {
    static listMyassetContracts(param: any) {
        var url = 'catalogue/projectAsset/listMyassetContracts?' + CommonUtilities.buildQueryString(param, true, true)
        return CommonUtilities.getData(url);
    }

    //https://qa.onkorrect.com/Api/catalogue/projectAsset/listMyassetContracts?assetType=songTrack&pageNumber=1&pageSize=10&songCode=00000000023264

    /* static listMyassetContracts(param : any) {
         var url = 'catalogue/projectAsset/listMyassetContracts?' + CommonUtilities.buildQueryString(param , true , true)
         return CommonUtilities.getData(url);
     }*/

    //https://qa.onkorrect.com/Api/catalogue/projectAsset/listMyassetContracts?assetType=songTrack&export=1&songCode=00000000024184
    static export(param: any) {
        var url = 'catalogue/projectAsset/listMyassetContracts?' + CommonUtilities.buildQueryString(param, true, true)
        return CommonUtilities.getData(url);
    }

    //https://staging.onkorrect.com/Api/contacts/getContactDetails?pageNumber=1&pageSize=10&
    static getContactDetails(param: any) {
        var url = 'contacts/getContactDetails?' + CommonUtilities.buildQueryString(param, true, true)
        return CommonUtilities.getData(url);
    }

    //https://qa.onkorrect.com/Api/contracts/AllRegions
    static AllRegions() {
        var url = 'contracts/AllRegions';
        return CommonUtilities.getData(url);
    }

    //https://qa.onkorrect.com/Api/contracts/systemTerritoryCode/?regionCode=EU
    static systemTerritoryCode(data: any) {
        var url = 'contracts/systemTerritoryCode/?' + CommonUtilities.buildQueryString(data, true, true)
        return CommonUtilities.getData(url);
    }

    static getContainerDetails(data: any) {
        var url = 'royalty/kor_TRANS_Settings/listSettings?' + CommonUtilities.buildQueryString(data, true, true);

        return CommonUtilities.getData(url);
    }

    static getProjectMaps(data: any) {
        var url = 'royalty/kor_TRANS_Settings/list?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    }

    static getreports(data: any) {
        var url = 'reports/type1,type4,type6?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    }

    static getSearchContainerCodes(data: any) {
        var url = 'royalty/kor_TRANS_Containers/listContainerCodes?ContainerType=' + data.containerType + '&option=' + data.dimType + '&projectId=' + data.projectId + '&pageNumber=' + data.pageNumber + '&pageSize=' + data.pageSize + '&assetType=' + data.assetType;

        if (data.code) {

            url += '&code=' + data.code;
        }
        else if (data.name) {

            url += '&name=' + data.name;
        }
        else if (data.artist) {
            url += '&artist=' + data.artist;
        }
        return CommonUtilities.getData(url);
    }

    //services for container save
    static linkContainer(data: any) {
        var url = 'royalty/kor_TRANS_Containers/save';
        return CommonUtilities.postData(url, data);
    }

    static saveTransaction(data: any) {
        var url = 'royalty/kor_TRANS_Settings/save';
        return CommonUtilities.postData(url, data);
    }

    static saveProjectMap(data: any) {
        var url = 'royalty/kor_TRANS_ProjectMap/save';
        return CommonUtilities.postData(url, data);
    }

    static unlinkContainerCode(id: any) {
        var url = 'royalty/kor_TRANS_Containers/delete/' + id;
        return CommonUtilities.deleteData(url);
    }

    static unlinkPerContainerCodes(data: any) {
        var url = 'royalty/kor_TRANS_Containers/resetContainer?containerType=' + data.containerType + '&transactionRef=' + data.transactionRef;;
        return CommonUtilities.deleteData(url);
    }

    static financialPerformationExport(data: any) {
        const queryParams = new URLSearchParams(data).toString();
        const url = `contracts/financialPerformance?${queryParams}`;
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', data.type);
    };

    static masterReleaseListing(param: any) {
        var url = 'metadata/master/search?' + CommonUtilities.buildQueryString(param, true, true);
        return CommonUtilities.getData(url);
    }

    static currencyListing(param: any) {
        var url = 'contracts/currencyListing?' + CommonUtilities.buildQueryString(param, true, true);
        return CommonUtilities.getData(url);
    }

    static listContainerCodes(param: any) {
        var url = 'royalty/kor_TRANS_Containers/listContainerCodes?' + CommonUtilities.buildQueryString(param, true, true);
        return CommonUtilities.getData(url);
    }

    static MonitorDetail(data: any) {
        var url = 'common/monitoring/detail';
        return CommonUtilities.postData(url, data);
    }

    static MonitorListing(data: any) {
        var url = 'common/monitoring';
        return CommonUtilities.postData(url, data);
    }

    static MonitorListingExport(data: any , filename :any) {
        var url = 'common/monitoring/Export';
        return CommonUtilities.postExport(url, data , filename);
    }

    static freeTextallSocieties(param: any) {
        var url = 'contracts/freeText/?' + CommonUtilities.buildQueryString(param, true, true);
        return CommonUtilities.getData(url);
    }

    static freeTextallSocietiesSave(param: any) {
        var url = 'contracts/freeText';
        return CommonUtilities.postData(url, param);
    }

    static freeTextallSocietiesDelete(paramId: any) {
        var url = 'contracts/freeText/' +paramId;
        return CommonUtilities.deleteData(url);
    }

    static saveTheme(param: any) {
        var url = 'DefaultValue/mutipleSave';
        return CommonUtilities.postData(url, param);
    }
    
    static loadTheme() {
        var url = 'DefaultValue/list';
        return CommonUtilities.getData(url);
    }
}
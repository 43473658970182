import React, { Suspense, useState, useEffect, useRef } from "react";
import { Modal } from 'react-responsive-modal';
import Pagination from "../Header/Pagination"
import { format } from "date-fns";
import DeleteDialogueBox from "../Common/deleteDialogue";
import ClientProfileSevices from "./clientProfileServices";

export default function CurrentJobs(param: any) {

    const [openFirst, setOpenFirst] = React.useState(false);
    const [currentList, initCurrentlist] = useState<any[]>([]);
    const [showtab, setShowTab] = useState(1);
    var dataLink = "pageSize=10&pageNumber=1";
    const [totalRows, setTotalRows] = useState(1);
    const [deleteJob, setDelete] = useState(0);

    const Data: any = {
        pageSize: 10,
        pageNumber: 1,
        totalRows: 0
    }

    const handleNameOnChange = (n: any) => {
        Data.pageNumber = n.pageNumber;
        Data.pageSize = n.pageSize;
        dataLink = 'pageSize=' + Data.pageSize + '&pageNumber=' + Data.pageNumber;
        currentPage(showtab)
    }

    const currentPage = (e: React.SetStateAction<number>) => {
        setShowTab(e);

        if (e == 1) {
            ClientProfileSevices.CurrentJobs(dataLink)
                .then((res) => {
                    initCurrentlist(res)
                    if (res.length < 1) { setTotalRows(0) }
                    else { setTotalRows(res[0].totalRows); }
                })

        } else if (e == 2) {

            ClientProfileSevices.AsynchronousJobs(dataLink)
                .then((res) => {
                    initCurrentlist(res)
                    if (res.length < 1) { setTotalRows(0) }
                    else { setTotalRows(res[0].totalRows); }
                })

        } else if (e == 3) {
            ClientProfileSevices.EmailStatementJobs(dataLink)
                .then((res) => {
                    initCurrentlist(res)
                    setTotalRows(res[0].totalRows);
                })
        }
    }

    const Export = (param: any) => {
        console.log("data")
        ClientProfileSevices.CurrentJobsExport(param);
    }

    const Refresh = (e: any) => {

        dataLink = 'pageSize=10&pageNumber=1';
        Data.pageNumber = 1;
        Data.pageSize = 10;
        handleNameOnChange(Data);

    }

    const Confirmation = (e: any) => {
        if (e == 1) {
            if (showtab == 1) {
                ClientProfileSevices.CurrentJobsClear({ "include": "1|2", jobId: '' });
            } else if (showtab == 2) {

            } else if (showtab == 3) {
                ClientProfileSevices.CurrentJobsClear({ "include": "3", jobId: '' });
            }
        }
        setDelete(0);
        Refresh(showtab);
    }

    return (

        <>

            <div className={param.type != "calculation" ? "box-primary ms text-center iconLogo pull-left  mrl" : "hide"}>
                <i className="box icon-tag2" onClick={() => { setOpenFirst(true); currentPage(showtab); }}></i>
                <span className="m0 pts ">Job Status</span>
            </div>

            <div className={param.type == "calculation" ? "box-primary prx pts  pull-right  mrl" : "hide"}>
                <a className="white text-underline" onClick={() => { setOpenFirst(true); currentPage(showtab); }}>Job Status</a>
            </div>

            <Modal open={openFirst} onClose={() => setOpenFirst(false)} center>



                <div className="box full-height" style={{ maxWidth: "1300px", minWidth: "1300px", minHeight: "500px" }}>
                    <div className="box-content full-height">

                        <ul className="nav nav-tabs nav-justified mbl">
                            <li id="1" className={showtab === 1 ? "btn active" : "btn "} onClick={() => currentPage(1)}> Current Job </li>
                            <li id="2" className={showtab === 2 ? "btn active" : "btn "} onClick={() => currentPage(2)}> Asynchronous Job </li>
                            <li id="3" className={showtab === 3 ? "btn active" : "btn "} onClick={() => currentPage(3)}> Email Statement Job  </li>
                        </ul>
                        <div className="box">
                            <div className="box-header well">
                                <h2 className="pull-left "><i className="fa fa-tasks"></i>
                                    {showtab == 1 ? "Current Jobs Status" : showtab == 2 ? "Asynchronous Job Status" : "Email Statement Status"}({totalRows})</h2>
                                <div className="pull-right"><button className="btn btn-pink" onClick={() => Refresh(showtab)}>
                                    <i className="icon-refresh1"></i></button>
                                </div>
                            </div>
                            {
                                (() => {

                                    if (totalRows > 0) {
                                        return (

                                            <>
                                                <div className="box-content">

                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Time</th>
                                                                <th>Name</th>
                                                                <th>Date Created</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {currentList.map(post => (
                                                                <tr>
                                                                    <td>{post.timeEntered} </td>
                                                                    <td> {post.actionCompleted}</td>
                                                                    <td>{format(new Date(post.dateCreated), 'MMM dd, yyyy')} </td>
                                                                </tr>

                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>

                                                {
                                                    (() => {
                                                        if (totalRows > 1 && totalRows > 10) {
                                                            return <div className="pll ml"> <Pagination onChange={handleNameOnChange} totalRows={totalRows} /></div>
                                                        }

                                                    })()

                                                }
                                                <div className="box-footer">
                                                    {showtab != 3 && <button className="btn btn-info btn-sm " onClick={() => setDelete(1)}>Clear All Jobs</button>}
                                                    {showtab === 1 && <button className="btn btn-success btn-sm " onClick={() => Export("1|2")}><i className="fa fa-download"></i> Export All Jobs</button>}
                                                    {showtab === 3 && <button className="btn btn-success btn-sm " onClick={() => Export("3")}><i className="fa fa-download"></i> Export All Jobs</button>}
                                                    {showtab === 5 && <button className="btn btn-success btn-sm " onClick={() => Export("1|2")}><i className="fa fa-download"></i> Export All Jobs</button>}
                                                    <button className="btn btn-danger btn-sm " onClick={() => setOpenFirst(false)}> Close</button>
                                                </div>
                                            </>

                                        )
                                    } else {
                                        return (
                                            <div className="box ptl pLarge">
                                                {showtab === 1 && <span className="alert alert-success ws-12 text-center ptl">No sql jobs running</span>}
                                                {showtab === 2 && <span className="alert alert-success ws-12 text-center">No current jobs available</span>}
                                                {showtab === 3 && <span className="alert alert-success ws-12 text-center">No email statement record</span>}
                                            </div>)
                                    }
                                })()
                            }


                            {
                                (() => {
                                    if (deleteJob && deleteJob == 1) {
                                        return <DeleteDialogueBox param={true} message="all jobs" onChange={Confirmation} />
                                    }
                                })()
                            }
                        </div>
                    </div>
                </div>
            </Modal>

        </>

    )
}
import React, { useEffect, useState } from "react";
import clientProfileServices from "../clientProfileServices";
import DspDetail from "../dspDetail";
import Pagination from "../../../Header/Pagination";


export default function DSP_Management(param:any) {

    const [saveAlert , setsaveAlert] = useState(false);
    const [saveError , setsaveError] = useState(false);
    const [deleteAlert , setdeleteAlert] = useState(false);   
 
    let [ClientDSPSearch, initClientDSPSearch] = useState<any[]>([]);
    let [DSPSearch, initDSPSearch] = useState<any[]>([]);
    let [ClientDSP, initClientDSP] = useState<any[]>([]);
    let [DSP, initDSP] = useState<any[]>([]);

    const [avalaiblesearchLength , setavalaibleSearchLength] = useState(1);
    const [avalaiblelinkedtotalRows , setavalaibleTotalRows] = useState(1);
    const [linkedsearchLength , setlinkedSearchLength] = useState(1);
    const [linkedtotalRows , setlinkedTotalRows] = useState(1);
    const [dspDetail , setdspDetail] = useState(0);

    const [selectedDSP, initselectedDSP] = useState<any[]>([]);
    const [ListDSP, initListDSP] = useState<any[]>([]);

    const [searchField , initSearchField] = useState("");
    const [searchFieldLinked , initSearchFieldLink] = useState("");

    useEffect(() => {
        if(param.param == 'open'){
            apiCall();
        }
    }, []);
    
    const apiCall = () => {
        clientProfileServices.getListDSP({ }).then((res) => {
            initListDSP(res)
        });

        clientProfileServices.getDSP().then((res) => {
            initDSP(res);
            initDSPSearch(res);

            if(res.length > 0){
                DSP = res ;
                var obj = DSP.slice(0,10) 
                initDSP(obj)   
                setavalaibleSearchLength(res.length);
                setavalaibleTotalRows(res.length)
            }
        });

        clientProfileServices. getClientDSP().then((res) => {
            initClientDSP(res)            
            initClientDSPSearch(res)
            setlinkedTotalRows(res.length)
            setlinkedSearchLength(res.length)
          
            if(res.length > 0){
                ClientDSP = res;
                var obj = ClientDSP.slice(0,10) 
                initClientDSP(obj)  
            }
        });
    }

    const exportDspList = () =>{
        clientProfileServices.dspExport();
    }
    const exportDspTemplate = () =>{
        clientProfileServices.dspTemplateExport();
    }

    const Confirmation = (e :any) =>{ 
        setdspDetail(0)
    }

    const linkedSearch = (e:any) => {
        if(e != ""){
            ClientDSP = ClientDSPSearch;
            var obj = ClientDSP.filter((item) => {return item.name.toLocaleLowerCase().includes(e.toLocaleLowerCase())})
            ClientDSP = obj;
            initClientDSP(ClientDSP);
            setlinkedSearchLength(ClientDSP.length)
        }else{
            initClientDSP(ClientDSPSearch);
            setlinkedSearchLength(ClientDSPSearch.length)
        }

    }

    const availableSearch = (e:any) => {
        if(e != ""){
            DSP = DSPSearch;
            var obj = DSP.filter((item) => {return item.name.toLocaleLowerCase().includes(e.toLocaleLowerCase())})
            DSP = obj;
            initDSP(DSP);
            setavalaibleSearchLength(DSP.length)
        }else{
            initDSP(DSPSearch);
            setavalaibleSearchLength(DSPSearch.length)
        }
    }

    const linkDSP = (e:any) => {
        var obj = DSP.filter((item) => {return item.digitalServiceProviderId != (e.digitalServiceProviderId)})
        
        DSP = obj
        initDSP(DSP)
        initClientDSP([...ClientDSP ,e])

        /*var obj2 = ClientDSPSearch.filter((item) => {return item.digitalServiceProviderId != (e.digitalServiceProviderId)})
        ClientDSPSearch = obj2;*/
        
        initClientDSPSearch([...ClientDSPSearch,e])
        console.log(ClientDSPSearch)
    }
    
    const UnlinkedlinkDSP = (e:any) => {
        var obj = ClientDSP.filter((item) => {return item.digitalServiceProviderId != (e.digitalServiceProviderId)})        
        ClientDSP=obj
        initClientDSP(ClientDSP);
        initDSP([...DSP ,e])


        var obj2 = ClientDSPSearch.filter((item) => {return item.digitalServiceProviderId != (e.digitalServiceProviderId)})        
        ClientDSPSearch=obj2
        initClientDSPSearch(ClientDSPSearch);
    }

    const saveLinkedDSP = () => {
        console.log(ClientDSPSearch)
        clientProfileServices.updateDSPToClient(ClientDSPSearch).then((res) => {
            setsaveAlert(true)
            apiCall();            
        });

        setTimeout(function(){
            setsaveAlert(false)
        }, 5000);

    }
    
    const paginationHandler = (n: any) => { 
        ClientDSP = ClientDSPSearch;
        var num = n.pageNumber;
        var size  = n.pageSize;
        ClientDSP = ClientDSP.slice((num -1 ) * size,(size*num ))   
        initClientDSP(ClientDSP)       
    }

    const paginationHandlerAvailable = (n: any) => { 
        DSP = DSPSearch;
        var num = n.pageNumber;
        var size  = n.pageSize;
        var obj = DSP.slice((num -1 ) * size,(size*num ))       
        initDSP(obj)       
    }

    return (
    <div className="box box-add-region">

        <div className="box-header well">
            <h2><i className="icon-globe1 pt pr"></i>Manage DSP </h2>
        </div>

        <div className="box-content form-box">
            {saveAlert && <div className="alert alert-success" >Client DSPs saved successfully</div>}
            { saveError && <div className="alert alert-danger" >Choose at least 1 DSP to save</div> }

            <div className="row-fluid form-condensed">
                <div className="ws-6">
                    <div className="ws-4">
                        <div className="form-format"> <h2>Linked DSP(s)</h2></div>
                    </div>
                    {(linkedtotalRows > 0) && <div className="form-format ws-6 m0 pbl">
                        <div className="label-entries">
                            <div className="ws-12"> <input type="text" placeholder="Search" value={searchFieldLinked} onChange={(e:any) => {linkedSearch(e.target.value) ; initSearchFieldLink(e.target.value)}}/> </div>
                        </div>
                    </div>}
                    {(linkedtotalRows == 0) && <div className="alert alert-info ws-12 m0">No DSPs to display </div> } 
                    {(linkedsearchLength == 0) && <div className="alert alert-info ws-12 m0">No DSP matches your search criteria </div>}

                    <table className="table">
                        <tbody>

                            {
                                ClientDSP.map( (value) => {
                                    return (
                                        <tr >
                                        <td ><a className="btn btn-danger btn-small" onClick={()=>UnlinkedlinkDSP(value)}><i className="icon-trashcan"></i></a> </td>
                                        <td><a onClick={()=>{setdspDetail(1) ; initselectedDSP(value)}}>{value.fullName} </a></td>
                                    </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>

                    <div className="clearfix">

                    </div>

                    {
                        (() => {
                            if(dspDetail && dspDetail==1){
                                return <DspDetail param={true}  onChange={Confirmation} selected={selectedDSP}/>
                            }

                            if(linkedsearchLength && linkedsearchLength>10){
                                return <Pagination onChange={paginationHandler} totalRows={linkedtotalRows} />
                            }                                                  

                        })()                        
                    }
                </div>

                <div className="ws-6">
                    <div className="ws-4">
                        <div className="form-format">
                            <h2>List of available DSP(s) <button type="button" className="btn btn-xs btn-success hide"> <i className="icon-plus1"></i> New </button></h2>
                        </div>
                    </div>
                    {(avalaiblelinkedtotalRows > 0) && <div className="form-format ws-6 m0 pbl">
                        <div className="label-entries">
                            <div className="ws-12">
                                <input type="text" placeholder="Search" value={searchField} onChange={(e:any) => {availableSearch(e.target.value) ; initSearchField(e.target.value)}}/>
                            </div>
                        </div>
                    </div>}

                    {(avalaiblelinkedtotalRows== 0) && <div className="alert alert-info span12 m0" >No DSPs to display</div>}
                    {(avalaiblesearchLength == 0) && <div className="alert alert-info span12 m0" >No DSP matches your search criteria</div>}                
                    
                    <table className="table">
                        <tbody>
                            {
                                DSP.map( (values) => {
                                    return (
                                        <tr>
                                            <td><a className="btn btn-success btn-small"onClick={()=>linkDSP(values)} ><i className="icon-arrow-left"></i></a></td>
                                            <td><a onClick={()=>{setdspDetail(1) ; initselectedDSP(values)}}>{values.name}</a></td>
                                        </tr>
                                    )
                                })
                            }                   

                        </tbody>
                    </table>

                    <div className="clearfix">

                    {
                        (() => {

                            if(avalaiblelinkedtotalRows && avalaiblelinkedtotalRows>10 && avalaiblesearchLength > 10){
                                return <Pagination onChange={paginationHandlerAvailable} totalRows={avalaiblelinkedtotalRows} />
                            }                                                  

                        })()                        
                    }
                    </div>
                </div>

            </div>
        </div>
        <div className="box-footer pull-right">
            <button className="btn btn-success btn-sm" onClick={()=>saveLinkedDSP()}><i className="icon-save"></i> Save</button>
            <button className="btn btn-pink btn-sm" onClick={() => {apiCall() ; initSearchField("") ; initSearchFieldLink("")}}><i className="icon-reload"></i> Reset</button>
            <button className="btn btn-success pull-left btn-sm" onClick={exportDspList}> <i className=" icon-download1"></i> DSP</button>
            <a className="btn btn-success pull-left btn-sm" onClick={exportDspTemplate}><i className=" icon-download1"></i> Template</a>
        </div>

    </div>

    )
}
import { CommonUtilities } from '../../commonUtils';

export default class TransactionServices {

    static projectStatement(param: any) {
        var url = 'contract/statement/project/list?' + CommonUtilities.buildQueryString(param, true, true)
        return CommonUtilities.getData(url);
    }

    static savePayment = function (period: any, type: any, postData: any, hold: any, payOpeningBalance: any) {
        if (!payOpeningBalance) {
            payOpeningBalance = false;
        }
        var url = 'contracts/savePayment/' + period + '?paymentType=' + type + '&hold=' + hold + '&payOpeningBalance=' + payOpeningBalance;

        console.log(postData)
        //return null ; 
        return CommonUtilities.postData(url, postData);
    };

    static history = function (param: any) {
        var url = 'salesAnalysis/invoices/costs?' + CommonUtilities.buildQueryString(param, true, true);
        return CommonUtilities.getData(url);
    };

    //https://qa.onkorrect.com/Api/salesAnalysis/factSalesAct/Costs/KORRECT/2019Q4-%20A%2020234_52721/1/0?pageNumber=1&pageSize=10

    static factSalesActListing = function (header :any , account :any  , transId :any , param: any) {
        var url = 'salesAnalysis/factSalesAct/'+ header +'/'+ account +'/'+ transId +'/1/0?' + CommonUtilities.buildQueryString(param, true, true);
        return CommonUtilities.getData(url);
    };

    static getItemCodeSoldTracks (param:any){
        var url ='salesAnalysis/factSalesAct/getItemCodeSoldTracks?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    //getItemCodeSoldContracts
    static getItemCodeSoldContracts (param:any){
        var url ='salesAnalysis/factSalesAct/getItemCodeSoldContracts?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    //getItemCodeSoldCompilations
    static getItemCodeSoldCompilations (param:any){
        var url ='salesAnalysis/factSalesAct/getItemCodeSoldCompilations?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static subDetails (param:any){
        var url ='salesAnalysis/factSalesAct/'+param.headerType+'/'+param.accountCode+'/'+param.transactionRef+'/1/1';
        return CommonUtilities.getData(url);
    }

    static saveDetails (param:any) {
        var url = 'salesAnalysis/factSalesAct' ;
        return CommonUtilities.postData(url, param);
    }

}
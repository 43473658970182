import React, { Suspense, useState, useEffect, useRef } from "react";
import { Modal } from 'react-responsive-modal';
import dataLoaderServices from "./DataLoaderServices";

export default function DataLoaderFileLogDetail(param: any) {

    let [fileLogList , setfileLogList] = useState<any[]>([]);
    const [openFirst, setOpenFirst] = React.useState(false);

    const returnValue = (e: any) => {
        param.onChangeLoadFile(e)
        setOpenFirst(false)
    }
    useEffect(() => {
     //    console.log(param)
        ApiCall();

    }, [param.open]);

    const ApiCall=()=>{

        dataLoaderServices.loadIFileLogDetail(param.type.impLogID).then((res: any) => {
            setfileLogList(res)
        })
    }

    return (
        <div className="" style={{ width: "50%", height: "120%" }}>

            <Modal open={param.open} onClose={() => returnValue(0)} >

                <div className="box-header-fix" >

                    <div className="box-header-main">
                        <h2 className="ws-10"><i className="fa fa-question-circle"></i> File {param.type.originalFileName}</h2>

                        <div className="toolbox prxl">
                            <button className="btn btn-s btn-success pull-right" onClick={ApiCall}><i className="icon-refresh"></i> </button>
                        </div>
                    </div>

                    <div className="box-content">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Process</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>

                                {
                                    fileLogList?.map ( (values:any) =>{
                                        return (<>
                                        <tr className={values.status ==100?"info" :values.status ==0?"info": "danger"}>
                                            <td ><i className="fa fa-check"></i> {values.status ==100?"Completed" :values.status ==0?"Completed": "Fail"}</td>
                                            <td >{values.description}</td>
                                        </tr>
                                        </>
                                        )
                                    })

                                }

                            </tbody>
                        </table>

                    </div>

                    <div className="box-footer">
                        {<button className="btn-danger" onClick={() => returnValue(1)} > Close</button>}
                    </div>

                </div>
            </Modal>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import ContainerSlider from "./ContainerSlider";
import projectServices from "../../Projects/projectServices";
import { CommonUtilities } from "../../../commonUtils";


export default function DirectoryAnalytic(param: any) {


    const [hideGraph, sethideGraph] = useState(false)
    const [TotalRow, setTotalRow] = useState(0)
    let [dialogueOpenView, setdialogueOpenView] = useState(false)
    const onChangeClose = (e: any) => {
        setdialogueOpenView(false)
    }

    const [analyticInd, setanalyticInd] = useState<any>([]);
    const [analyticIndListing, setanalyticIndListing] = useState<any>([]);
    const [analyticIndListingHeader, setanalyticIndListingHeader] = useState<any>([]);

    var payLoad = { "type": "Performance", "level": 1, "pageNumber": 1, "pageSize": 10, "bIType": "Creator", "bICode": "VISI514534", "projectId": 22 }
    useEffect(() => {
        search(1);
    }, [param.openUI]);

    const search = (level: any) => {
        payLoad.level = level;
        projectServices.projectFinancialPerformance(payLoad).then((res) => {
            console.log(res)

            if (payLoad.level == 1) { setanalyticInd(CommonUtilities.relativeDataRemapping(res.data, res.headers.original)[0]) }

            console.log(CommonUtilities.relativeDataRemapping(res.data, res.headers.display))
            if (payLoad.level == 2) {
                var Header: any = [];
                setTotalRow(res.totalRows);
                setanalyticIndListing(CommonUtilities.relativeDataRemapping(res.data, res.headers.display))

                Object.entries(res.headers.display).forEach(([key, value]: any) => {
                    Header.push(value.replace(/ /g, ''))

                });
                setanalyticIndListingHeader(Header)
                //setanalyticIndListingHeader(CommonUtilities.relativeDataRemapping(res.headers.display, res.headers.original) )
            }


        });
    }
    return (<>

        <div className="ws-12">
            <div className="box ">

                <div className="box-content">

                    <div className="ws-12 positionRelative">

                        <div className="ws-12 pLarge">

                            <div className="positionAbsolute hide" style={{ zIndex: "9", top: "5%", right: "0 " }} ng-className="openTabP? '' : ''" ng-click="openPerformanceTabSearch = !openPerformanceTabSearch; searchByContainerCodes('Performance', 'contractPerformance')">
                                <i ng-className="openPerformanceTabSearch? 'icon-circle-right' : 'icon-circle-left'" ng-click="openTabP = !openTabP" className="text-xxl icon-circle-right icon-circle-left"></i>
                            </div>

                            <div className="box borderSolid pLarge">
                                <div className="pull-left mbl w-22">
                                    <div className="form-format ws-12 mt">
                                        <label className="ws-5 pull-left ">Hide Graph</label>

                                        <label className="switch pull-left">
                                            <input type="checkbox" name="hideGraph" onClick={() => sethideGraph(!hideGraph)} />
                                            {(hideGraph == true) && <span className="slider text-left on">Yes</span>}
                                            {(hideGraph == false) && <span className="slider off text-right">No</span>}
                                        </label>

                                    </div>
                                </div>

                                {<div className="w-78 pull-right mbl">
                                    <div className="pull-right">
                                        <button className="btn btn-success" onClick={() => search(2)}>
                                            <span >Search</span> <i className="icon-search pull-right text-xl pl p0 m0" style={{ marginTop: "-2px !important;" }}></i>
                                        </button>
                                    </div>
                                    <div className="pull-right">
                                        <button className="btn btn-queue" onClick={() => { setdialogueOpenView(true); }}> Containers
                                            <i className="icon-params1 pull-right text-xl pl p0 m0 ml" style={{ marginTop: "-6px !important", transform: "rotate(90deg);" }}></i>
                                        </button>
                                    </div>

                                    {/*                                 <!-- <div className="pull-right">
                                    <select ng-model="albumSales" name="label" style={{height: "36px !important"}}
                                        className="btn-queue">
                                        <option className="btn-queue" style={{height: "36px !important"}} value=""> Album Sales UK</option>
                                        <option className="btn-queue" style={{height: "36px !important"}} value="us"> Album Sales US</option>
                                    </select>
                                </div> --> */}

                                    <div className="pull-right verticalMediumWide hover-box">
                                        <ul className="hover-block textboxDropdownList pLarge defaultColor ws-4" style={{ background: "transparent", boxShadow: "none" }}>
                                            <div className="ws-11">
                                                <li className="ws-12 pm btn btn-queue">
                                                    <a ng-click="chooseAlbumSales('Album Sales UK')">Album Sales UK</a>
                                                </li>

                                                <li className="ws-12 pm btn btn-queue">
                                                    <a ng-click="chooseAlbumSales('Album Sales US')">Album Sales US</a>
                                                </li>


                                            </div>

                                        </ul>

                                        <button className="btn btn-queue" style={{ height: "36px !important" }}>Choose Album Sales
                                            <i className="icon-angle-right pull-right text-xl pl p0 m0" style={{ marginTop: " -2px !important" }}></i>
                                        </button>

                                    </div>

                                    <div className="pull-right">
                                        <button className="btn btn-queue" data-ng-click="loadFile(1)"> Performance
                                            <i className="icon-angle-right pull-right text-xl pl p0 m0" style={{ marginTop: " -2px !important" }}></i></button>
                                    </div>
                                    <div className="pull-right">
                                        <button className="btn btn-queue" data-ng-click="loadFile(1)"> Royalties
                                            <i className="icon-angle-right pull-right text-xl pl p0 m0" style={{ marginTop: " -2px !important" }}></i>
                                        </button>
                                    </div>


                                </div>}

                                {(hideGraph == false) && <div className="ws-12 box borderSolid">
                                    <div className="box-header bg-grey pLarge pull-right">
                                        <span className="italic pull-right">20191231</span>

                                        <span className="text-bold mll mrl pull-right">
                                            Current Period :
                                        </span>
                                    </div>
                                    <div className="box-content pLarge" ng-click="searchFilter();">


                                        <div className="ws-12 mbl">
                                            <div className="ws-3 text-right ps"> Royalties </div>
                                            <div className="ws-8 bg-white borderSolid p0 positionRelative" ng-click="totalPerformanceChartClick()">
                                                <div className={"m0 text-right p0 ps w-" + analyticInd.royaltiesPercentage} style={{ background: "#CBC3E3", color: "white" }}>
                                                    &nbsp;
                                                </div>
                                                <div className="text-right positionAbsolute rightReset ps" style={{ background: "#8b95e1", color: "white" }}>
                                                    {analyticInd.totalRoyalty}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ws-12 mbl">
                                            <div className="ws-3 text-right ps"> Recoupables </div>
                                            <div className="ws-8 bg-white borderSolid p0 positionRelative" ng-click="totalPerformanceChartClick()">
                                                <div className={"m0 text-right p0 ps w-" + analyticInd.recoupablesPercentage} style={{ background: "#fb922f", color: "#FFFFFF" }}>
                                                    &nbsp;
                                                </div>
                                                <div className="text-right positionAbsolute rightReset ps" style={{ background: "#F8B88B", color: "white" }}>
                                                    {analyticInd.totalRecoup}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ws-12 mbl">
                                            <div className="ws-3 text-right ps"> Balance </div>
                                            <div className="ws-8 bg-white borderSolid p0 positionRelative" ng-click="totalPerformanceChartClick()">
                                                <div className={"m0 text-right p0 ps w-" + analyticInd.balancePercentage} style={{ background: "#66FF99", color: "#FFFFFF" }}>
                                                    &nbsp;
                                                </div>
                                                <div className="text-right positionAbsolute rightReset ps" style={{ background: "#65d867", color: "white" }}>
                                                    {analyticInd.totalProfit}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ws-12 mbl">
                                            <div className="ws-3 text-right ps"> Revenue </div>
                                            <div className="ws-8 bg-white borderSolid p0 positionRelative" ng-click="totalPerformanceChartClick()">
                                                <div className={"m0 text-right p0 ps w-" + analyticInd.revenuePercentage} style={{ background: "#4B9CD3", color: "#FFFFFF" }}>
                                                    &nbsp;
                                                </div>
                                                <div className="text-right positionAbsolute rightReset ps" style={{ background: "#89CFF0", color: "white" }}>
                                                    {analyticInd.totalRevenue}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ws-12 ">
                <div className="box">
                    <div className="box-header well">
                        <h2>Performance ( <span ng-if="!financialPerformationItems">No activity</span> )
                            <span ng-show="searchItemD.transaction_Ref"> for </span>
                        </h2>
                    </div>
                    <div className="box-content">
                        {TotalRow == 0 && <div className="alert alert-info mt" > Click on the graph to get the breakdown </div>}
                        {TotalRow > 0 && <table className="table borderRounded ">
                            <thead>
                                <tr>
                                    {
                                        analyticIndListingHeader?.map((values: any) => {

                                            return (
                                                <th>{values}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>


                                {

                                    analyticIndListing?.map((index: any, value: any) => {

                                        return (
                                            <tr>
                                                {
                                                    analyticIndListingHeader?.map((header: any) => {

                                                        return (
                                                            <td>{index[header]}</td>
                                                        )
                                                    })
                                                }
                                            </tr>)

                                    })
                                }

                            </tbody>

                        </table>}

                    </div>
                </div>
            </div>


            <div className="box ">
                {
                    (() => {
                        if (dialogueOpenView) {
                            return (<ContainerSlider onChangeClose={onChangeClose} open={dialogueOpenView} />)
                        }
                    })()
                }</div>
        </div>
    </>)
}
import React, { useEffect, useState } from "react";
import clientProfileServices from "../clientProfileServices";

export default function FTP_Management (param:any) {

    const [ftpList, initFTPlist] = useState<any>([]);
    const [saveAlert , setsaveAlert] = useState(false);
    const [saveEmpty , setsaveEmpty] = useState(false);
    const [emptyField , setemptyField] = useState('');

    useEffect(() => {
        clientProfileServices.ftpDetails()
        .then((res) => {
            initFTPlist(res)
        })
    }, []);

    const saveFTPDetail=() =>{

        if((ftpList.ftpAddress != '' && ftpList.ftpUsername != '' && ftpList.ftpPassword != '') &&
         (ftpList.ftpAddress != null && ftpList.ftpUsername != null && ftpList.ftpPassword != null) ){
            clientProfileServices.saveFTPDetails(ftpList);
            setsaveAlert(true)
            setsaveEmpty(false) 
            initFTPlist([]) 
        }
        if (ftpList.ftpAddress == '' || ftpList.ftpAddress == null) {
            setsaveEmpty(true) ;
        }

        if (ftpList.ftpUsername == '' || ftpList.ftpUsername == null) {
            setsaveEmpty(true) ;
        }

        if (ftpList.ftpPassword == '' || ftpList.ftpPassword == null) {
            setsaveEmpty(true) ;
        }
 
    }

    const fieldHandle =(e:any) =>  {
        let key = e.target.name
        ftpList[key] = e.target.value;
    }
    return(

    <div className="box">

        <div className="box-header well">
            <h2><i className="icon-edit pt pr"></i> Manage FTP</h2>
        </div>

        {saveAlert && <div className="alert alert-success" >FTP details saved successfully</div> } 
        {saveEmpty && <div className="alert alert-danger">FTP details must all be filled</div> } 

        <div className="box-content">
            <form className="form-horizontal form-condensed" name="ftpDetail">
                <div className="row-fluid form-condensed">
                    <div className="row-fluid label-entries m0 pt">
                        <label className="ws-2 text-bold">FTP Address</label>
                        <input type="text" value={ftpList.ftpAddress} onChange={fieldHandle} name="ftpAddress"  className="checkbox-inline ws-3"/>
                    </div>
                    <div className="row-fluid label-entries m0 pt">
                        <label className="ws-2 text-bold">FTP Username</label>
                        <input type="text" value={ftpList.ftpUsername} onChange={fieldHandle} name="ftpUsername" className="checkbox-inline ws-3"/>
                    </div>
                    <div className="row-fluid label-entries m0 pt">
                        <label className="ws-2 text-bold">FTP Password</label>
                        <input type="text" value={ftpList.ftpPassword} onChange={fieldHandle} name="ftpPassword" className="checkbox-inline ws-3"/>
                    </div>          
                </div>
            </form>
        </div>
        <div className="box-footer pull-right">
            <button className="btn btn-success btn-sm" onClick={ () => saveFTPDetail() }><i className="icon-save"></i> Save
            </button>
        </div>
    </div>
    )

}

import React, { Suspense, useState, useEffect, useRef } from "react";
import clientProfileServices from "../clientProfileServices";
import Pagination from "../../../Header/Pagination";
import ManageTerritory from "../manageTerritory";
import DeleteDialogueBox from "../../../Common/deleteDialogue";

export default function Zone_Management(param:any) {

    let [clientTerritoriesZonesPagination, initClientTerritoriesZonesPagination] = useState<any[]>([]);
    let [allZonesExcludingClientSearch ,setallZonesExcludingClientSearch ]= useState<any[]>([]);
    let [clientTerritoriesZonesSearch, initClientTerritoriesZonesSearch] = useState<any[]>([]);
    let [allZonesExcludingClient, initAllZonesExcludingClient] = useState<any[]>([]);
    let [clientTerritoriesZones, initClientTerritoriesZones] = useState<any[]>([]);
    let [allClientZones, initAllClientZones] = useState<any[]>([]);

    const [territoryGroupsUnassignZone, initTerritoryGroupsUnassignZone] = useState<any[]>([]);
    const [searchZonesMechanical, initSearchZonesMechanical] = useState<any[]>([]);
    const [selectedTerritory, initselectedTerritory] = useState<any[]>([]);
    const [deleteTerritory , setdeleteTerritory] = useState<any>([]);
    const [search , setSearch] = useState('');

    const [searchLengthAvailable, setSearchLengthAvailable] = useState(1);
    const [totalRowsPagination , setTotalRowsPagination] = useState(0);
    const [totalRowsAvailable , setTotalRowsAvailable] = useState(1);
    const [addTerritory , setaddTerritory] = useState(0);
    const [searchLength , setSearchLength] = useState(0);
    const [totalRows , setTotalRows] = useState(1);
    const [deleteJob , setDelete] = useState(0);

    const [deleteAlert , setdeleteAlert] = useState(false);
    const [saveAlert , setsaveAlert] = useState(false);
    const [saveError , setsaveError] = useState(false);
 

    useEffect(() => {
        if(param.param == 'open'){
            apiCall();
        }
    }, []);

    const apiCall = () => {
        clientProfileServices.getAllZonesExcludingClient().then((res) => {
            initAllZonesExcludingClient(res)
            setallZonesExcludingClientSearch(res)
            setTotalRowsAvailable(res.length)
        });

        clientProfileServices.getAllClientZones({"isAllZone" : false}) .then((res) => {
            initAllClientZones(res)
        });

        clientProfileServices.getClientTerritoriesZones() .then((res) => {
            initClientTerritoriesZones(res)
            initClientTerritoriesZonesSearch(res)
            setTotalRows(res.length)
            setTotalRowsPagination(res.length)
            setSearchLength(res.length)

            if(res.length > 0){
                clientTerritoriesZones = res;
                var obj = clientTerritoriesZones.slice(1,10) 
                initClientTerritoriesZones(obj)   
            }
        });               
    
        clientProfileServices.territoryGroupsUnassignZone() .then((res) => {
            initTerritoryGroupsUnassignZone(res)
        });
    
        clientProfileServices.searchZonesMechanical({"isAllZone" : ""}) .then((res) => {
            initSearchZonesMechanical(res)
        });
    }

    const searchField =(e:any) =>{

        if(e != ''){
            clientTerritoriesZones=clientTerritoriesZonesSearch;
            var obj =  clientTerritoriesZones.filter((item)=> { return item.territoryName.toLocaleLowerCase().includes(e) || item.zoneDesc.toLocaleLowerCase().includes(e) })
            clientTerritoriesZones = obj;
            initClientTerritoriesZones(clientTerritoriesZones)
            setSearchLength(clientTerritoriesZones.length)            
           // setTotalRows(clientTerritoriesZones.length)
        }else {
            clientTerritoriesZones = clientTerritoriesZonesSearch;
            initClientTerritoriesZones(clientTerritoriesZones)
            setSearchLength(clientTerritoriesZonesSearch.length)
           // setTotalRows(clientTerritoriesZonesSearch.length)
           if(clientTerritoriesZones.length > 0){
            var obj = clientTerritoriesZones.slice(1,10) 
            initClientTerritoriesZones(obj)   
        }
        }

    }

    const searchFieldAvailable =(e:any) =>{
        if(e != ''){
            allZonesExcludingClient = allZonesExcludingClientSearch;
            var obj =  allZonesExcludingClient.filter((item)=> { return item.zoneID.toLocaleLowerCase().includes(e)  })
            allZonesExcludingClient = obj;
            initAllZonesExcludingClient(allZonesExcludingClient)
            setSearchLengthAvailable(obj.length)          
        }else {
            allZonesExcludingClient = allZonesExcludingClientSearch;
            initAllZonesExcludingClient(allZonesExcludingClient)
            setSearchLengthAvailable(allZonesExcludingClient.length)
        }
    }

    const paginationHandler = (n: any) => { 
        clientTerritoriesZones = clientTerritoriesZonesSearch;
        var num = n.pageNumber;
        var size  = n.pageSize;
        var obj = clientTerritoriesZones.slice((num -1 ) * size,(size*num ))       
        initClientTerritoriesZones(obj)       
    }

    const Confirmation = (e :any) =>{
        setaddTerritory(0);
    }

    const addZone = (e :any) =>{   
        initAllClientZones([...allClientZones , e]) 
        var obj = allZonesExcludingClient.filter((item)=> { return item.zoneID.toLocaleLowerCase() != (e.zoneID.toLocaleLowerCase())  })
        allZonesExcludingClient = obj;
        initAllZonesExcludingClient(allZonesExcludingClient)
        setTotalRowsAvailable(allZonesExcludingClient.length)
    }

    const saveZone = (e:any) => {
        clientProfileServices.updateClientZones(allClientZones).then((res) => {
            apiCall();
            setsaveAlert(true)
        });

        setTimeout(function(){
            setsaveAlert(false)
        }, 5000);
    }

    const resetAvailable = (e:any) => {
        apiCall();
    }

    const deleteLinkZone = (e:any) => {
        var obj = allClientZones.filter((item)=> { return item.zoneID.toLocaleLowerCase() != (e.zoneID.toLocaleLowerCase())  })
        initAllClientZones(obj)
        initAllZonesExcludingClient([...allZonesExcludingClient,e])
    }

    const ConfirmationDelete = (e :any) =>{  

        if(e == 1) {
            clientProfileServices.deleteZone(deleteTerritory).then((res)=>{
                apiCall();
            })
        }
        setDelete(0);
    }

    return (

        <div className="box box-add-region">

            <div className="box-header well"><h2><i className="icon-globe1 pt pr"></i>Add Zone </h2></div>

            <div className="box-content form-box">
                {saveAlert && <div className="alert alert-success" >Client zones saved successfully</div>}
                {saveError && <div className="alert alert-danger">Choose at least 1 zone to save</div>}

                <div className="row-fluid form-condensed">            
                    <div className="ws-6">
                        <div className="form-format">
                            <h2>Linked Zone(s)</h2>
                        </div>
                        {(allClientZones.length == 0) && <div className="alert alert-info">No zones to display</div>}
                        {(allClientZones.length != 0) && <table className="table m0">
                            
                            <thead>
                                <tr>
                                    <th> Remove </th>
                                    <th> Zone </th>
                                    <th> Operations </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allClientZones.map( (value)=> {

                                        return(
                                            <tr>
                                                <td><a className="btn btn-danger btn-small" onClick={(e:any)=>deleteLinkZone(value)}> <i className="icon-trashcan"></i></a></td>
                                                <td>{value.zoneID}</td>
                                                <td><a className="btn btn-success pull-right" onClick={()=> {setaddTerritory(1) ; initselectedTerritory(value)} }><i className="icon-plus1"></i></a></td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>}
                    </div>

                    <div className="ws-6">
                        <div className="ws-6">
                            <div className="form-format"> <h2>List of available Zones</h2></div>
                        </div>
                        {(totalRowsAvailable > 0) && <div className="form-format ws-6  pull-right m0">
                            <div className="label-entries">
                                <div className="input-group">
                                    <span className="input-group-btn"> <button type="button" className="btn-success"><i className="icon-search"></i>Filter</button></span>
                                    <input type="text" placeholder="Search" onChange={(e:any)=>searchFieldAvailable(e.target.value)}/>                                
                                </div>
                            </div>
                        </div>}
                        {(totalRowsAvailable == 0) && <div className="alert alert-info ws-12 m0" >No zones to display</div>}
                        {(searchLengthAvailable == 0) && <div className="alert alert-info ws-12 m0 " >No zones matches your search criteria</div>}          

                        {(allZonesExcludingClient.length > 0) &&<table className="table m0 ptl">
                            <thead>
                                <tr>
                                    <th>Operations</th>
                                    <th >Zone</th>
                                </tr>
                            </thead>
                            <tbody>
                                { allZonesExcludingClient.map( (value) => {
                                    return (
                                        <tr>
                                            <td><a className="btn btn-info btn-small" onClick={()=>addZone(value)}><i className="icon-arrow-left"></i></a></td>
                                            <td>{value.zoneID}</td>    
                                        </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>}
                    </div>

                </div>
            </div>

            <div className="box-footer pull-right">
                <button className="btn btn-success btn-sm" onClick={(e:any) => saveZone(e)}><i className="icon-save"></i> Save</button>
                <button className="btn btn-pink btn-sm" onClick={(e:any) => resetAvailable(e)}><i className="icon-reload"></i> Reset</button>
            </div>
                
            <div className="box-content">
                {deleteAlert && <div className="alert alert-success"> Territory has been deleted successfully</div>}
                    <div className="box-header well"><span><i className="icon-globe1 ptl pr text-bold"></i> Linked Territories with Zones {totalRowsPagination} record(s) found</span> </div>
                    <div className="box-content">
                        {(totalRows > 0) && <div className="form-horizontal row-fluid">
                            <div className="form-format ws-5">
                                <label className="control-label ws-12 text-bold pb">Filter</label>

                                <div className="label-entries pbl">
                                    <input type="text" className="form-control  input-sm" placeholder="Search" onChange={(e:any)=>searchField(e.target.value)} id="zone"/>
                                </div>
                            </div>
                        </div>}

                        {(totalRows == 0) && <div className="alert alert-info mtl">No records to display</div> } 
                        {(searchLength == 0) && <div className="alert alert-info mtl">No zones matches your search criteria</div>}

                        {(clientTerritoriesZones.length > 0) && <div className="table-responsive mtl">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Zone ID</th>
                                        <th>Zone</th>
                                        <th>Territory ID</th>
                                        <th>Territory</th>
                                        <th>Operation</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        clientTerritoriesZones.map((value) => {
                                            return ( 
                                            <tr>
                                                <td>{value.zoneID}</td>
                                                <td>{value.zoneDesc}</td>
                                                <td>{value.territoryLink}</td>
                                                <td>{value.territoryName}</td>
                                                <td className="text-center"><a  className="btn btn-danger" onClick={()=>{setDelete(1) ; setdeleteTerritory(value)}}><i className="icon-trashcan"></i></a></td>
                                            </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>

                            <div className="clearfix">

                                {(() => {
                                        if(searchLength && searchLength>10){
                                            return <Pagination onChange={paginationHandler} totalRows={totalRows} />
                                        }                                                  

                                    })()}
                            </div>

                        </div>}
                    </div> 

                    {
                        (() => {
                            if(addTerritory && addTerritory==1){
                                return <ManageTerritory param={true}  onChange={Confirmation} selected={selectedTerritory}/>
                            }

                            if(deleteJob && deleteJob==1){
                                return <DeleteDialogueBox param={true} message={"territory " +deleteTerritory.territoryLink}onChange={ConfirmationDelete}/>
                            }

                        })()
                    }


            </div>
        </div>
    )
}
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./styles/Css/theme/default.css";
import "./styles/Css/theme/foundation.css";
import "./styles/crow.css";
import "./styles/iconmoon/style.css";
import 'rc-tooltip/assets/bootstrap_white.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
import React, { useEffect, useState } from "react";
import clientProfileServices from "../clientProfileServices";
import { SelectContact } from "../../../Contacts/selectContact";
import { ContactFormSearch } from "../../../Contacts/contactFormSearch";
import ClientProfileServices from "../clientProfileServices";

export default function LicensingClientProfile(param:any) {

    let [licensingList, initLicensingList]  = useState<any>([]);
    const [saveAlert , setsaveAlert] = useState(false);

    useEffect(() => {
        clientProfileServices.licensingDetails()
        .then((res) => {
            initLicensingList(res[0])
        })
    }, []);


    const sysLicensorMech = (e :any) =>{
        initLicensingList((prevDetails: any) => ({
            ...prevDetails,
            licensorMechDisplay: e.kontactDisplay,
            licensorMech : e.kontactCode
        }));
    }
    const sysLicensorPubl = (e :any) =>{
        initLicensingList((prevDetails: any) => ({
            ...prevDetails,
            licensorPubDisplay : e.kontactDisplay,
            licensorPub : e.kontactCode
        }));
    }

    const sysLicensorSynch = (e :any) =>{
        initLicensingList((prevDetails: any) => ({
            ...prevDetails,
            licensorSynchDisplay : e.kontactDisplay,
            licensorSynch : e.kontactCode
        }));
    }

    const SysLicenseeMech = (e :any) =>{
        initLicensingList((prevDetails: any) => ({
            ...prevDetails,
            licenseeMechDisplay : e.kontactDisplay,
            licenseeMech : e.kontactCode
        }));
    }

    const SysLicenseePub = (e :any) =>{
        initLicensingList((prevDetails: any) => ({
            ...prevDetails,
            licenseePubDisplay : e.kontactDisplay,
            licenseePub : e.kontactCode
        }));
    }

    const SysLicenseeSynch= (e :any) =>{
        initLicensingList((prevDetails: any) => ({
            ...prevDetails,
            licenseeSynchDisplay : e.kontactDisplay,
            licenseeSynch : e.kontactCode
        }));
    }
   
    const defLicensorMech = (e :any) =>{
        initLicensingList((prevDetails: any) => ({
            ...prevDetails,
            licensorMechCurrDisplay : e.kontactDisplay,
            licensorMechCurr : e.kontactCode
        }));
    }

    const defLicensorPubl = (e :any) =>{
        initLicensingList((prevDetails: any) => ({
            ...prevDetails,
            licensorPubCurrDisplay : e.kontactDisplay,
            licensorPubCurr : e.kontactCode            
        }));
    }

    const defLicensorSynch = (e :any) =>{
        initLicensingList((prevDetails: any) => ({
            ...prevDetails,
            licensorSynchCurrDisplay : e.kontactDisplay,
            licensorSynchCurr : e.kontactCode            
        }));
    }

    const defLicenseeMech = (e :any) =>{
        initLicensingList((prevDetails: any) => ({
            ...prevDetails,
            licenseeMechCurrDisplay : e.kontactDisplay,
            licenseeMechCurr : e.kontactCode            
        }));
    }
    const defLicenseePub = (e :any) =>{
        initLicensingList((prevDetails: any) => ({
            ...prevDetails,
            licenseePubCurrDisplay : e.kontactDisplay,
            licenseePubCurr : e.kontactCode            
        }));
    }

    const defLicenseeSynch= (e :any) =>{
        initLicensingList((prevDetails: any) => ({
            ...prevDetails,
            licenseeSynchCurrDisplay : e.kontactDisplay,
            licenseeSynchCurr: e.kontactCode
        }));
    }


    const saveClientProfile = () => {
        ClientProfileServices.saveClient(licensingList).then((res) => {
            setsaveAlert(true)
        });
        clientProfileServices.licensingDetails().then((res) => {
            initLicensingList(res)
        })
        setTimeout(function(){
            setsaveAlert(false)
        }, 3000);
    }

    return(
    <div className="box">
        <div className="box-header well">
            <h2><i className="icon-edit pt pr"></i> Licensing</h2>
        </div>

        <div className="box-content">
            {saveAlert && <div className="alert alert-success">Licensing saved successfully</div> }
            <div className="form-horizontal form-condensed">

                 <fieldset>
                    <div className="row-fluid">
                        <div className="ws-2"><h4 className="text-bold">System Default</h4></div>
                        <div className="ws-3"><h4 className="">Mech</h4></div>
                        <div className="ws-3"><h4 className="">Publ</h4></div>
                         <div className="ws-3"><h4 className="">Synch</h4></div>
                    </div>                

                    <div className="ws-12">
                        <div className="form-format ws-1">
                            <label className="text-bold">Set Licensor</label>
                        </div>
                        <div className="form-format ws-3">
                            <div className="ws-9">
                                <input type="text" className="form-control text-uppercase ws-12" placeholder="Mech Licensor"
                                    value={licensingList.licensorMechDisplay}
                                    name="licensorMechDisplay"/>
                            </div>
                            <div className="ws-3">
                                <ContactFormSearch onChange={sysLicensorMech}/>
                                <button type="button" className="btn-success btn-xs hide"><i className="icon-user-add"></i></button>
                            </div>
                        </div> 

                        <div className="form-format ws-3">
                            <div className="ws-9">
                                <input type="text" className="form-control text-uppercase ws-12" placeholder="Publ Licensor"
                                    value={licensingList.licensorPubDisplay}
                                    name="licensorPubDisplay"/>
                            </div>
                            <div className="ws-3">
                                <ContactFormSearch onChange={sysLicensorPubl}/>
                                <button type="button" className="btn btn-success btn-xs hide"><i className="icon-user-add"></i></button>
                            </div>
                        </div> 

                        <div className="form-format ws-3">
                            <div className="ws-9">
                                <input type="text" className="form-control text-uppercase ws-12" placeholder="Synch Licensor"
                                value={licensingList.licensorSynchDisplay}
                                name="licensorSynchDisplay"/>                                            
                            </div>
                            <div className="ws-3">
                                <ContactFormSearch onChange={sysLicensorSynch}/>
                                <button type="button" className="btn btn-success btn-xs hide" ><i className="icon-user-add"></i></button>
                            </div>
                        </div>
                    </div>

                    <div className="ws-12 ptl">
                        <div className="form-format ws-1"><label className="ws-4 text-bold">Set Licensee</label></div>

                        <div className="form-format ws-3">
                            <div className="ws-9">
                                <input type="text" className="form-control text-uppercase ws-12" placeholder="Mech Licensee"
                                value={licensingList.licenseeMechDisplay}
                                name="licenseeMechDisplay"/>
                        </div>
                            <div className="ws-3">
                                <ContactFormSearch onChange={SysLicenseeMech}/>
                                <button type="button" className="btn btn-success btn-xs hide"><i className="icon-user-add"></i></button>
                            </div>
                        </div>

                        <div className="form-format ws-3">
                            <div className="ws-9">
                                <input type="text" className="form-control text-uppercase ws-12" placeholder="Publ Licensee"
                                value={licensingList.licenseePubDisplay}
                                name="licenseePubDisplay"/>
                            </div>
                            <div className="ws-3">
                                <ContactFormSearch onChange={SysLicenseePub}/>
                                <button type="button" className="btn btn-success btn-xs hide"><i className="icon-user-add"></i></button>
                            </div>
                        </div>

                        <div className="form-format ws-3">
                            <div className="ws-9">
                                <input type="text" className="form-control text-uppercase ws-12" placeholder="Synch Licensee"
                                value={licensingList.licenseeSynchDisplay} name="licenseeSynchDisplay"/>
                            </div>
                            <div className="ws-3">
                                <ContactFormSearch onChange={SysLicenseeSynch}/>
                                <button type="button" className="btn btn-success btn-xs hide" ><i className="icon-user-add"></i></button>
                            </div>
                        </div>
                     </div>

                                <div className="row-fluid m1">
                                    <div className="ws-2"> <h4 className="">My Current Defaults</h4> </div>
                                    <div className="ws-3"> <h4 className="">Mech</h4> </div>
                                    <div className="ws-3"> <h4 className="">Publ</h4> </div>
                                    <div className="ws-3"> <h4 className="">Synch</h4> </div>
                                </div>

                                <div className="ws-12">
                                    <div className="form-format ws-1">
                                        <label className="ws-4 text-bold">Set Licensor</label>
                                    </div>

                                    <div className="form-format ws-3">
                                        <div className="ws-9">
                                            <input type="text" className="form-control text-uppercase ws-12" placeholder="Mech Licensor"                                            
                                                value={licensingList.licensorMechCurrDisplay}name="licensorMechCurrDisplay"/>
                                        </div>
                                        <div className="ws-3">
                                            <ContactFormSearch onChange={defLicensorMech}/>
                                            <button type="button" className="btn btn-success btn-xs hide" ><i className="icon-user-add"></i></button>
                                        </div>
                                    </div>

                                    <div className="form-format ws-3">
                                        <div className="ws-9">
                                            <input type="text" className="form-control text-uppercase ws-12" placeholder="Publ Licensor"
                                                value={licensingList.licensorPubCurrDisplay}
                                                name="licensorPubCurrDisplay"/>
                                        </div>
                                        <div className="ws-3">
                                            <ContactFormSearch onChange={defLicensorPubl}/>
                                            <button type="button" className="btn btn-success btn-xs hide"><i className="icon-user-add"></i></button>
                                        </div>
                                    </div>

                                    <div className="form-format ws-3">
                                        <div className="ws-9">
                                            <input type="text" className="form-control text-uppercase ws-12" placeholder="Synch Licensor"
                                                value={licensingList.licensorSynchCurrDisplay} name="licensorSynchCurrDisplay"/>
                                        </div>
                                        <div className="ws-3">
                                        <ContactFormSearch onChange={defLicensorSynch}/>
                                            <button type="button" className="btn btn-success btn-xs hide" ><i className="icon-user-add"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <div className="ws-12 ptl">
                                    <div className="form-format ws-1">
                                        <label className="ws-4 text-bold">Set Licensee </label>
                                    </div>

                                    <div className="form-format ws-3">
                                        <div className="ws-9">
                                            <input type="text" className="form-control text-uppercase ws-12" placeholder="Mech Licensee"
                                                value={licensingList.licenseeMechCurrDisplay}name="licenseeMechCurrDisplay"/>
                                        </div>
                                        <div className="w-3">
                                            <ContactFormSearch onChange={defLicenseeMech}/>
                                            <button type="button" className="btn btn-success btn-xs hide"><i className="icon-user-add"></i></button>
                                        </div>
                                    </div>

                                    <div className="form-format ws-3">
                                        <div className="ws-9">
                                            <input type="text" className="form-control text-uppercase ws-12" placeholder="Publ Licensee"
                                                value={licensingList.licenseePubCurrDisplay}
                                                name="licenseePubCurrDisplay"/>
                                        </div>
                                        <div className="ws-3">
                                            <ContactFormSearch onChange={defLicenseePub}/>
                                            <button type="button" className="btn btn-success btn-xs hide" ><i className="icon-user-add"></i></button>
                                        </div>
                                    </div>

                                    <div className="form-format ws-3">
                                        <div className="ws-9">
                                            <input type="text" className="form-control text-uppercase ws-12" placeholder="Synch Licensee"
                                                value={licensingList.licenseeSynchCurrDisplay} name="licenseeSynchCurrDisplay"/>
                                        </div>
                                        <div className="ws-3">
                                            <ContactFormSearch onChange={defLicenseeSynch}/>
                                            <button type="button" className="btn btn-success btn-xs hide"><i className="icon-user-add"></i></button>
                                        </div>
                                    </div>

                                </div>

                </fieldset>                       
            </div>
        </div>
        <div className="box-footer pull-right">
            <button className="btn btn-success btn-sm" onClick={saveClientProfile}><i className="icon-save"></i>save</button>
        </div>
    </div>
    )
}
import React, { Suspense, useState, useEffect, useRef } from "react";
import { Modal } from 'react-responsive-modal'; 
import dataLoaderServices from "./DataLoaderServices";

export default function DataLoaderLoadFile(param: any) {
    const [openFirst, setOpenFirst] = React.useState(false);
    const [upLoadedCount , setUpLoadedCount] = useState(0)
    const returnValue = (e: any) => {
        param.onChangeLoadFile(e)
        setOpenFirst(false)
    }

    //console.log(param)

    useEffect(() => {
        if(param.type == 'Contacts' ){ dataLoaderServices.dataLoadContactCount().then((value : any)=>{ setUpLoadedCount(value)}) }
        if(param.type == 'Contracts' ){ dataLoaderServices.dataLoadContractCount().then((value : any)=>{ setUpLoadedCount(value)}) }
        if(param.type == 'Contract Link' ){ dataLoaderServices.dataLoadcontractLinksCount().then((value : any)=>{ setUpLoadedCount(value)}) }

        if(param.type == 'Upload Mechanical' ){ 
            if(param.subType){
                dataLoaderServices.dataLoadMechanicalUploadCount({type:param.subType}).then((value : any)=>{ setUpLoadedCount(value)})
            }             
        }

        if(param.type == 'Mappings' ){ 
            if(param.subType){
                dataLoaderServices.dataLoadMappingCount({type:param.subType}).then((value : any)=>{ setUpLoadedCount(value)})
            }
        }
        if(param.type == 'Data Transfer' ){ dataLoaderServices.dataTransferCount().then((value : any)=>{ setUpLoadedCount(value)}) }
        if(param.type == 'Bulk Update' ){ 
            if(param.subType){
                dataLoaderServices.bulkUpdateSummary({type:param.subType}).then((value : any)=>{ setUpLoadedCount(value)})
            }
        }
    }, [param.open]);

    return (
        <div className="" style={{ width: "50%", height: "120%" }}>

            <Modal open={param.open} onClose={() => returnValue(0)} >

                <div className="box-header-fix" >

                    <div className="box-header-main">  <h2><i className="fa fa-question-circle"></i> Clear or Append Data?</h2> </div>

                    <div className="box-content test"><span>Do you want to clean previous data or append new data ?</span></div>

                    <div className="box-footer">
                        {(upLoadedCount == 0) && <button className="btn-primary" onClick={() => returnValue(3)} > Load</button>}
                        {(upLoadedCount > 0) && <>
                            <button className="btn-danger" onClick={() => returnValue(1)} > Append</button>
                            <button className="btn-primary" onClick={() => returnValue(2)} > Clear</button></>}
                    </div>

                </div>
            </Modal>
        </div>
    )
}
import { CommonUtilities }  from '../../commonUtils';

export default class dataLoaderServices  {
   

    public static dataLoaderSearch = [
        {
            field_display   : ["Contact Code","Contact Name","email",],
            field_code      : ["kontactCode","kontactName","email"],
            className       : "ws-4" , fieldType : "text" ,for :"BasicContactDetails", fieldFor        : "Contacts"
        },

        {
            field_display   : ["Contact Code","Contact Name","email",],
            field_code      : ["kontactCode","kontactName","email"],
            className       : "ws-4" , fieldType : "text" ,for :"AdditionalContactRecipients", fieldFor        : "Contacts"
        },

        {
            field_display   : ["Contact Code","Contact Name","email",],
            field_code      : ["kontactCode","kontactName","email"],
            className       : "ws-4" , fieldType : "text" ,for :"ContactStatuses", fieldFor: "Contacts"
        },

        {
            field_display   : ["Artist/Code","Contract Ref","Title",],
            field_code      : ["artist","contractRefNum","contractTitle"],
            className       : "ws-4" , fieldType : "text" , fieldFor: "Contracts",for :"rateBasis"
        },
        {
            field_display   : ["Artist/Code","Contract Ref","Title",],
            field_code      : ["artist","contractRefNum","contractTitle"],
            className       : "ws-4" , fieldType : "text" , fieldFor: "Contracts",for :"regions"
        },
        {
            field_display   : ["Artist/Code","Contract Ref","Title",],
            field_code      : ["artist","contractRefNum","contractTitle"],
            className       : "ws-4" , fieldType : "text" , fieldFor: "Contracts",for :"adjustments"
        },
        {
            field_display   : ["Artist/Code","Contract Ref","Title",],
            field_code      : ["artist","contractRefNum","contractTitle"],
            className       : "ws-4" , fieldType : "text" , fieldFor: "Contracts",for :"reserves"
        },
        {
            field_display   : ["Artist/Code","Contract Ref","Title",],
            field_code      : ["artist","contractRefNum","contractTitle"],
            className       : "ws-4" , fieldType : "text" , fieldFor: "Contracts",for :"reducingRoyalties"
        },
        {
            field_display   : ["Artist/Code","Contract Ref","Title",],
            field_code      : ["artist","contractRefNum","contractTitle"],
            className       : "ws-4" , fieldType : "text" , fieldFor: "Contracts",for :"contractStatus"
        },
        {
            field_display   : ["Artist/Code","Contract Ref","Title",],
            field_code      : ["artist","contractRefNum","contractTitle"],
            className       : "ws-4" , fieldType : "text" , fieldFor: "Contracts",for :"contractGrouping"
        },

        {
            field_display   : ["Artist/Code","Track Name","ISRC","Title","Contract Ref","Release Code"],
            field_code      : ["artist","trackName","isrc","contractTitle","contractRefNum","releaseCode"],
            className       : "ws-4" , fieldType : "text" ,fieldFor: "Contract Links"
        },


        {
            field_display   : ["Writer","Contract Ref/Title","Publisher","Collector"],
            field_code      : ["writer","contract","publisher","collector"],
            className       : "ws-4" , fieldType : "text" ,for :"mechanicalContract",
            fieldFor        : "Upload Mechanicals"
        }, 
        {
            field_display   : ["Writer","Contract Ref/Title","Publisher","Collector","Track Artist","Track Title","Release Code",],
            field_code      : ["writer","contract","publisher","collector","trackartist","tracktitle","releaseCode"],
            className       : "ws-4" , fieldType : "text" ,for :"mechanicalTrackSplits",
            fieldFor        : "Upload Mechanicals"
        },
        {
            field_display   : ["Writer","Contract Ref/Title","Publisher","Collector","Artist","ISRC","Release Code",],
            field_code      : ["writer","contract","publisher","collector","artist","isrc","releaseCode"],
            className       : "ws-4" , fieldType : "text" ,for :"mechanicalLicenses",
            fieldFor        : "Upload Mechanicals"
        },

        {
            field_display   : ["Unique ID","Invoice Ref","Account Code"],
            field_code      : ["artist","trackName","isrc","contractTitle","contractRefNum","releaseCode"],
            className       : "ws-4" , fieldType : "text" ,for:"mappingRaw",
            fieldFor        : "Mappings"
        },
        {
            field_display   : ["Rep Album Code 1/2", "Rep Track Code 1/2","Item Code","Territory","Format","Analysis Code","Artist","Title","Price" ,"Sub Provider","Sub Content","Sub Formats/Source Codes","Description","Royalty Period","Monetary Value From","Monetary Value To","Transaction Ref" ],
            field_code      : ["repAlbumCode","repTrackCode","itemCodeSold","territory","format","analysisCode" ,"artist","title","price","subprovider","subcontent","subformat","description","royaltyPeriod","monetaryFrom","monetaryTo","transRef"],
            className       : "ws-4" , fieldType : "text" ,hidden : true,for:"mappingRaw",
            fieldFor        : "Mappings"
        },

        {
            field_display   : ["Unique ID","Invoice Ref","Account Code"],
            field_code      : ["artist","trackName","isrc","contractTitle","contractRefNum","releaseCode"],
            className       : "ws-4" , fieldType : "text" ,for:"mappingRawPub",
            fieldFor        : "Mappings"
        },
        {
            field_display   : ["Song Code", "HFA Code","ISWC","Item Code","Territory","Song Title","Writer Name","Publisher Name","Header Type" ,"Royalty Period","Monetary Value From","Monetary Value To" ],
            field_code      : ["songCode","hFACode","iSWC","itemCodeSold","territory","title" ,"writerName","publisherName","headerType","royaltyPeriod","monetaryFrom","monetaryTo"],
            className       : "ws-4" , fieldType : "text" ,hidden : true,for:"mappingRawPub",
            fieldFor        : "Mappings"
        },
    ]

    public static SearchfieldName  = [
        { 
            fieldFor : "Contacts",for :"BasicContactDetails",
            fieldName : ["Contact Code","Contact Name","Contact Display","Company Name","First Name","Last Name","Email","Website URL","Address Line1","Address Line2","Address Line3","Address Line4","Address PostCode","Telephone"],
            fieldCode : ["kontactCode","kontactName","kontactDisplay","companyName","firstName","lastName","email","websiteURL","addressLine1","addressLine2","addressLine3","addressLine4","addressPostCode","telephone"]
        },
        { 
            fieldFor : "Contacts",for :"AdditionalContactRecipients",
            fieldName : ["Contact Code","Contact Name","Contact Display","Company Name","First Name","Last Name","Email","Website URL","Address Line1","Address Line2","Address Line3","Address Line4","Address PostCode","Telephone"],
            fieldCode : ["kontactCode","kontactName","kontactDisplay","companyName","firstName","lastName","email","websiteURL","addressLine1","addressLine2","addressLine3","addressLine4","addressPostCode","telephone"]
        },
        { 
            fieldFor : "Contacts",for :"ContactStatuses",
            fieldName : ["Contact Code","Contact Name","Contact Display","Company Name","First Name","Last Name","Email","Website URL","Address Line1","Address Line2","Address Line3","Address Line4","Address PostCode","Telephone"],
            fieldCode : ["kontactCode","kontactName","kontactDisplay","companyName","firstName","lastName","email","websiteURL","addressLine1","addressLine2","addressLine3","addressLine4","addressPostCode","telephone"]
        },
        { 
            fieldFor : "Contracts",
            fieldName : ["Contract Ref","Contract With","Contact Code",	"Title"	,"Type"	,"Reported"],
            fieldCode : ["contractRefNum","contractTitle","contactCode","contractTitle"	,"type"	,"reported"]
        },
        { 
            fieldFor : "Contract Links",
            fieldName : ["ReleaseCode","TrackContactCode","TrackName","TrackArtist","ISRC","PercentageOwned","ContractRefNum","ContractWith","ContactCode","ContractTitle","ContractType","Reported"],
            fieldCode : ["catLogNumber","trackContactCode","trackName","trackArtist","intStdRecCode","percentageOwned","contractRefNum","contractWith","contactCode","contractTitle","type","reported"]
        },
        { 
            fieldFor : "Upload Mechanicals",for :"mechanicalContract",
            fieldName : ["Contract Ref","Artist","Title","Formats/Source Codes","Zone","Rate"],
            fieldCode : ["contractRefNum","contractArtist","contractTitle","mechFormat","zoneCode","mechRate"]
        },
        { 
            fieldFor : "Upload Mechanicals",for :"mechanicalTrackSplits",
            fieldName : ["ISRC","Artist","Collector Name","Formats/Source Codes","Writer Name","Publisher Name","Release Code","Title","Controlled","Zone"],
            fieldCode : ["iSRC","trackArtist","collectorName","formatCode","writerName","mechRate"]
        },
        { 
            fieldFor : "Upload Mechanicals",for :"mechanicalLicenses",
            fieldName : ["Contract Ref","Artist","Title","Formats/Source Codes","Zone","Rate"],
            fieldCode : ["contractRefNum","contractArtist","contractTitle","mechFormat","zoneCode","mechRate"]
        },
        { 
            fieldFor : "Mappings",for :"mappingRaw",
            fieldName : ["Unique ID","Invoice Ref","AlbumCode 1","AlbumCode 2","TrackCode 1","TrackCode 2","UPC","Sub Content","Sub Formats/Source Codes","Sub Provider","Royalty Period"],
            fieldCode : ["UniqueID","InvoiceRef","AlbumCode1","AlbumCode2","TrackCode1","TrackCode2","UPC","SubContent","SubFormat","SubProvider","RoyaltyPeriod"]
        },
        { 
            fieldFor : "Mappings",for :"mappingRawPub",
            fieldName : ["Unique ID","Invoice Ref","SongCode 1","SongCode 2","SongCode 3","SongCode 4","HFA Code","ISWC","Reported Code","Song Title","Writer Name","Publisher Name","Header Type","Royalty Period"],
            fieldCode : ["UniqueID","invoiceref","SongCode1","SongCode2","SongCode3","SongCode4","HFACode","ISWC","ItemCodeSold","SongTitle","WriterName","PublisherName","HeaderType","RoyaltyPeriod"]
        }
    ]
    public static viewAllfieldName  = [
        { 
            fieldFor : "Contacts",
            fieldName : ["Contact Code","Contact Name","Contact Display","Company Name","First Name","Last Name","Email","Website URL","Address Line1","Address Line2","Address Line3","Address Line4","Address PostCode","Telephone"],
            fieldCode : ["kontactCode","kontactName","kontactDisplay","companyName","firstName","lastName","email","websiteURL","addressLine1","addressLine2","addressLine3","addressLine4","addressPostCode","telephone"]
        },
        { 
            fieldFor : "Contracts",
            fieldName : ["Contract Ref","Title","Desc","Contracted Party","Label","Type","Reported"],
            fieldCode : ["contractRefNum","contractTitle","contractDesc","contractedParty","labelCode","type"	,"reported"]
        },
        { 
            fieldFor : "Contract Links",
            fieldName : ["Contract ref","Contract Title","Track Artist","Code","Track Name"],
            fieldCode : ["contractRefNum","contractTitle","trackArtist","trackContactCode","trackName"]
        },

        { 
            fieldFor : "Upload Mechanicals",for : "mechanicalContract",
            fieldName : ["Contract Ref","Artist","Title","Formats/Source Codes","Zone","Rate"],
            fieldCode : ["contractRefNum","contractArtist","contractTitle","mechFormat","zoneCode","mechRate"]
        },
        { 
            fieldFor : "Upload Mechanicals",for : "mechanicalTrackSplits",
            fieldName : ["ISRC","Artist","Collector Name","Formats/Source Codes","Writer Name","Publisher Name","Controlled","Zone"],
            fieldCode : ["iSRC","trackArtist","collectorName","formatCode","writerName","publisherName","controlledYN","zoneCode"]
        },
        { 
            fieldFor : "Upload Mechanicals",for : "mechanicalLicenses",
            fieldName : ["Artist","Collector Name","Publisher Name","Writer Name","Title","ISRC","Release Code","Zone","Formats/Source Codes","Controlled"],
            fieldCode : ["artistName","collectorName","publisherName","writerName","trackTitle","iSRC","releaseCode","zoneCode","formatCode","controlledYN"]
        },

        { 
            fieldFor : "Mappings",for :"mappingFormat",
            fieldName : ["Account Code","Content","Formats/Source Codes","Provider","Map To Code"],
            fieldCode : ["account","subContent","subFormat","subProvider","maptoCode"]
        },
        { 
            fieldFor : "Mappings",for :"mappingCatalogue",
            fieldName : ["Account Code","Artist","Title","Type","Formats/Source Codes","Album Code 1","Album Code 2","Track Code 1","Track Code 2","Item Code","Rep Item Code"],
            fieldCode : ["accountCode","albumArtist","albumTitle","headerType","subFormat" ,"repAlbumCode1","repAlbumCode2","repTrackCode1" ,"repTrackCode2","itemCodeSold","repItemCodeSold"]
        },
        { 
            fieldFor : "Mappings",for :"mappingRaw",
            fieldName : ["Account Code","Invoice Ref","Artist","Title","Item Code Sold","Formats/Source Codes","Provider","Content","Monetary Value"],
            fieldCode : ["accountCode","invoiceRef","albumArtist","albumTitle","itemCodeSold","subFormat","subProvider","subContent","monetaryValue"]
        },
        { 
            fieldFor : "Mappings",for :"mappingAudit",
            fieldName : ["Account Code","Invoice Ref","Artist","Title","Item Code Sold","Formats/Source Codes","Provider","Content","Monetary Value"],
            fieldCode : ["accountCode","invoiceRef","albumArtist","albumTitle","itemCodeSold","subFormat","subProvider","subContent","monetaryValue"]
        },
        { 
            fieldFor : "Mappings",for :"mappingRawPub",
            fieldName : ["Account Code","Invoice Ref","Writer","Publisher","Title","Rep Item Code Sold","Header Type","Royalty Period","Code 1","Code 2","Code 3","Code 4","HFA","ISWC","Monetary Value"],
            fieldCode : ["accountcode","invoiceref","writerName","publisherName","albumTitle" ,"repItemCodeSold","headerType","royaltyPeriod" ,"songCode1","songCode2","songCode3","songCode4","hFACode","iSWC","monetaryValue"]
        },
        { 
            fieldFor : "Mappings",for :"mappingAnalysisCode",
            fieldName : ["Code	Group","Description	Costs","Flat Fee","Mechanicals","Points","Profits","Analysis","Analysis 2"],
            fieldCode : ["code","group","description","costs","flatFee","mechanicals","points","profits","analysisDIM1","analysisDIM2"]
        },

        { 
            fieldFor : "Mappings",for :"mappingClassList.excel",
            fieldName : ["Class List","Release Alt 1","Release Alt 2","Release Alt 3","Release Alt 4","Release Alt 5","Release Alt 6","ISRC"],
            fieldCode : ["classList","releaseAlt1","releaseAlt2","releaseAlt3","releaseAlt4","releaseAlt5","releaseAlt6","releaseIsRc"]
        },
        { 
            fieldFor : "Mappings",for :"mappingClassList.mpm",
            fieldName : ["Class List","Release Alt 1","Release Alt 2","Release Alt 3","Release Alt 4","Release Alt 5","Release Alt 6","ISRC"],
            fieldCode : ["classList","releaseAlt1","releaseAlt2","releaseAlt3","releaseAlt4","releaseAlt5","releaseAlt6","releaseIsRc"]
        },

        { 
            fieldFor : "Data Transfer",for :"artistContracts",
            fieldName : ["Artist","Title","Payor","Payee","Client Acc No"],
            fieldCode : ["f2","f1","f4","f5","f7"]
        },
        { 
            fieldFor : "Data Transfer",for :"artistPayees",
            fieldName : ["Payee","Client Acc No"],
            fieldCode : ["f1","f2"]
        },
        { 
            fieldFor : "Data Transfer",for :"transactionsLicense",
            fieldName : ["Artist","Title","Version","Catalog No","Label"],
            fieldCode : ["f4","f1","f2","f3","f5"]
        },
        { 
            fieldFor : "Data Transfer",for :"transactionsPublisher",
            fieldName : ["Publisher","Client Acc No"],
            fieldCode : ["f1","f2"]
        },
    ]

    public static logName = [
        {Contacts   : "CN"} ,
        {Contracts  : "CU"} , 
        {'Contract Links'  : "CL"} ,
        {'Upload Mechanicals'  : "MU"} ,
        {Mappings  : "MM"} ,
        {'Data Transfer'  : "DT"} , 
        {'Data Transfer'  : "Deal"} 
    ]

    static loadIFileLog(type:any, fileName:any, pageNumber:any, pageSize:any, source:any) {
        var url =  'importLog/SearchFileLog/' + type + '/' + fileName + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&source=' + source;
        return CommonUtilities.getData(url);
    };

    static loadIFileLogDetail(source:any) {
        var url =  'importLog/searchProgressImportLog/'+ source;
        return CommonUtilities.getData(url);
    };

    static dataLoadContactSearch(param :any) {
        var url = 'dataLoadContact/searchDataloaderContacts?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadContractSearch(param :any) {
        var url = 'dataLoadContract/searchDataloaderContracts?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadContractLinksSearch(param :any) {
        var url = 'contractLinks/searchContractLinks?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadContractLinksViewAll(param :any) {
        var url = 'contractLinks/detail?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadmechanicalContractSearch(param :any) {
        var url = 'mechanicalUpload/searchMechanicalContract?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadMechanicalTrackSplitsSearch(param :any) {
        var url = 'mechanicalUpload/searchMechanicalTrackSplits?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadMechanicalLicenseSearch(param :any) {
        var url = 'mechanicalUpload/searchMechanicalLicense?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadMappingfactsalesRawSearch(param :any) {
        var url = 'salesAnalysis/factsalesRaw/Search?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadMappingfactsalesRawPubSearch(param :any) {
        var url = 'salesAnalysis/factsalesRawPub/Search?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadMechanicalContractViewAll(param :any) {
        var url = 'mechanicalUpload/detail/mechanicalContract?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadMechanicalTrackSplitsViewAll(param :any) {
        var url = 'mechanicalUpload/detail/mechanicalTrackSplits?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadMechanicalLicenseViewAll(param :any) {
        var url = 'mechanicalUpload/detail/mechanicalLicense?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadMappingRawViewAll(param :any) {
        var url = 'dataLoadMapping/mappingmatch?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadMappingRawPubViewAll(param :any) {
        var url = 'dataLoadMapping/mappingmatchPub?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadmappingformatViewAll(param :any) {
        var url = 'dataLoadMapping/mappingformat?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadmappingCatalogueViewAll(param :any) {
        var url = 'dataLoadMapping/mappingcatalogue?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadmappinganalysiscodeViewAll(param :any) {
        var url = 'dataLoadMapping/mappinganalysiscode?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static dataLoadmappingclasslistViewAll(param :any) {
        var url = 'dataLoadMapping/mappingclasslist?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static getClientDetails() {
        var url = 'client/getClientDetails'
        return CommonUtilities.getData(url);
    }

    static bulkUpdateSummary(param :any) {
        var url = 'Contract/bulkUpdateSummary/listAll'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    
    /* ************************    export from table ******************************************** */
    static dataLoadContactExport(param :any) {
        var url = 'royalty/contactList?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    static dataLoadContractExport(param :any) {
        var url = 'dataLoadContract/searchDataloaderContracts/export?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.exportVsWindow(url);
    }
    static dataLoadContractLinksExport(param :any) {
        var url = 'contractLinks/searchContractLinks/export?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    static dataLoadMechanicalUploadExport(param :any) {
        var url = 'mechanicalUpload/searchMechanicalContract/export?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.exportVsWindow(url);
    }
    /* ************************    file uploading******************************************** */
    //contact
    static dataLoadContactFileUpload(file :any) {
        var url = 'dataLoadContact/fileUpload' 
        return CommonUtilities.postUploadFile(url, file ,{} );
    }
    //contract
    static dataLoadContractFileUpload(file :any) {
        var url = 'dataLoadContract/fileUpload' 
        return CommonUtilities.postUploadFile(url, file ,{} );
    }
    //contractLink
    static dataLoadcontractLinksFileUpload(file :any) {
        var url = 'contractLinks/fileUpload' 
        return CommonUtilities.postUploadFile(url, file ,{} );
    }
    //Mechanical Upload
    static dataLoadMechanicalFileUpload(file :any) {
        var url = 'mechanicalUpload/fileUpload' 
        return CommonUtilities.postUploadFile(url, file ,{} );
    }
    //Mapping
    static dataLoadMappingFileUpload(file :any) {
        var url = 'dataLoadMapping/fileUpload' 
        return CommonUtilities.postUploadFile(url, file ,{} );
    }
    //data Transfer
    static dataTransferFileUpload(file :any) {
        var url = 'dataTransfer/fileUpload' 
        return CommonUtilities.postUploadFile(url, file ,{} );
    }


    /* ************************    file upload count ******************************************** */
    //contract
    static dataLoadContractCount() {
        var url = 'dataLoadContract/count'
        return CommonUtilities.getData(url);
    }
    //contractLink
    static dataLoadcontractLinksCount() {
        var url = 'contractLinks/count'
        return CommonUtilities.getData(url);
    }
    //contact
    static dataLoadContactCount() {
        var url = 'dataLoadContact/count'
        return CommonUtilities.getData(url);
    }
    //Mechanical Upload
    static dataLoadMechanicalUploadCount(param :any) {
        var url = 'mechanicalUpload/count?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    //Mapping
    static dataLoadMappingCount(param :any) {
        var url = 'dataLoadMapping/mapping/count?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    //dataTransfer
    static dataTransferCount() {
        var url = 'dataTransfer/count';
        return CommonUtilities.getData(url);
    }

    /* ************************    filevalidation ******************************************** */
    ///contact
    static dataLoadContactValidation(param :any) {
        var url = 'dataLoadContact/validation?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    ///contract
    static dataLoadContractValidation(param :any) {
        var url = 'dataLoadContract/validation?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    ///contractLink
    static dataLoadContractLinksValidation() {
        var url = 'contractLinks/validation';
        return CommonUtilities.getData(url);
    }    
    ///mechanicalUpload
    static dataLoadmechanicalUploadValidation(param :any) {
        var url = 'mechanicalUpload/validation?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    ///dataLoadMapping
    static dataLoadMappingValidation(param :any) {
        var url = 'dataLoadMapping/validation?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    //dataTransfer
    static dataLoaddataTransferValidation(param :any) {
        var url = 'dataTransfer/validation?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    /* ************************    file commit ******************************************** */
    //contact
    static dataLoadContactCommit(param :any) {
        var url = 'dataLoadContact/commit?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    //contract
    static dataLoadContractCommit(param :any) {
        var url = 'dataLoadContract/commit?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    //contractLink
    static dataLoadContractLinksCommit() {
        var url = 'contractLinks/commit'
        return CommonUtilities.getData(url);
    }
    //mechanicalUpload
    static dataLoadMechanicalCommit(param :any) {
        var url = 'mechanicalUpload/commit?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    //dataLoadMapping
    static dataLoadMappingCommit(param :any) {
        var url = 'dataLoadMapping/mapping/commit?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    //dataTransfer
    static dataTransferCommit(param :any) {
        var url = 'dataTransfer/commit?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }


    /* ************************    file upload clear ******************************************** */
    //contact
    static dataLoadContactClear() {
        var url = 'dataLoadContact/clear';
        return CommonUtilities.getData(url);
    }
    //contract
    static dataLoadContractClear() {
        var url = 'dataLoadContract/clear';
        return CommonUtilities.getData(url);
    }
    //contractLink
    static dataLoadContractLinksClear() {
        var url = 'contractLinks/clear';
        return CommonUtilities.getData(url);
    }
    //mechanicalUpload
    static dataLoadMechanicalClear(param :any) {
        var url = 'mechanicalUpload/clear?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    //dataLoadMapping
    static dataLoadMappingClear(param :any) {
        var url = 'dataLoadMapping/mapping/clear?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }
    //dataLoadTransfer
    static dataLoadTransferClear(param :any) {
        var url = 'dataTransfer/clear?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

}
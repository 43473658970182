import React, { useState } from "react";
import NameForm from "./Login";
import UserService from "./UserService";


export const ResetPassword_Template = () => {

    const[resetPassword , setResetPassword] = useState({
        email: "",
        password: ""
    });

    const [error, setError] = React.useState({
        message: "",
        type: ""
    });


    const sendResetLink=()=>{
        UserService.forgetPassword(resetPassword)
        .then((res) => {
            //setResetPassword(res);

            if (res.result && res.result == true){
                setError(existingValue => ({ ...existingValue , message: "A temporary password has been sent to your email." , type:"success"}) )
            }

            else if (res.errorCode = "2007"){
                setError( exitingValue => ({ ...exitingValue , message: "Invalid user email." , type:"error"}) )
            }
        })
    } 

    const setDefault =() =>{
        setError( existingValue => ({ ...existingValue , message: "" , type:""}) )
    }

    const switchTabs = () => {
        
        <NameForm/>
       
    }

    return(
        <div>
            <h1 className="text-center text-xl ws-12 mtl">Reset your password</h1>
              <div className='grey pLarge text-center'>
                Enter your username and we’ll send a password reset link to the recovery email address for this account.
              </div>
              <div className="box">
                  <div className="box-content mtl  ">
                      <form className="form-horizontal form-login" >

                          <div className="ws-12 pbl">
                              <span className="ws-12 mb text-bold">Username<span className='turquoise'>*</span></span>
                              <input placeholder="Username" type="text" 
                              className='ws-12 pull-none' 
                              value={resetPassword.email}
                              onKeyDown={(e) => {if(e.code == "Enter"){sendResetLink()} }}
                              onChange={(e) => setResetPassword(passValues => ({
                                ...passValues,
                                email: e.target.value
                              }))} 
                              onFocus={setDefault}/>
                          </div>

                          <div className="ws-12 mtl ptl">
                          {error.message && <div className={ (error.type=='error')? 'errorMessage' : 'successMessage'}> {error.message} </div>}

                              <div className='grey pLarge text-left'>
                                Remember your password? Go and <button className="btn-white  pull-none turquoise text-underline m0 p0" onClick={switchTabs}>sign in</button>
                              </div>

                              <hr className='ws-12 mtl'></hr>

                              <a className='btn-search btn-xxl pull-right plx prx mtl' onClick={()=> {sendResetLink()}} >
                                Send password reset email
                              </a>
                          </div>
                      </form>
                  </div>

              </div>
                
        </div>

    )
}
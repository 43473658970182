import { CommonUtilities } from "commonUtils";
import dashboardService from "components/Dashboard/dashboardServices";
import Creator from "components/directories/creator";
import Tooltip from "rc-tooltip";
import React, { Suspense, useState, useEffect } from "react";
import { Modal } from 'react-responsive-modal';


export default function ItunesScraping({ onSelectItem }: any) {

    const [showtab, setShowTab] = useState(false);
    const [resourceCall, setResourceCall] = useState('https://itunes.apple.com/search?term=');
    const [requestJSON, setRequestJSON] = useState('jack johnson');
    const [resourceResponse, setResourceResponse] = useState([]);



    async function searchData() {

        console.log(resourceCall);

        const url = resourceCall + encodeURIComponent(requestJSON);

        const artistParameters = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            }
        }


        console.log(url);

        const artistID = await fetch(url, artistParameters)
            .then((response) => response.json())
            .then((data) => ITunesResponse(data));

        console.log(resourceResponse);

    }

    const ITunesResponse = (data: any) => {
        setResourceResponse(data.results);
    };

    const handleLinkButtonClick = (item: any) => {

        onSelectItem(item);
    };


    return (
        <div className="pull-left ws-12 full-width">
            <div className="box">
                <div className="box-header">
                    iTunes
                </div>
                <div className="box-content mtl ptl">

                    <input type="text" className="ws-12 mtl" value={resourceCall} onChange={(e) => setResourceCall(e.target.value)} />

                    <input type="text" className="ws-12 mtll" value={requestJSON} onChange={(e) => setRequestJSON(e.target.value)} />

                    {resourceResponse && (
                        <div>
                            {resourceResponse.slice(0, 5).map((item: any, index: any) => (
                                <div className="creatorImg pm pull-left" key={index}>
                                    <div className="box shadowCoverPicture shadow miniCreatorImg">
                                        <div className="coverPicture">
                                        <div className="labelOverlay mt">
                                            <i onClick={() => handleLinkButtonClick(item)} className="icon-LinkMade text-m white text-Shadow ml"></i>

                                        </div>

                                            <Tooltip placement="right" overlayStyle={{ width: '400px', height: '50px' }} trigger={['hover']} overlay={
                                                <div className='box'>
                                                    <div className='box-header'>
                                                        Track Details
                                                    </div>
                                                    <div className='box-content'>
                                                        <div className="mb">
                                                            <span style={{ fontWeight: 'bold' }}>Track Name:</span> {item.trackName}
                                                        </div>
                                                        <div className="mb">
                                                            <span style={{ fontWeight: 'bold' }}>Artist Name:</span>{item.artistName}
                                                        </div>

                                                        <div className="mb">
                                                            <span style={{ fontWeight: 'bold' }}>Country:</span>{item.country}
                                                        </div>

                                                        <div className="mb">
                                                            <span style={{ fontWeight: 'bold' }}>Currency:</span>{item.currency}
                                                        </div>

                                                        <div className="mb">
                                                            <span style={{ fontWeight: 'bold' }}>Release Date:</span>{item.releaseDate}
                                                        </div>
                                                    </div>
                                                </div>}>

                                                
                                                <i className="icon-info1 pull-right positionAbsolute rightReset mr mt text-m white text-Shadow"> </i>

                                            </Tooltip>
<img src={item.artworkUrl100} alt={item.trackName} />
                                            <div className="bottomOverlayLabel">

                                                <label className="control-label ws-12"> {CommonUtilities.longCharacter (item.trackName,18)}</label>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                </div>

                <div className="box-footer">
                    <button className="btn-success" onClick={() => searchData()}>
                        Request
                    </button>
                </div>
            </div>
        </div>
    )
}

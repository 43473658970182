import { CommonUtilities } from "../../commonUtils";


export default class contactService {

    static ContactListing(data : {}){
        var url = 'royalty/contactList';
        return CommonUtilities.postData(url, data);
    }

    //https://qa.onkorrect.com/Api/contacts/contactNameOrCode?contactNameOrCode=sam&digitalOnly=true&creator=true
    static SelectContact(data : {}){
        var url = 'contacts/contactNameOrCode?' + CommonUtilities.buildQueryString(data , true , true) ;
       return CommonUtilities.getData(url);
    }
    
    static deleteContact(data : any){
        var url = 'contacts/info/' + data.kontactId ;
       return CommonUtilities.deleteData(url);
    }

    //https://qa.onkorrect.com/Api/Contract/kontactCompanyInfo/list?imprint=label&pageNumber=2&pageSize=10&
    static kontactCompanyInfo(data : {}){
            var url = 'Contract/kontactCompanyInfo/list?' + CommonUtilities.buildQueryString(data , true , true) ;
           return CommonUtilities.getData(url);
    }

    static exportContact(data: any) {
        const url = 'royalty/contactList?'+ CommonUtilities.buildQueryString(data , true , true) ;
        return CommonUtilities.exportWithToken('GET', url, {}, data.exportType, data.type);
    };

    
    static savecontactsGridResult(data : any){
        var url = 'metadata/globalUserSearch?type=contactsGridResult';
        return CommonUtilities.postData(url, data);
    }

    static royaltylistContactAsset(data : any){
        var url = 'royalty/listContactAsset?'+ CommonUtilities.buildQueryString(data , true , true) ;
        return CommonUtilities.getData(url);
    }

    static royaltylistContractContactAsset(data : any){
        var url = 'royalty/listContractContactAsset?'+ CommonUtilities.buildQueryString(data , true , true) ;
        return CommonUtilities.getData(url);
    }

    static deleteContactAsset(data : any){
        var url = 'royalty/deleteContactAsset/'+ data ;
        return CommonUtilities.deleteData(url);
    }

    static saveContactAsset(data : any){
        var url = 'royalty/saveContactAsset';
        return CommonUtilities.postData(url, data);
    }
    
}
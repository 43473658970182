import { CommonUtilities }  from '../../commonUtils';

export default class ClientProfileSevices {

    static AuditTrailListing() {
        var url = 'contract/emailActivity/list?event=open,bounce,deferred,delivered,dropped,processed&queueId=&email=&dateFrom=&dateTo=&pageNumber=1&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AuditTrailChronological(param:any) {
        var url = 'auditTrail/searchLog?'+ CommonUtilities.buildQueryString(param , true , true);;
        return CommonUtilities.getData(url);
    }

    static AuditTrailCount(param:any) {
        var url = 'auditTrail/searchLogCount?'+ CommonUtilities.buildQueryString(param , true , true);;
        return CommonUtilities.getData(url);
    }

        static CurrentJobs( Data:{}) {            
        //var url = 'contracts/jobStatus?include=1%7C2&pageSize=10&pageNumber=1';
        var url = 'contracts/jobStatus?include=1|2&' +Data;
        return CommonUtilities.getData(url);
    }

    static AsynchronousJobs(Data:{}) {
        var url = 'common/getSqlJob?' + Data;
        var url1 = 'common/getSqlJob?pageSize=10&pageNumber=1';
        return CommonUtilities.getData(url1);
    }


    static EmailStatementJobs(Data:{}) {
        var url = 'contracts/jobStatus?include=3&'+ Data;
        return CommonUtilities.getData(url);
    }

    static CurrentJobsExport(Data : any) {
        var url = 'contracts/jobStatus/export?include='+ Data;
       // return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'JobStatus');
       CommonUtilities.exportVsWindow(url);
    }

    static CurrentJobsClear(Data : any) {
        var url = 'contracts/jobStatus?include=' + Data.include + 'jobId='+ Data.jobId;
        return CommonUtilities.deleteData(url);
    }
    
    static EmailJobsClear(Data : any) {
        var url = 'contracts/jobStatus?include=' + Data.include + 'jobId='+ Data.jobId;
        return CommonUtilities.deleteData(url);
    }

    static EmailListing(data:any) {
        console.log(data)
        //var url = 'contract/emailActivity/list?event='+data.event+'&queueId=&email='+data.email +'&dateFrom='+data.dateFrom +'&dateTo=' +data.dateTo +'&pageNumber='+data.pageNumber +'&pageSize=' +data.pageSize ;
       var url = 'contract/emailActivity/list?'+ CommonUtilities.buildQueryString(data , true , true) ;
        return CommonUtilities.getData(url);
    }

    /*Email Dropdown filters */ 

    static EmailFilters(Data:{}) {
        var url = 'contract/emailActivity/list?event=open'+ Data;
        return CommonUtilities.getData(url);
    }

    static EmailExport(data:any) {
        var url = 'contract/emailActivity/list?'+  CommonUtilities.buildQueryString(data , true , true) ;
       // return CommonUtilities.getData(url);
       return CommonUtilities.exportWithToken('GET', url, {}, '_blank', "EmailFeed");
    }

    static getSalesAnalysissales(data:any) {
        var url = 'salesAnalysis/getSalesAnalysis/sales?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static getSalesAnalysismechanical(data:any) {
        var url = 'salesAnalysis/getSalesAnalysis/mechanical?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static getSalesAnalysispublishing(data:any) {
        var url = 'salesAnalysis/getSalesAnalysis/publishing?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static getSalesAnalysiscosts(data:any) {
        var url = 'salesAnalysis/getSalesAnalysis/costs?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }
    
    static getSalesAnalysisadvances(data:any) {
        var url = 'salesAnalysis/getSalesAnalysis/advances?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static getSalesAnalysislicensing(data:any) {
        var url = 'salesAnalysis/getSalesAnalysis/licensing?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }


    static getAllFormatCodeByParentGroup(data:any) {
        var url = 'release/getAllFormatCodeByParentGroup?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static FormatReleaseListing(data:any) {
        var url = 'release/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static MechanicalRateListing(data:any) {
        var url = 'contracts/mechRate/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static MechanicalRateCount(data:any) {
        var url = 'contracts/mechRate/count?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static classlistListing(data:any) {
        var url = 'salesAnalysis/classlist/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static territoryList(data:any) {
        var url = 'systemListing/territory/territoryList?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static accountSubproviderList(data:any) {
        var url = 'systemListing/accountSubprovider/ASPlist?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static contractStatusList(data:any) {
        var url = 'contracts/contractStatus/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static projectStatusList(data:any) {
        var url = 'contracts/projectStatus/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static statusAccountList(data:any) {
        var url = 'common/statusAccount/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static statusAccountExtList(data:any) {
        var url = 'common/statusAccountExt/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static accountSubproviderExport(data:any) {
        var url = 'systemListing/accountSubprovider/ASPlist/Export?'+  CommonUtilities.buildQueryString(data , true , true) ;
       // return CommonUtilities.getData(url);
       return CommonUtilities.exportWithToken('GET', url, {}, '_blank', "accountSubprovider");
    }

    static statusStatementList(data:any) {
        var url = 'common/statusStatement/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static statusPayeeList(data:any) {
        var url = 'common/statusPayee/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static statusProductList(data:any) {
        var url = 'common/statusProduct/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static statusProductExtList(data:any) {
        var url = 'common/statusProductExt/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static saveSalesAnalysis (param:any) {
        var url = 'salesAnalysis/saveSalesAnalysis/' ;
        return CommonUtilities.postData(url, param);
    }

    static deleteSalesAnalysis (param:any) {
        var url = 'salesAnalysis/deleteSalesAnalysis/' ;
        return CommonUtilities.postData(url, param);
    }


    static saveFormat (param:any) {
        var url = 'release/saveFormat/' ;
        return CommonUtilities.postData(url, param);
    }

    static deleteFormat (param:any ,ID :any) {
        var url = 'release/deleteFormat/'+ID ;
        return CommonUtilities.postData(url, param);
    }

    static releaseSave (param:any) {
        var url = 'release/save/';
        return CommonUtilities.postData(url, param);
    }

    static releasedelete (param:any) {
        var url = 'release/delete/'+ param;
        return CommonUtilities.deleteData(url);
    }

    static mechRateSave (param:any) {
        var url = 'contracts/mechRate/save';
        return CommonUtilities.postData(url, param);
    }

    static mechRatedelete (param:any) {
        var url = 'contracts/mechRate/'+ param;
        return CommonUtilities.deleteData(url);
    }

    static classlistSave (param:any) {
        var url = 'salesAnalysis/classlist/save';
        return CommonUtilities.postData(url, param);
    }

    static classlistdelete (param:any) {
        var url = 'salesAnalysis/classlist/delete/'+ param;
        return CommonUtilities.deleteData(url);
    }
    
    static territorySave (param:any) {
        var url = 'systemListing/territory/save';
        return CommonUtilities.postData(url, param);
    }

    static territorydelete (param:any) {
        var url = 'systemListing/territory/delete/'+ param;
        return CommonUtilities.deleteData(url);
    }
        
    static accountSubproviderSave (param:any) {
        var url = 'systemListing/accountSubprovider/save';
        return CommonUtilities.postData(url, param);
    }

    static accountSubproviderdelete (param:any) {
        var url = 'systemListing/accountSubprovider/delete/'+ param;
        return CommonUtilities.deleteData(url);
    }

    static contractStatusSave (param:any) {
        var url = 'contracts/contractStatus/save/';
        return CommonUtilities.postData(url, param);
    }

    static contractStatusdelete (param:any) {
        var url = 'contracts/contractStatus/delete/'+ param;
        return CommonUtilities.deleteData(url);
    }

    static projectStatusSave (param:any) {
        var url = 'contracts/projectStatus/save/';
        return CommonUtilities.postData(url, param);
    }

    static projectStatusdelete (param:any) {
        var url = 'contracts/projectStatus/delete/'+ param;
        return CommonUtilities.deleteData(url);
    }

    static statusAccountSave (param:any) {
        var url = 'contracts/statusAccount/save/';
        return CommonUtilities.postData(url, param);
    }

    static statusAccountdelete (param:any) {
        var url = 'contracts/statusAccount/delete/'+ param;
        return CommonUtilities.deleteData(url);
    }

    static statusAccountExtSave (param:any) {
        var url = 'contracts/statusAccountExt/save/';
        return CommonUtilities.postData(url, param);
    }

    static statusAccountExtdelete (param:any) {
        var url = 'contracts/statusAccountExt/delete/'+ param;
        return CommonUtilities.deleteData(url);
    }

    static statusStatementSave (param:any) {
        var url = 'common/statusStatement/save/';
        return CommonUtilities.postData(url, param);
    }

    static statusStatementdelete (param:any) {
        var url = 'common/statusStatement/delete/'+ param;
        return CommonUtilities.deleteData(url);
    }


    static statusPayeeSave (param:any) {
        var url = 'common/statusPayee/save/';
        return CommonUtilities.postData(url, param);
    }

    static statusPayeedelete (param:any) {
        var url = 'common/statusPayee/delete/'+ param;
        return CommonUtilities.deleteData(url);
    }

    static statusProductSave (param:any) {
        var url = 'common/statusProduct/save/';
        return CommonUtilities.postData(url, param);
    }

    static statusProductdelete (param:any) {
        var url = 'common/statusProduct/delete/'+ param;
        return CommonUtilities.deleteData(url);
    }


    static statusProductExtSave (param:any) {
        var url = 'common/statusProductExt/save/';
        return CommonUtilities.postData(url, param);
    }

    static statusProductExtdelete (param:any) {
        var url = 'common/statusProductExt/delete/'+ param;
        return CommonUtilities.deleteData(url);
    }

    static tagColorSave (param:any) {
        var url = 'common/tagColor/save';
        return CommonUtilities.postData(url, param);
    }

    static roleMatrix(data:any) {
        var url = 'userManagement/userRole/roleMatrix?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static roleNameList(data:any) {
        var url = 'userManagement/roles/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static subEntityRoleList(data:any) {
        var url = 'userManagement/subEntityRole/roleMatrix?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static paymentRoleList(data:any) {
        var url = 'userManagement/paymentRole/list?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static usersList(data:any) {
        var url = 'users?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static affiliateContracts(data:any) {
        var url = 'affiliate/affiliateContracts';
        return CommonUtilities.postData(url, data);
    }

    static acontractsList(data:any) {
        var url = 'affiliate/contractsList';
        return CommonUtilities.postData(url, data);
    }

    static saveContractsForAffiliateUser(data:any) {
        var url = 'affiliate/saveContractsForAffiliateUser';
        return CommonUtilities.postData(url, data);
    }
   
    
    static affiliateContractsList(data:any) {
        var url = 'affiliate/contractsList';
        return CommonUtilities.postData(url, data);
    }    

    static userRegistration(data:any ,type :any ) {
        var url = 'register?'+  CommonUtilities.buildQueryString(type , true , true);
        return CommonUtilities.postData(url, data);
    }

    static periodManagementList(data:any) {
        var url = 'common/periodManagement/periodManagementList?'+  CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }
}
import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import clientProfileServices from "./clientProfileServices";
import Pagination from "../../Header/Pagination";

export default function ManageTerritory(param : any) {

    let [territoryGroupsUnassignZoneSearch, initTerritoryGroupsUnassignZoneSearch] = useState<any[]>([]);
    let [clientTerritoriesZonesPagination, initClientTerritoriesZonesPagination] = useState<any[]>([]);
    let [clientTerritoriesZonesSearch, initClientTerritoriesZonesSearch] = useState<any[]>([]);
    let [searchZonesMechanicalSearch, initSearchZonesMechanicalSearch] = useState<any[]>([]);
    let [territoryGroupsUnassignZone, initTerritoryGroupsUnassignZone] = useState<any[]>([]);
    let [clientTerritoriesZones, initClientTerritoriesZones] = useState<any[]>([]);
    let [searchZonesMechanical, initSearchZonesMechanical] = useState<any[]>([]);

    const [allZonesExcludingClient, initAllZonesExcludingClient] = useState<any[]>([]);
    const [allClientZones, initAllClientZones] = useState<any[]>([]);

    const [avalaiblesearchLength , setavalaibleSearchLength] = useState(1);
    const [avalaiblelinkedtotalRows , setavalaibleTotalRows] = useState(0);
    const [linkedsearchLength , setlinkedSearchLength] = useState(1);
    const [linkedtotalRows , setlinkedTotalRows] = useState(0);

    const [deleteAlert , setdeleteAlert] = useState(false);
    const [openFirst, setOpenFirst] = React.useState(false);
    const [saveAlert , setsaveAlert] = useState(false);
    const [saveError , setsaveError] = useState(false);
    const [saveFlag , setsaveFlag] = useState(false); 

    const returnValue = (e : any) => {
        param.onChange(e)
        setOpenFirst(false) 
    }

    useEffect(() => {
        if(param.param){
            apiCall();           
        }

    }, []);

    const apiCall = () => {
        clientProfileServices.getAllZonesExcludingClient().then((res) => {
            initAllZonesExcludingClient(res)
        });

        clientProfileServices.getAllClientZones({"isAllZone" : false}).then((res) => {
            initAllClientZones(res)
        });

        clientProfileServices.getClientTerritoriesZones().then((res) => {
            initClientTerritoriesZones(res)
        });               
    
        clientProfileServices.territoryGroupsUnassignZone().then((res) => {
            initTerritoryGroupsUnassignZone(res)
            setavalaibleTotalRows(res.length)
            initTerritoryGroupsUnassignZoneSearch(res)

            if(res.length > 0){
                territoryGroupsUnassignZone = res;
                var obj = territoryGroupsUnassignZone.slice(0,10) 
                initTerritoryGroupsUnassignZone(obj)   
            }
        });
    
        clientProfileServices.searchZonesMechanical({"isAllZone" : param.selected.zoneID}).then((res) => {
            initSearchZonesMechanical(res)
            setlinkedTotalRows(res.length)
            initSearchZonesMechanicalSearch(res)
            if(res.length > 0){
                searchZonesMechanical = res;
                var obj = searchZonesMechanical.slice(0,10) 
                initSearchZonesMechanical(obj)   
            }

        });
    }

    const linkedSearch = (e:any ) => {
        if(e != ''){
            searchZonesMechanical = searchZonesMechanicalSearch;
            var obj = searchZonesMechanical.filter((item)=> {return item.territoryName.toLocaleLowerCase().includes(e)    })
            searchZonesMechanical = obj ;
            initSearchZonesMechanical(searchZonesMechanical)
            setlinkedSearchLength(searchZonesMechanical.length)
            
            var obj2 = searchZonesMechanical.slice(0,10) 
            initSearchZonesMechanical(obj2) 
            
        }else{
            initSearchZonesMechanical(searchZonesMechanicalSearch);
            setlinkedSearchLength(searchZonesMechanicalSearch.length)

            var object = searchZonesMechanicalSearch
            var obj2 = object.slice(0,10) 
            initSearchZonesMechanical(obj2) 
        }
    }

    const availableSearch = (e:any ) => {
        if(e != ''){
            territoryGroupsUnassignZone = territoryGroupsUnassignZoneSearch;
            var obj = territoryGroupsUnassignZone.filter((item)=> {return item.territoryName.toLocaleLowerCase().includes(e)    })
            territoryGroupsUnassignZone = obj ;
            initTerritoryGroupsUnassignZone(territoryGroupsUnassignZone)
            setavalaibleTotalRows(territoryGroupsUnassignZone.length)

        }else{
            initTerritoryGroupsUnassignZone(territoryGroupsUnassignZoneSearch)
            setavalaibleTotalRows(territoryGroupsUnassignZoneSearch.length)

        }
    }

    const selectTerritory = (e:any) =>{
        if(e != 'all'){
            var obj = territoryGroupsUnassignZone.filter( (item) =>{ return item.territoryCode != e.territoryCode});
            initTerritoryGroupsUnassignZone(obj)
            initSearchZonesMechanical([...searchZonesMechanical , e]) 
            
            var obj = searchZonesMechanical.slice(0,10) 
            initSearchZonesMechanical(obj) 
            
            initSearchZonesMechanicalSearch([...searchZonesMechanicalSearch , e])         
        }else {

            for(var i = 0 ; i < territoryGroupsUnassignZone.length ; i++){
                searchZonesMechanical.push(territoryGroupsUnassignZone[i]);
                searchZonesMechanicalSearch.push(territoryGroupsUnassignZone[i]);
            }
            
            initSearchZonesMechanicalSearch(searchZonesMechanicalSearch)  
            initSearchZonesMechanical(searchZonesMechanical);
            territoryGroupsUnassignZone =[];
            initTerritoryGroupsUnassignZone(territoryGroupsUnassignZone); 
            
            var object = searchZonesMechanicalSearch
            var obj = object.slice(0,10) 
            initSearchZonesMechanical(obj) 
    
        }
    }

    const deleteTerritory = (e:any) =>{
        var obj = searchZonesMechanical.filter( (item) =>{ return item.territoryName != e.territoryName});
        searchZonesMechanical = obj;
        initSearchZonesMechanical(searchZonesMechanical);
        initTerritoryGroupsUnassignZone([...territoryGroupsUnassignZone , e]);   
        
        var obj2 = searchZonesMechanicalSearch.filter( (item) =>{ return item.territoryName != e.territoryName});
        searchZonesMechanicalSearch = obj2
        initSearchZonesMechanicalSearch(searchZonesMechanicalSearch)
        //searchZonesMechanicalSearch
       /// initSearchZonesMechanicalSearch([...territoryGroupsUnassignZone , e])
    }

    const saveTerritories = () => {

        console.log(searchZonesMechanicalSearch)
       if(searchZonesMechanicalSearch.length != 0){
            clientProfileServices.saveZonesMechanical(searchZonesMechanicalSearch).then((res) => {
                apiCall();
                setsaveAlert(true)
            });
        }else{
            setsaveFlag(true);
        }

        setTimeout(function(){
            setsaveAlert(false)
        }, 5000);

    }

    const paginationAvailable  =(n:any) => {
        //territoryGroupsUnassignZone

        territoryGroupsUnassignZone = territoryGroupsUnassignZoneSearch;
        var num = n.pageNumber;
        var size  = n.pageSize;
        var obj = territoryGroupsUnassignZone.slice((num -1 ) * size,(size*num ))       
        initTerritoryGroupsUnassignZone(obj) 
    }

    const paginationLinked  =(n:any) => {
        searchZonesMechanical = searchZonesMechanicalSearch;
        var num = n.pageNumber;
        var size  = n.pageSize;
        var obj = searchZonesMechanical.slice((num -1 ) * size,(size*num ))       
        initSearchZonesMechanical(obj)  
    }

    return (
        <Modal open={param.param} onClose={() => returnValue(1)} center>
            <div className="box">

                <div className="box-header well">
                    <h2 ><i className="icon-globe1 pt pr"></i>Manage Territories </h2>
                </div>

                <div className="box-content form-box">
                    {saveAlert && <div className="alert alert-success">Territories saved successfully</div>}
                    {saveError && <div className="alert alert-danger">Choose at least 1 territory to save</div>}
                    {saveFlag && <div className="alert alert-danger"> You need to associate at least 1 territory to zone <i ></i></div>}

                    <div className="row-fluid form-condensed">
                        <div className="ws-6">
                            <div className="ws-6">
                                <div className="form-format"><h2 >Linked Territories</h2></div>
                            </div>
                            {(linkedtotalRows != 0) && <div className="form-format pull-left mt mb ws-12">
                                <div className="label-entries ws-12">
                                    <div className="ws-3"><button type="button" className="btn-success"><i className="icon-search"></i> Filter</button></div>
                                    <div className="ws-7"><input type="text" placeholder="Search" onChange={(e:any)=>linkedSearch(e.target.value)}/></div>
                                </div>
                            </div>}
                            {(searchZonesMechanical.length == 0 && linkedsearchLength !=0) && <div className="alert alert-info ws-12 m0"> No linked territories to display</div>}
                            {(linkedsearchLength ==0 && (searchZonesMechanical.length == 0)) &&<div className="alert alert-info ws-12 m0" >No territory matches your search criteria</div>}

                            <table className="table">
                                <tbody>

                                    { searchZonesMechanical.map((value) =>{

                                        return (
                                            <tr >
                                                <td><a className="btn btn-danger btn-small" onClick={()=>deleteTerritory(value)}><i className="icon-trashcan"></i></a></td>
                                                <td >{value.territoryName}</td>
                                                <td >{value.territoryLink}</td>
                                            </tr>
                                            )
                                        })}
                                </tbody>
                            </table>

                            <div className="ws-12">
                                {
                                    (() =>{
                                        if(linkedtotalRows> 10 && searchZonesMechanical.length > 9){
                                            return <Pagination onChange={paginationLinked} totalRows={linkedtotalRows} />
                                        }   
                                    })()
                                }
                            </div>
                        </div>
                        <div className="ws-6">
                            <div className="ws-6">
                                <div className="form-format"><h2 >List of available territories</h2></div>
                            </div>
                            { <div className="form-format pull-left mt mb ws-12">
                                <div className="label-entries ws-12">
                                    <div className="ws-3"><button type="button" className="btn-success"> <i className="icon-search"></i> Filter </button></div>
                                    <div className="ws-7"><input type="text" placeholder="Search" onChange={(e:any)=>availableSearch(e.target.value)}/></div>
                                </div>
                            </div>}
                            {(territoryGroupsUnassignZone.length==0 && avalaiblelinkedtotalRows !=0) && <div className="alert alert-info ws-12 m0">No available territories to display</div>}
                            {(avalaiblelinkedtotalRows==0) && <div className="alert alert-info ws-12 m0">No territory matches your search criteria</div>}
                            {(territoryGroupsUnassignZone.length!=0) && <table className="table">
                                <thead>
                                    <tr>
                                        <th><a className="btn btn-info btn-small" onClick={()=>selectTerritory("all")}><i className="icon-arrow-left"></i></a></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        territoryGroupsUnassignZone.map( (value) =>{

                                            return (
                                                <tr>
                                                    <td> <a className="btn btn-info btn-small" onClick={()=>selectTerritory(value)}><i className="icon-arrow-left"></i></a> </td>
                                                    <td>{value.territoryName}</td>
                                                    <td>{value.territoryCode}</td>
                                                </tr>
                                            )
                                        })
                                    }                               

                                </tbody>
                            </table>}

                            <div className="clearfix">
                            {
                                (() =>{
                                    if(avalaiblelinkedtotalRows && avalaiblelinkedtotalRows>10){
                                        return <Pagination onChange={paginationAvailable} totalRows={avalaiblelinkedtotalRows} />
                                    }   
                                })()
                            }
                            </div>
                        </div>

                    </div>
                </div>
                <div className="box-footer pull-right">
                    <button className="btn btn-pink btn-sm " onClick={apiCall}><i className="icon-reload"></i> Reset </button>
                    <button className="btn btn-success btn-sm " onClick={saveTerritories}><i className="icon-save"></i> Save </button>
                    <button className="btn btn-danger btn-sm " onClick={() => returnValue(2)}><i className="icon-close"></i> Close </button>

                </div>
            </div>


        </Modal>
    )

}
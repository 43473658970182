import React, { useEffect, useState } from "react";
import ClientProfileServices from "../clientProfileServices";

export default function Logo_Template() {
    global.portalLogo = '/assets/images/logos.png'; 
    const [logo, setLogo] = useState<any>([]);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [logoSaved, setLogoSaved] = useState(false);

    useEffect(() => {
        ClientProfileServices.getClientImage()
            .then((res) => {
                setLogo(res)
                console.log(res);
                
        global.portalLogo = res; 
            })
    }, []);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setLogo(URL.createObjectURL(file));
        }
        console.log(logo);
        console.log(selectedFile) ;
        global.portalLogo = logo; 
    };

    const handleSave = async () => {
        console.log("handleSave function called");

        if (selectedFile) {

            // Upload the logo
            const uploadResponse = await ClientProfileServices.uploadLogo(selectedFile);

            if (uploadResponse) {
                console.log("Logo uploaded successfully");

                // Now save the logo
                const saveResponse = await ClientProfileServices.saveClientLogo(uploadResponse.data);
                console.log(saveResponse);

                console.log(global.portalLogo);
                console.log(global.portalLogo);

                if (saveResponse) {

                    setLogoSaved(true);
                    //setLogo(uploadResponse.data);


                    setTimeout(() => {
                        setLogoSaved(false);
                    }, 3000);

                }

                else {
                    console.error("Failed to save client logo data");
                }
            } else {
                console.error("Logo upload failed");
            }
        }
    };

    return (
        <div className="box box-add-region">

            <div className="box-header well">
                <h2><i className="icon-globe1 pt pr"></i>Upload Logo</h2>
            </div>

            <div className="box-content form-box">
                <form className="form-horizontal" name="frmLogo">

                    {logoSaved && (
                        <div className="alert alert-info">
                            Logo has been uploaded successfully
                        </div>
                    )}

                    {/* {!logoSaved && (
                        <div className="alert alert-danger mtl">
                            Rejected file. Only images of type .png are allowed
                        </div>
                    )} */}


                    <div className="box mtl pLarge">
                        <div className="ws-3 pLarge">
                            <h1 className="text-xl ws-12 mbl mtxxl ptxl">Upload Logo</h1>
                            <div className="ws-12 grey pbl mtl">We recommend 500px x 500px minimum file size
                                Only .png files accepted</div>
                        </div>

                        <div className="ws-9">

                            <div className="ws-12 pm">
                                <div className="ws-6">
                                    <label className=" text-center pull-left">

                                        {<img src={logo} className="icon-user1 paleTurquoise positionRelative pLarge mtl mbl width200 height200" alt="Logo" />}
                                        {/* {selectedFile && (<img className="icon-user1 paleTurquoise positionRelative pLarge mtl mbl width200 height200" src={URL.createObjectURL(selectedFile)} />)} */}

                                    </label>


                                    <div className="pull-left mtxl ptl mll">
                                        <div className="pull-left mtxl ptxl mll">
                                            <label className="mtxl btn-search pLarge ptl pbl" >
                                                <input type="file" accept="image/*" className="hide" onChange={handleFileChange} /> Select Image</label>                                           
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </form>
            </div>

            <div className="box-footer pull-right">
                <a className="btn btn-success btn-sm" onClick={handleSave}>
                    <i className="icon-save"></i> Save</a>
            </div>
        </div>


    )

}
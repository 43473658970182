import React, { useEffect, useState } from "react";
import clientProfileServices from "../clientProfileServices";

export const Reserves_Template = () => {

    const [reserveslist, initReservesList] = useState<any>([]);
    const [checked, setChecked] = useState<any>({
        reserveDigital: false,
        reserveNegativeProd: false,
        reserveLicensingIncome: false,
        reserveSpreadEvenly: false,
        reserveLastPeriod: false,
        reservePositiveAmount: false,
    });

    useEffect(() => {
        clientProfileServices.getClient()
            .then((res) => {
                initReservesList(res[0])

                //checkboxes
                setChecked({
                    reserveDigital: res[0].reserveDigital === 1,
                    reserveNegativeProd: res[0].reserveNegativeProd === 1,
                    reserveLicensingIncome: res[0].reserveLicensingIncome === 1,
                    reserveSpreadEvenly: res[0].reserveSpreadEvenly === 1,
                    reserveLastPeriod: res[0].reserveLastPeriod === 1,
                    reservePositiveAmount: res[0].reservePositiveAmount === 1,

                });


            })
    }, []);

    const handleInputChange = (checkboxName: string) => {

        if (checkboxName === 'reserveSpreadEvenly' && checked.reserveLastPeriod) {
            setChecked((prevChecked: any) => ({
                ...prevChecked,
                reserveLastPeriod: false,
                reserveSpreadEvenly: !prevChecked.reserveSpreadEvenly,
            }));
        } else if (checkboxName === 'reserveLastPeriod' && checked.reserveSpreadEvenly) {
            setChecked((prevChecked: any) => ({
                ...prevChecked,
                reserveSpreadEvenly: false,
                reserveLastPeriod: !prevChecked.reserveLastPeriod,
            }));

        } else {
            setChecked((prevChecked: any) => ({
                ...prevChecked,
                [checkboxName]: !prevChecked[checkboxName],
            }));
        }



        if (checkboxName === "reserveLastPeriod" || checkboxName === "reserveSpreadEvenly") {
            initReservesList((prevDetails: any) => ({
                ...prevDetails,
                reserveQuarter1: 0,
                reserveQuarter2: 0,
                reserveQuarter3: 0,
                reserveQuarter4: 0,
                reserveQuarter5: 0,
                reserveQuarter6: 0,
                reserveQuarter7: 0,
                reserveQuarter8: 0,
            }));
        }


    };

    //normal fields
    const handleInputChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        initReservesList((prevDetails: any) => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const saveReserves = () => {
        const updateReservesList = {
            ...reserveslist,
            reserveDigital: checked.reserveDigital ? 1 : 0,
            reserveNegativeProd: checked.reserveNegativeProd ? 1 : 0,
            reserveLicensingIncome: checked.reserveLicensingIncome ? 1 : 0,
            reserveSpreadEvenly: checked.reserveSpreadEvenly ? 1 : 0,
            reserveLastPeriod: checked.reserveLastPeriod ? 1 : 0,
            reservePositiveAmount: checked.reservePositiveAmount ? 1 : 0,
        }

        clientProfileServices.saveClient(updateReservesList)
            .then(res => {
                console.log(res);
            })
    }

    const handleFormSubmit = (e: any) => {
        e.preventDefault();
        saveReserves();
    }

    return (
        <div className="">

            <div className="box box-header well">
                <h2><i className="icon-edit pt pr"></i> Reserves</h2>
            </div>

            <div className="box-content pLarge">
                <form className="form-horizontal form-condensed" name="reservesInfo" onSubmit={handleFormSubmit}>
                    <div className="ws-12 ptl">
                        <div className="form-format ws-4">
                            <div className="label-entries">
                                <label className="switch">
                                    <input type="checkbox" checked={checked.reserveDigital} onChange={() => handleInputChange("reserveDigital")} />
                                    <span className="slider round"></span>
                                </label>
                                <div className="ws-10">Take Reserves on Digital Transactions </div>
                            </div>
                        </div>

                        <div className="form-format ws-4">
                            <div className="label-entries">

                                <label className="switch m0 mr">
                                    <input type="checkbox" checked={checked.reserveNegativeProd} onChange={() => handleInputChange("reserveNegativeProd")} />
                                    <span className="slider round"></span>
                                </label>
                                <div className="ws-10 forceSingleLine">Take Reserves on negative products</div>
                            </div>
                        </div>

                        <div className="form-format ws-4">
                            <div className="label-entries">

                                <label className="switch m0 mr">
                                    <input type="checkbox" checked={checked.reserveLicensingIncome} onChange={() => handleInputChange("reserveLicensingIncome")} />
                                    <span className="slider round"></span>
                                </label>
                                <div className="ws-10 forceSingleLine">Take Reserves on Licensing Income </div>
                            </div>
                        </div>

                    </div>
                    <div className="ws-12 ptl">
                        <div className="form-format ws-4">
                            <div className="label-entries">

                                <label className="switch m0 mr">
                                    <input type="checkbox" checked={checked.reserveSpreadEvenly} onChange={() => handleInputChange("reserveSpreadEvenly")} />
                                    <span className="slider round"></span>
                                </label>
                                <div className="ws-10 forceSingleLine">Spread Evenly </div>
                            </div>
                        </div>

                        <div className="form-format ws-4">
                            <div className="label-entries">

                                <label className="switch m0 mr">
                                    <input type="checkbox" checked={checked.reserveLastPeriod} onChange={() => handleInputChange("reserveLastPeriod")} />
                                    <span className="slider round"></span>
                                </label>
                                <div className="ws-10 forceSingleLine">Release in last period </div>
                            </div>
                        </div>

                        <div className="form-format ws-4">
                            <div className="label-entries">

                                <label className="switch">
                                    <input type="checkbox" checked={checked.reservePositiveAmount} onChange={() => handleInputChange("reservePositiveAmount")} />
                                    <span className="slider round"></span>
                                </label>
                                <div className="ws-10 forceSingleLine"> Pay out only on positive amount per release per territory</div>
                            </div>
                        </div>


                    </div>

                    <div className="form-format ws-12 ptl">
                        <div className="ws-3">
                            <label className="text-bold ws-3">Qtr 1</label>
                            <div className="label-entries m0 ">
                                <input type="text" value={reserveslist.reserveQuarter1} name="reserveQuarter1" className="checkbox-inline  text-right" onChange={handleInputChangeField}
                                    disabled={checked.reserveSpreadEvenly || checked.reserveLastPeriod} />
                            </div>
                        </div>
                        <div className="ws-3">
                            <label className="text-bold">Qtr 2</label>
                            <div className="label-entries m0 ">
                                <input type="text" value={reserveslist.reserveQuarter2} name="reserveQuarter2" className="checkbox-inline text-right" onChange={handleInputChangeField}
                                    disabled={checked.reserveSpreadEvenly || checked.reserveLastPeriod} />
                            </div>
                        </div>
                        <div className="ws-3">
                            <label className="text-bold">Qtr 3</label>
                            <div className="label-entries m0 ">
                                <input type="text" value={reserveslist.reserveQuarter3} name="reserveQuarter3" className="checkbox-inline text-right" onChange={handleInputChangeField}
                                    disabled={checked.reserveSpreadEvenly || checked.reserveLastPeriod} />
                            </div>
                        </div>
                        <div className="ws-3">
                            <label className="text-bold">Qtr 4</label>
                            <div className="label-entries m0 ">
                                <input type="text" value={reserveslist.reserveQuarter4} name="reserveQuarter4" className="checkbox-inline text-right" onChange={handleInputChangeField}
                                    disabled={checked.reserveSpreadEvenly || checked.reserveLastPeriod} />
                            </div>
                        </div>
                    </div>

                    <div className="form-format ws-12 ptl">
                        <div className="ws-3">
                            <label className="text-bold">Qtr 5</label>
                            <div className="label-entries m0 ">
                                <input type="text" value={reserveslist.reserveQuarter5} name="reserveQuarter5" className="checkbox-inline text-right" onChange={handleInputChangeField}
                                    disabled={checked.reserveSpreadEvenly || checked.reserveLastPeriod} />
                            </div>
                        </div>
                        <div className="ws-3">
                            <label className="text-bold">Qtr 6</label>
                            <div className="label-entries m0 ">
                                <input type="text" value={reserveslist.reserveQuarter6} name="reserveQuarter6" className="checkbox-inline text-right" onChange={handleInputChangeField}
                                    disabled={checked.reserveSpreadEvenly || checked.reserveLastPeriod} />
                            </div>
                        </div>
                        <div className="ws-3">
                            <label className="text-bold">Qtr 7</label>
                            <div className="label-entries m0 ">
                                <input type="text" value={reserveslist.reserveQuarter7} name="reserveQuarter7" className="checkbox-inline text-right" onChange={handleInputChangeField}
                                    disabled={checked.reserveSpreadEvenly || checked.reserveLastPeriod} />
                            </div>
                        </div>
                        <div className="ws-3">
                            <label className="text-bold">Qtr 8</label>
                            <div className="label-entries m0 ">
                                <input type="text" value={reserveslist.reserveQuarter8} name="reserveQuarter8" className="checkbox-inline text-right" onChange={handleInputChangeField}
                                    disabled={checked.reserveSpreadEvenly || checked.reserveLastPeriod} />
                            </div>
                        </div>
                    </div>

                    <div className="box-footer pull-right">
                        <button className="btn btn-success btn-sm" type="submit"><i className="icon-save"></i> Save </button>
                    </div>
                </form>
            </div>

        </div>

    )
}
import React, { Suspense, useState, useEffect } from "react";
import siteLogo from "../../assets/images/logoTransparent.png";
import { CommonUtilities } from "../../commonUtils";
import IndexDashboard from "../Dashboard/indexDashboard";


export default function IndexAffiliate(Param : any){

        const [showtab , setShowTab] = useState(1);
        const currentPage = (e: React.SetStateAction<number>) => {
           setShowTab(e);
           return 1;
        }

       let [param , setParm] = useState(0)

        const [post, setPost] = useState({
            
            avatar: "",
            avatarTh: ""
        });

   
        useEffect(() => {
           // setPost (JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string))
            console.log(post);
            
           // const interval = setInterval(() => {
           //     setPost(JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string));
           //   }, 1000);
         }, []);
    
        return (

      <div className='ws-12'>
       <IndexDashboard></IndexDashboard>
      </div>


        )
     
    };

import React, { useState, useEffect } from 'react';
import { CommonUtilities } from '../../commonUtils'; 
import DataLoaderFileLog from './DataLoaderFileLog';
import DataLoaderLoadFile from './DataLoaderLoadfile';
import dataLoaderServices from './DataLoaderServices';
import DataLoaderTableConfirmation from './DataLoadertableClear';
import DataLoaderFileValidation from './DataLoaderFileValidation';

export default function UploadTemplate(param: any) {

    let [progress, setProgress] = useState(0);
    const [searchtotalRows , setsearchtotalRows] = useState(0);    
    const [viewAlltotalRows , setviewAlltotalRows] = useState(0);
    const [openLoadFile, setopenLoadFile] = useState(0);
    const [fileToUpload, setfileToUpload] = useState(null);
    const [LoadFileStatus, setLoadFileStatus] = useState(false);
    const [collapsedSearch, setcollapsedSearch] = useState(false);
    const [showSearchResult, setshowSearchResult] = useState(false);
    const [fileuploadStatus, setfileuploadStatus] = useState(false);
    const [searchListing, setsearchListing] = useState<any>([]);
    const [viewAllListing, setviewAllListing] = useState<any>([]);
    const [showViewAllResult ,setshowViewAllResult] = useState(false);
    const [showShearchField ,setshowShearchField] = useState(false);
    let [LogName ,setLogName] = useState("");
    let   [editFormParam, seteditFormParam] = useState<any>([]);

    useEffect(() => {
        uploadDataCounting();
        setcollapsedSearch(false);
        setshowSearchResult(false);
        setshowViewAllResult(false);

        if(param.subType.length >0 && dataLoaderServices.dataLoaderSearch.some((filter:any)=> filter.fieldFor == param.type && filter.for == param.subType) ){
            setshowShearchField(true)
        }else if(param.subType.length ==0 && dataLoaderServices.dataLoaderSearch.some((filter:any)=> filter.fieldFor == param.type) ){
            setshowShearchField(true)
        }else{
            setshowShearchField(false)
        }

        var list :any =  dataLoaderServices.logName.filter((filter:any)=> { return LogName = filter[param.type] })[0]
        setLogName(list[param.type] );
        seteditFormParam([])

    }, [param.type,param.subType]);

    const fileupload = (event: any) => { 
        const fileName = event.target.files[0];
        if (fileName) {
            setfileToUpload(fileName);
            setfileuploadStatus(true);          
        }
    } 

    const onChangeLoadFile = (e: any) => {
        if (e == 1) {
            //Appen
            uploadData();
        }
        if (e == 2) {
            //clear
            clearUpload();
            uploadData();
        }
        if (e == 3) {
            //load
            uploadData();
        }

        setLoadFileStatus(false)
        ViewUploadList();
    }
    
    const progressTiming = (e:any) =>{   

        setProgress(30)

        setProgress(50)

        if(e >= 0){
            setProgress(100)
        }  
    }

    const uploadData = () => {
         
        if(param.type == 'Contacts' ){ dataLoaderServices.dataLoadContactFileUpload(fileToUpload).then((value : any)=>{   progressTiming(value); uploadDataCounting();   }) }
        if(param.type == 'Contracts' ){ dataLoaderServices.dataLoadContractFileUpload(fileToUpload).then((value : any)=>{   progressTiming(value); uploadDataCounting(); }) }
        if(param.type == 'Contract Links' ){ dataLoaderServices.dataLoadcontractLinksFileUpload(fileToUpload).then((value : any)=>{   progressTiming(value); uploadDataCounting(); }) }
        if(param.type == 'Upload Mechanicals' ){dataLoaderServices.dataLoadMechanicalFileUpload(fileToUpload).then((value : any)=>{   progressTiming(value); uploadDataCounting(); })}
        if(param.type == 'Mappings' ){  dataLoaderServices.dataLoadMappingFileUpload(fileToUpload).then((value : any)=>{   progressTiming(value); uploadDataCounting(); }) }
        if(param.type == 'Data Transfer' ){ dataLoaderServices.dataTransferFileUpload(fileToUpload).then((value : any)=>{   progressTiming(value); uploadDataCounting(); }) }
        if(param.type == 'Bulk Update' ){ 
            //dataLoaderServices.bulkUpdateSummary({type:param.subType}).then((value : any)=>{ uploadDataCounting() }) 
        }
    }

    const uploadDataCounting = () => {
        if(param.type == 'Contacts' ){ dataLoaderServices.dataLoadContactCount().then((value : any)=>{ setviewAlltotalRows(value)}) }
        if(param.type == 'Contracts' ){ dataLoaderServices.dataLoadContractCount().then((value : any)=>{ setviewAlltotalRows(value)}) }
        if(param.type == 'Contract Links' ){ dataLoaderServices.dataLoadcontractLinksCount().then((value : any)=>{ setviewAlltotalRows(value)}) }
        if(param.type == 'Upload Mechanicals' ){ 
            if(param.subType){
                dataLoaderServices.dataLoadMechanicalUploadCount({type:param.subType}).then((value : any)=>{ setviewAlltotalRows(value)})
            }             
        }
        if(param.type == 'Mappings' ){ 
            if(param.subType){
                dataLoaderServices.dataLoadMappingCount({type:param.subType}).then((value : any)=>{ setviewAlltotalRows(value)})
            }
        }
        if(param.type == 'Data Transfer' ){ dataLoaderServices.dataTransferCount().then((value : any)=>{ setviewAlltotalRows(value)}) }
        if(param.type == 'Bulk Update' ){ 
            if(param.subType){
                dataLoaderServices.bulkUpdateSummary({type:param.subType}).then((value : any)=>{ setviewAlltotalRows(value)})
            }
        }
        
    }

    const clearUpload = () => {

        if(param.type == 'Contacts' ){ 
            dataLoaderServices.dataLoadContactClear().then((res: any) => { ViewUploadList() });
        }

        if(param.type == 'Contracts' ){ 
            dataLoaderServices.dataLoadContractClear().then((res: any) => { ViewUploadList() });
        }

        if(param.type == 'Contract Links' ){ 
            dataLoaderServices.dataLoadContractLinksClear().then((res: any) => { ViewUploadList() });
        }
        
        if(param.type == 'Upload Mechanicals' ){ 
            dataLoaderServices.dataLoadMechanicalClear({type : param.subType}).then((res: any) => { ViewUploadList() }) ;         
        }

        if(param.type == 'Mappings' ){ 
            dataLoaderServices.dataLoadMappingClear({type : param.subType}).then((res: any) => { ViewUploadList() }) ;         
        }
        if(param.type == 'Data Transfer' ){ 
            dataLoaderServices.dataLoadTransferClear({type : param.subType}).then((res: any) => { ViewUploadList() }) ;         
        }
    }

    const onFileValidation = (e: any) => {
        setLoadFileStatus(false);
        ViewUploadList();
    }

    const onClearConfirmation = (e: any) => {
        if (e == 1) { 
            clearUpload();
        }
        setLoadFileStatus(false)
    }

    const onChangeLogFile = (e: any) => {
        setLoadFileStatus(false)
    }

    var searchListingPayload = {
        type: param.subType,
        pageNumber: 1,
        pageSize: 10
    }

    const searchForListing = () =>{

        if(editFormParam){
            searchListingPayload = {...searchListingPayload ,...editFormParam}
        }
        if(param.type == 'Contacts' ){ 
            dataLoaderServices.dataLoadContactSearch(searchListingPayload).then((value : any)=>{ 
                setsearchListing(value); setshowSearchResult(true);
                setsearchtotalRows(value && value[0] && value[0].totalRows ? value[0].totalRows : 0)
            }) 
        }

        if(param.type == 'Contracts' ){ 
            dataLoaderServices.dataLoadContractSearch(searchListingPayload).then((value : any)=>{ 
                setsearchListing(value); setshowSearchResult(true);
                setsearchtotalRows(value && value[0] && value[0].totalRows ? value[0].totalRows : 0)
            }) 
        }

        if(param.type == 'Contract Links' ){ 
            dataLoaderServices.dataLoadContractLinksSearch(searchListingPayload).then((value : any)=>{ 
                setsearchListing(value); setshowSearchResult(true);
                setsearchtotalRows(value && value[0] && value[0].totalRows ? value[0].totalRows : 0)
            }) 
        }
        
        if(param.type == 'Upload Mechanicals' ){ 

            if(param.subType == "mechanicalContract"){
                dataLoaderServices.dataLoadmechanicalContractSearch(searchListingPayload).then((value : any)=>{ 
                    setsearchListing(value); setshowSearchResult(true);
                    setsearchtotalRows(value && value[0] && value[0].totalRows ? value[0].totalRows : 0)
                }) 
            }

            if(param.subType == "mechanicalTrackSplits"){
                dataLoaderServices.dataLoadMechanicalTrackSplitsSearch(searchListingPayload).then((value : any)=>{ 
                    setsearchListing(value); setshowSearchResult(true);
                    setsearchtotalRows(value && value[0] && value[0].totalRows ? value[0].totalRows : 0)
                }) 
            }

            if(param.subType == "mechanicalLicenses"){
                dataLoaderServices.dataLoadMechanicalLicenseSearch(searchListingPayload).then((value : any)=>{ 
                    setsearchListing(value); setshowSearchResult(true);
                    setsearchtotalRows(value && value[0] && value[0].totalRows ? value[0].totalRows : 0)
                }) 
            }

        }
    }

    const sliderUpdateEditFormParam = (type : any ,value:any , InputType : any ,InputValue :any)=>{
        if( (value[type] == 0 || value[type] == 1) && InputType == "checkbox"){
            value[type] = value[type] == 0? 1 : 0;

            seteditFormParam({...editFormParam , value })
        }

        else if( (value[type] == true || value[type] == false) && InputType == "checkbox"){
            value[type] = value[type] == false? true : false;

            seteditFormParam({...editFormParam , value })
        } else {
            value[type] = InputValue;
            seteditFormParam({...editFormParam , value })
        }

        if(editFormParam && editFormParam.value){
            delete editFormParam.value
        }  
    }

    const ViewUploadList = ()=>{
        if(param.type == 'Contacts' ){ 
            dataLoaderServices.dataLoadContactSearch(searchListingPayload).then((value : any)=>{ 
                setviewAllListing(value); setshowViewAllResult(true);
                setviewAlltotalRows(value && value[0] && value[0].totalRows ? value[0].totalRows : 0)
            }) 
        }

        if(param.type == 'Contracts' ){ 
            dataLoaderServices.dataLoadContractSearch(searchListingPayload).then((value : any)=>{ 
                setviewAllListing(value); setshowViewAllResult(true);
                setviewAlltotalRows(value && value[0] && value[0].totalRows ? value[0].totalRows : 0)
            }) 
        }
        if(param.type == 'Contract Links' ){ 
            dataLoaderServices.dataLoadcontractLinksCount().then((value : any)=>{ 
                setviewAlltotalRows(value ? value : 0)
            }) 
            dataLoaderServices.dataLoadContractLinksViewAll(searchListingPayload).then((value : any)=>{ 
                setviewAllListing(value); setshowViewAllResult(true);
            }) 
        }

        if(param.type == 'Upload Mechanicals' ){ 
            dataLoaderServices.dataLoadMechanicalUploadCount({type: param.subType}).then((value : any)=>{ 
                setviewAlltotalRows(value ? value : 0)
            }) 

            if(param.subType == "mechanicalContract"){
                dataLoaderServices.dataLoadMechanicalContractViewAll(searchListingPayload).then((value : any)=>{ 
                    setviewAllListing(value); setshowViewAllResult(true);
                }) 
            }

            if(param.subType == "mechanicalTrackSplits"){
                dataLoaderServices.dataLoadMechanicalTrackSplitsViewAll(searchListingPayload).then((value : any)=>{ 
                    setviewAllListing(value); setshowViewAllResult(true);
                }) 
            }//mechanicalLicenses

            if(param.subType == "mechanicalLicenses"){
                dataLoaderServices.dataLoadMechanicalLicenseViewAll(searchListingPayload).then((value : any)=>{ 
                    setviewAllListing(value); setshowViewAllResult(true);
                }) 
            }

        }

        if(param.type == 'Mappings' ){ 
            dataLoaderServices.dataLoadMappingCount({type: param.subType}).then((value : any)=>{ 
                setviewAlltotalRows(value ? value : 0)
            }) 

            if(param.subType == "mappingRaw"  || param.subType == "mappingAudit" ){
                dataLoaderServices.dataLoadMappingRawViewAll(searchListingPayload).then((value : any)=>{ 
                    setviewAllListing(value); setshowViewAllResult(true);
                }) 
            }
            
            if(param.subType == "mappingRawPub" || param.subType == "mappingAuditPub"  ){
                dataLoaderServices.dataLoadMappingRawPubViewAll(searchListingPayload).then((value : any)=>{ 
                    setviewAllListing(value); setshowViewAllResult(true);
                }) 
            }

            if(param.subType == "mappingFormat"  ){
                dataLoaderServices.dataLoadmappingformatViewAll(searchListingPayload).then((value : any)=>{ 
                    setviewAllListing(value); setshowViewAllResult(true);
                }) 
            }

            if(param.subType == "mappingCatalogue"  ){
                dataLoaderServices.dataLoadmappingCatalogueViewAll(searchListingPayload).then((value : any)=>{ 
                    setviewAllListing(value); setshowViewAllResult(true);
                }) 
            }

            if(param.subType == "mappingAnalysisCode"  ){
                dataLoaderServices.dataLoadmappinganalysiscodeViewAll(searchListingPayload).then((value : any)=>{ 
                    setviewAllListing(value); setshowViewAllResult(true);
                }) 
            }

            if(param.subType == "mappingClassList"  ){
                dataLoaderServices.dataLoadmappingclasslistViewAll(searchListingPayload).then((value : any)=>{ 
                    setviewAllListing(value); setshowViewAllResult(true);
                }) 
            }
            
        }       
        
    }

    const exportListing =() =>{

        var ExportPayload = {
            export: true , exportType: param.subType
        }
        
        if(editFormParam){
            ExportPayload = {...ExportPayload ,...editFormParam}
        }

        if(param.type == 'Contacts' ){  dataLoaderServices.dataLoadContactExport(ExportPayload) }

        if(param.type == 'Contracts' ){  dataLoaderServices.dataLoadContractExport(ExportPayload) }

        if(param.type == 'Contract Links' ){  dataLoaderServices.dataLoadContractLinksExport(ExportPayload) }
        
        if(param.type == 'Upload Mechanicals' ){  dataLoaderServices.dataLoadMechanicalUploadExport(ExportPayload)       }
    }

    return (
        <>  
        <div>

            {showShearchField && <div className="box mtl">                
                
                <div className="box-header well">
                    <h2 className="ws-6"><i className="icon-music1"></i> Search</h2>

                    <div className="ws-6">
                        <button className="btn-grey pull-right" onClick={()=>setcollapsedSearch(!collapsedSearch)}>
                            <i className = {collapsedSearch ? 'icon-angle-up' : 'icon-angle-down' } ></i>
                        </button>
                    </div>

                </div>

                {collapsedSearch && <>
                    <div className="box-content">
                        
                        <div className="row-fluid ws-12">
                        {
                            dataLoaderServices.dataLoaderSearch?.map((valueMapping:any)=>{
                                {
                                    if(valueMapping.fieldFor == param.type && !valueMapping.for){
                                        return (
                                        valueMapping.field_display?.map((fieldName:any, index:any)=>{
                                            return (
                                                <div className={valueMapping.className}>
                                                <div className="form-format">
                                                    <label className="control-label">{fieldName}</label>
                                                    <div className="label-entries ws-10">
                                                        <input id={valueMapping.field_code[index]}  name={valueMapping.field_code[index]} type="text" placeholder={fieldName}
                                                            value={editFormParam && editFormParam[valueMapping.field_code[index]] ? editFormParam[valueMapping.field_code[index]] : ""}
                                                            onChange={(e)=>sliderUpdateEditFormParam(valueMapping.field_code[index] ,editFormParam ,"text",e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        })    
                                        )
                                    }

                                    if(valueMapping.fieldFor == param.type && valueMapping.for && valueMapping.for == param.subType){
                                        return (
                                        valueMapping.field_display?.map((fieldName:any, index:any)=>{
                                            return (
                                                <div className={valueMapping.className}>
                                                <div className="form-format">
                                                    <label className="control-label">{fieldName}</label>
                                                    <div className="label-entries ws-10">
                                                        <input id={valueMapping.field_code[index]}  name={valueMapping.field_code[index]} type="text" placeholder={fieldName}
                                                                value={editFormParam && editFormParam[valueMapping.field_code[index]] ? editFormParam[valueMapping.field_code[index]] : ""}
                                                                onChange={(e)=>sliderUpdateEditFormParam(valueMapping.field_code[index] ,editFormParam ,"text",e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        })    
                                        )
                                    }
                                }
                            })
                        }

                        {
                            ( ()=>{

                                if(param.type == "Contract Link" ){
                                    return (
                                    <div className="span2-4 m0 prl pl pull-left">
                                        Unlinked Only
                                        <label className="switch mr">
                                            <input type="checkbox" ng-model="searchContractLinksUnlinked.unlinked"/>
                                            <span className="slider round text-left white pm pts">Yes</span>
                                            <span className="slider round text-right white pm pts sliderOff">No</span> 
                                        </label>

                                    </div>
                                    )
                                }

                            })()
                        }
                        </div>
                        
                    </div>

                    <div className="box-footer pull-right">
                        <button className="btn-success btn" onClick={searchForListing}> <i className="icon-search"></i> Search </button>
                        <button className="btn-pink btn" onClick={()=>seteditFormParam([])}> <i className="icon-refresh"></i> Reset  </button>
                        <button className="btn-primary btn" onClick={exportListing}> <i className="icon-download1"></i>Download </button>
                    </div>
                </>}

                {showSearchResult && <div className="box ptl">
                    <div className="row-fluid">
                        <div className="ws-12">
                            <div className="box-header well">  <h2 ><i className="icon-info"></i> {searchtotalRows} record(s) found</h2> </div>

                            <div className="box-content">
                                {(searchtotalRows == 0) && <div className="alert alert-info">No data to display for this search </div>}

                                <div className="table-responsive allowHscroll">
                                    {(searchtotalRows > 0) && <table className="table">
                                        <thead>
                                        <tr>
                                             {
                                                dataLoaderServices.SearchfieldName.map((value:any , index :any)=>{

                                                    if(value.fieldFor == param.type && !value.for)
                                                    {
                                                        return (
                                                            dataLoaderServices.SearchfieldName[index].fieldName?.map((valueMap)=>{
                                                                return (<> <th className='forceSingleLine'>{valueMap}</th>  </>)
                                                            })
                                                        )                                                        
                                                    }

                                                    if(value.fieldFor == param.type && value.for && value.for==param.subType )
                                                    {
                                                        return (
                                                            dataLoaderServices.SearchfieldName[index].fieldName?.map((valueMap)=>{
                                                                return (<> <th className='forceSingleLine'>{valueMap}</th>  </>)
                                                            })
                                                        )                                                        
                                                    }
                                                })
                                             }
                                        </tr>
                                        </thead>

                                        <tbody>           

                                        {
                                            searchListing?.map((searchValue : any) =>{
                                                return (
                                                    <tr>
                                                        {
                                                            dataLoaderServices.SearchfieldName.map((value:any , index :any)=>{

                                                            if(value.fieldFor == param.type && !value.for)
                                                            {
                                                                return (
                                                                    dataLoaderServices.SearchfieldName[index].fieldCode?.map((valueMap)=>{
                                                                        return (<> <td className='forceSingleLine'>{searchValue[valueMap]}</td>  </>)
                                                                    })
                                                                )
                                                                
                                                            }
                                                        
                                                            if(value.fieldFor == param.type && value.for && value.for==param.subType )
                                                            {
                                                                return (
                                                                    dataLoaderServices.SearchfieldName[index].fieldCode?.map((valueMap)=>{
                                                                        return (<> <td className='forceSingleLine'>{searchValue[valueMap]}</td>  </>)
                                                                    })
                                                                )
                                                                
                                                            }
                                                        
                                                            })
                                                            
                                                        }
                                                    </tr>
                                                )
                                            })

                                        }
                                        </tbody>
                                    </table>}
                                </div>

                                <div className="clearfix"> 
                                </div>

                            </div>
                        </div>
                    </div>
                </div>}
            </div>}

            <div className="box mtl">
                <div className="box-header well">
                    <h2 className="ws-6 "><i className="icon-upload"></i> File Details</h2>
                    <div className="ws-6">
                        <button className="btn-primary pull-right" onClick={() => { setLoadFileStatus(true); setopenLoadFile(4) }}><i className="icon-history"></i> </button>
                        <button className="btn-pink pull-right" onClick={() => { uploadDataCounting() }}><i className="icon-refresh2"></i> Refresh</button>
                    </div>
                </div>

                <div className="box-content">
                    <div className="row-fluid form-horizontal form-condensed m0">
                        <div className="ws-3">
                            <div className="label-entries">
                                <label className="btn btn-success btn-file btn-sm">
                                    <i className="icon-upload"></i>Upload File
                                    <input type="file" accept='pdf/*' key="file" onChange={(e) => fileupload(e)} className="hide" />
                                </label>
                                {(fileuploadStatus) && <a className="btn btn-success btn-sm" onClick={() => { setLoadFileStatus(true); setopenLoadFile(1) }}><i className="icon-spinner10"></i> Load</a>}

                            </div>
                        </div>

                        <div className="ws-6">
                            <div className="progress">
                                <div className="btn-lightBlue" style={{ width: `${progress}%` }}>  {progress} % </div>
                            </div>
                        </div>

                    </div>
                </div>

                {(viewAlltotalRows > 0) && <div className="box-footer pull-right">
                    <button className="btn-warning pull-left btn-sm"  onClick={() => { setLoadFileStatus(true); setopenLoadFile(2) }}><i className="icon-trashcan"></i></button>
                    <button className="btn-success" onClick={ViewUploadList}><i className="icon-search"></i> View All</button>
                    <button className="btn-danger " onClick={() => { setLoadFileStatus(true); setopenLoadFile(3) }}> <i className="icon-tick"></i> Validate  </button>
                </div>}
            </div>


            {(showViewAllResult) && <div className="box" >
                <div className="box-header well"> <h2>{viewAlltotalRows} record(s) found</h2> </div>

                <div className="box-content">

                    {(viewAlltotalRows > 0) &&<div className="table-responsive allowVscroll allowHscroll">
                         <table className="table">
                            <thead>
                                <tr>
                                {
                                    dataLoaderServices.viewAllfieldName?.map((viewMap:any)=>{
                                        if(viewMap.fieldFor == param.type && !viewMap.for){
                                            return(
                                                viewMap.fieldName?.map((naming:any)=>{
                                                    return (
                                                        <th className='forceSingleLine'>{naming}</th>
                                                    )
                                                })
                                            )    
                                        }

                                        if(viewMap.fieldFor == param.type && viewMap.for && viewMap.for == param.subType){
                                            return(
                                                viewMap.fieldName?.map((naming:any)=>{
                                                    return (
                                                        <th className='forceSingleLine'>{naming}</th>
                                                    )
                                                })
                                            )    
                                        }


                                    })
                                } 
                                </tr>
                            </thead>                                
                            <tbody>

                            {
                                viewAllListing?.map((searchValue : any) =>{
                                    return (
                                    <tr>
                                    {
                                        dataLoaderServices.viewAllfieldName.map((value:any , index :any)=>{

                                           
                                        if(value.fieldFor == param.type && !value.for)
                                        {
                                            return (                                               
                                                
                                                value.fieldCode?.map((valueMap:any)=>{
                                                    return (<> <td className='forceSingleLine'>{searchValue[valueMap]}</td>  </>)
                                                })
                                            )                                            
                                        }

                                        if(value.fieldFor == param.type && value.for && value.for == param.subType)
                                        {
                                            return (

                                                value.fieldCode?.map((valueMap:any)=>{
                                                    return (<> <td className='forceSingleLine'>{searchValue[valueMap]}</td>  </>)
                                                })
                                            )                                            
                                        }                                    
                                    
                                        })                                        
                                    }
                                    </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>

                    </div>}
                    <div className="clearfix"> 

                    </div>
                    
                </div>
            </div>}

            {
                (() => {

                    if (openLoadFile == 1) {
                        return (<DataLoaderLoadFile onChangeLoadFile={onChangeLoadFile} open={LoadFileStatus} type={param.type} subType={param.subType}/>)
                    }

                    if (openLoadFile == 2) {
                        return (<DataLoaderTableConfirmation onClearConfirmation={onClearConfirmation} open={LoadFileStatus}/>)
                    }

                    if (openLoadFile == 3) {
                        return (<DataLoaderFileValidation onFileValidation={onFileValidation} open={LoadFileStatus} type={param.type} subType={param.subType}/>)
                    }

                    if (openLoadFile == 4) {
                        return (<DataLoaderFileLog onChangeLogFile={onChangeLogFile} open={LoadFileStatus} type={LogName} />)
                    }

                })()
            }
        </div>
        </>
    )
}
import { CommonUtilities } from "commonUtils";
import dashboardService from "components/Dashboard/dashboardServices";
import Creator from "components/directories/creator";
import React, { Suspense, useState, useEffect } from "react";
import { Modal } from 'react-responsive-modal';


export default function LinkedTracksScraping({ selectedItems }: any) {
    const [searchTerm, setSearchTerm] = useState("");

    return (
        <div className="pull-left full-width">
            <div className="box">
                <div className="box-header">
                    Linked Tracks
                </div>
                <div className="box-content mtl ptl">
                    {selectedItems.length > 0 ? (
                        <div>

                            <div className="ws-12 pm">
                                <input type="text" className=" ws-4 slim pull-right" placeholder="Search Tracks" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                
                            </div>
                            {selectedItems
                                .filter((item: any) =>
                                    (
                                        item.trackName &&
                                        item.trackName.toLowerCase().includes(searchTerm.toLowerCase())
                                    ) ||
                                    (
                                        item.name &&
                                        item.name.toLowerCase().includes(searchTerm.toLowerCase())
                                    ) ||
                                    (
                                        item.title &&
                                        item.title.toLowerCase().includes(searchTerm.toLowerCase())
                                    )
                                )
                                .map((item: any, index: any) => (
                                    <div className="creatorImg pm pull-left" key={index}>

                                        <div className="box shadowCoverPicture shadow miniCreatorImg">
                                            <div className="coverPicture">
                                                {item.artworkUrl100 && (
                                                    <img src={item.artworkUrl100} alt={item.trackName} />
                                                )}
                                                {item.album && (
                                                    <img src={item.album.images[0].url} alt={item.name} />
                                                )}
                                                {item.thumbnail && (
                                                    <img src={item.thumbnail} alt={item.title} />
                                                )}<div className="bottomOverlayLabel">
                                                    <label className="control-label ws-12">{CommonUtilities.longCharacter(item.trackName || item.name || item.title, 18)}


                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                        </div>
                    ) : (
                        <div className="ptl">
                            <span className="alert alert-success ws-12 text-center">No Linked tracks</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
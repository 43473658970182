import React, { Suspense, useState, useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import { Details_Template } from "./ClientProfilePortal/details_clients_tpl";
import { Reserves_Template } from "./ClientProfilePortal/reserves_client_profile_tpl";
import DSP_Management from "./ClientProfilePortal/client_manageDSP";
import Zone_Management from "./ClientProfilePortal/client_manageZone";
import FTP_Management from "./ClientProfilePortal/client_manageFTP";
import Email_Template from "./ClientProfilePortal/emailSettings";
import StatementApproval_Template from "./ClientProfilePortal/statementApproval";
import LicensingClientProfile from "./ClientProfilePortal/licensing";
import Logo_Template from "./ClientProfilePortal/logo_client_profile_tpl";

export default function ClientProfile() {

    const tabs = [
        {
            id: 1,
            keys: "details",
            title: "Details"
        },

        {
            id: 2,
            keys: "reserves",
            title: "Reserves"
        },

        {
            id: 3,
            keys: "logo",
            title: "Upload Logo"
        },

        {
            id: 4,
            keys: "zone",
            title: "Zone Management"
        },

        {
            id: 5,
            keys: "dsp",
            title: "DSP Management"
        },

        {
            id: 6,
            keys: "ftp",
            title: "FTP Management"
        },

        {
            id: 7,
            keys: "emailSetting",
            title: "Email Template"
        },

        {
            id: 8,
            keys: "statementSetting",
            title: "Statement Approval"
        },
        {
            id: 9,
            keys: "licensingSetting",
            title: "Licensing"
        }        

    ]

    const [activeTab, setActiveTab] = useState('');
    const [showtab , setShowTab] = useState(1);
    const [openFirst, setOpenFirst] = React.useState(false);

    const handleCloseModal = () => {
        setOpenFirst(false);
        setShowTab(1);
    }

    const currentPage = (e: React.SetStateAction<number>) => {
       setShowTab(e);
       return 1;
    }    

    return (
        <div className="pull-left">
            <div className="box-primary ms text-center iconLogo pull-left mrl">
                <i className="box icon-user2" onClick={() => setOpenFirst(true)}></i>
                <span className="m0 pts">Client Profile</span>                       
            </div>
               
            <Modal open={openFirst} onClose={handleCloseModal} center>
                <div className="box">

                    <div className="ws-12 pm box pLarge">

                        <ul className="nav nav-tabs m0 p0">
                            {tabs.map((o) => {
                                var temp = '#/clientProfile/';
                                return <li className ={showtab === o.id? "btn active": "btn"} onClick= {() => currentPage(o.id)} > {o.title}</li>
                                    
                            })}                                    
                        </ul>
                            
                    </div>

                    <div id="mainView"> 
                        <div ></div>
                    </div>

                   <>
                    {(() => {
                        switch (showtab) {
                            case 1 : return  <Details_Template/>
                            case 2 : return  <Reserves_Template/>
                            case 3 : return  <Logo_Template/>
                            case 4 : return  <Zone_Management param={"open"}/>
                            case 5 : return  <DSP_Management param={"open"}/>
                            case 6 : return  <FTP_Management param={"open"}/>
                            case 7 : return  <Email_Template param={"open"}/>
                            case 8 : return  <StatementApproval_Template param={"open"}/>
                            case 9 : return  <LicensingClientProfile/>                                                          
                        }
                    })()}
                    </>


                </div>
            </Modal>
                
        </div>
    );    
  
}
 
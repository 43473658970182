import React, { useState } from "react";
import Drawer from 'react-modern-drawer';
import contactService from "./contactService";
import Pagination from "../Header/Pagination";
import DeleteDialogueBox from "../Common/deleteDialogue";

export function ContactFormSearch (param :any) {

    const toggleDrawer = () => {setIsOpen(!isOpen)}
    const [isOpen, setIsOpen] = React.useState(false);      

    const [saveAlert , setsaveAlert] = useState(false);
    const [addContact , setaddContact] = useState(false);
    const [emptyField , setemptyField] = useState(false);
    let [ContactList, initContactList] = useState<any[]>([]);
    let [ContactListSearch, initContactSearchList] = useState<any[]>([]);
    const [totalRows ,setTotalRows] = useState(0);
    const [deleteJob , setDelete] = useState(0);
    const [deletekontactName , setdeletekontactName] = useState<any>([]);
    const [payload , setpayload] = useState<any>([]);
 
    const searchContact = () => {
        if(payload.contactNameOrCode != ''){
            setemptyField(false)
            if(!payload.digitalOnly){
                payload.digitalOnly = null;
            }
            contactService.SelectContact({...payload , 'pageSize' : 10 , 'pageNumber' : 10}).then((res) => {
                initContactList(res)
                initContactSearchList(res)
                setTotalRows( res[0] ? res.length: 0);

                if(res.length > 0){   
                    ContactListSearch = res;        
                    var obj = res.splice(0,10) 
                    initContactList(obj)   
                }
            })
        }else {
            setemptyField(true)
        }
    }

    const Confirmation = (e :any) =>{
        
        if(e == 1 ){
            contactService.deleteContact(deletekontactName);
            contactService.SelectContact(payload).then((res :any) => {
                initContactList(res)
                setTotalRows( res[0] ? res[0].totalRows : 0)
            })
        }
        setDelete(0);
    }

    const handleNameOnChange = (n: any) => { 
        ContactList = ContactListSearch;
        var num = n.pageNumber;
        var size  = n.pageSize;
        var obj = ContactList.splice((num),size)  
        initContactList(obj)  
    }

    const fieldHandler = (n: any) => { 

        
        if( n.target.name == "searchBox"){
            payload.contactNameOrCode = n.target.value
        }else if(n.target.name == "digitalprovideropt"){
            payload.digitalOnly = n.target.value
        }
        if(!payload.digitalOnly){
            payload.digitalOnly = null;
        }
        setpayload(payload)
    }

    const selectContact = (e:any) =>{
        param.onChange(e)
    }

    return (

        <>
        <button type="button" className="btn-success" onClick={() => {setIsOpen(true)} }><i className="icon-user-add"></i></button>
            <Drawer 
            open={isOpen}
            onClose={()=>setIsOpen(false)}
            direction='right'
            className='react-drawer allowHscroll'
            size={800}>

                <div className="row-fluid sortable ws-12 mbl">

                    <div className="modal-header">
                        <div className="box-header well">
                            <h2 className="ws-10">Search Contact Form</h2>
                            <i className="icon-close pull-right ptl" onClick={()=>setIsOpen(false)}></i>  
                        </div>
                    </div>

                    <div className="box-content">
                        { emptyField && <div className="alert alert-info">Try entering a Contact Code to search</div>}

                        <div className="form-horizontal form-condensed">

                            <div className="form-format ws-4">
                                <label className="control-label p0" >Contact code/display/name</label>
                                <div className="label-entries"> <input type="text" className="form-control forceSingleLine" onChange={fieldHandler} placeholder="Enter Contact Code/Display/Name"  name="searchBox"/> </div>
                            </div>
                            <div className="form-format ws-1">
                                <label>Creator</label>
                                <label className="switch">
                                    <input type="checkbox" ng-true-value="true" ng-false-value="false" ng-model="creatorFlag" name="creatorFlag" />
                                    <span className="slider round text-left white pm pts " ng-show="creatorFlag== true">Yes</span>
                                    <span className="slider round text-right white pm pts sliderOff" ng-show="!creatorFlag== true">No</span>                    
                                </label>
                            </div>
                            <div className="form-format ws-7">
                                <div className="label-entries">
                                    <div className="ws-2"><input type="radio" value="null" checked={true}  onChange={fieldHandler} name="digitalprovideropt" /> All </div>
                                    <div className="ws-5 forceSingleLine" > <input type="radio" value="true"   name="digitalprovideropt" />Digital Provider Only</div>
                                    <div className="ws-5 forceSingleLine"> <input type="radio" value="false" name="digitalprovideropt" /> Non digital Provider</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-footer">

                        <a className="btn btn-small btn-success mr" onClick={searchContact}><i className="icon-search"></i>Search </a>
                        <a className="btn btn-small btn-success hide"><i className="icon-tick"></i> New Contact</a>
                    </div>
                </div>
                
                <div className="ws-12">
                    <div className="box-header well">
                        <h2><i className="icon-info pt"></i> <span>{totalRows} record(s) found</span></h2>
                    </div>
                    {addContact && <div className="alert alert-success"> Contact  has been deleted successfully </div> }
                    <div className="box-content">
                        { (totalRows == 0) && <div className="alert alert-info">No records to display </div> }
                        { (totalRows > 0) && <div className="table-responsive">
                            <table className="table box">
                                <thead>
                                <tr>
                                    <th>Contact code</th>
                                    <th>Contact display</th>
                                    <th>Contact name</th>
                                    <th>Operation</th>
                                </tr>
                                </thead>
                                    {
                                        ContactList.map( (value) => {
                                            return (
                                                <tr>
                                                    <td>{value.kontactCode} </td>
                                                    <td>{value.kontactDisplay} </td>
                                                    <td>{value.kontactName} </td>            
                                                    <td>
                                                        <a className="btn btn-success btn-sm" onClick={() => {selectContact(value) ;setIsOpen(false)} }><i className="icon-user-add"></i></a>
                                                        <a className="btn btn-success btn-sm hide" ><i className="icon-edit"></i></a>
                                                        <a className={value.enableDeleteFlag? "btn btn-danger btn-sm " : "hide"} onClick={() =>{setDelete(1);setdeletekontactName(value)}}><i className="icon-close"></i> </a>
                                                    </td>
                                                </tr>
                                            )                                    
                                        })                                                                        

                                    }
                                <tbody>
                                </tbody>
                            </table>
                        </div>}

                        <div className="clearfix">
                            {     
                                (() => {
                                    if(totalRows>0 && totalRows>10){
                                        return <Pagination onChange={handleNameOnChange} totalRows={totalRows} />
                                    }
                                                                    
                                })()
                            }
                        </div>
                    </div>
                    { (totalRows > 0) && <div className="box-footer ">
                        <button className="btn btn-danger btn-sm pull-right" onClick={()=>setIsOpen(false)}><i className="icon-close"></i>Close </button>
                    </div>}

                    {
                        (() => {
                            if(deleteJob && deleteJob==1){
                                    return <DeleteDialogueBox param={true} message ={"delete contact " + deletekontactName.kontactName} onChange={Confirmation}/>
                            }
                        })()
                    }
                </div>
            </Drawer>
        </>
    )
}
import React, { useEffect, useState } from "react";
import clientProfileServices from "../clientProfileServices";

export default function Email_Template (param:any) {

    const [emailTempList, initEmailTemplist] = useState<any>([]);
     const[selection, setSelection] = useState("");

    useEffect(() => {
        clientProfileServices.emailTemp().then((res) => { initEmailTemplist(res[0])})
    }, []);

    const handleSelectionChange = (e : any) => {
        setSelection(e.target.value)
    };

    const fieldHandle =(e:any) =>  {
        let key = e.target.name
        emailTempList[key] = e.target.value;
    }

    const saveAllDefaults = () => {
        clientProfileServices.saveEmailUserTemplate(emailTempList);
    }

    return (
        <div className="box box-add-region">
            <div className="box-header well">
                <h2><i className="icon-globe1 pt pr"></i> Email Template </h2>
            </div>

            <div className="box-content form-box">
                <form className="form-horizontal form-condensed" name="detailsInfo">
                    <div className="row-fluid form-condensed">

                        <div className="form-format ws-12 mtl">
                            <label className="control-label text-bold ws-12">Type</label>
                            <div className="label-entries">
                                <select onChange={handleSelectionChange} className="ws-6">
                                     <option value={emailTempList.typeId}>{emailTempList.type} </option>                                            
                                </select>
                            </div>
                        </div>

                        <div className="form-format ws-12 mtl">
                            <label className="control-label text-bold ws-12">Subject</label>
                            <div className="label-entries w-45">
                                <input type="text" className="" value={emailTempList.subject} onChange={fieldHandle} placeholder="Enter Subject"/>
                            </div>
                        </div>
                        <div className="form-format ws-12 mtl">
                            <label className="control-label text-bold ws-12">Message</label>
                            <div className="label-entries w-45">
                                <textarea className="" placeholder="Enter a Message" name="message" onChange={fieldHandle} value={emailTempList.message}></textarea>
                            </div>
                        </div>

                        <div className="form-format ws-12 mtl">
                            <label className="control-label text-bold ws-12">Attachment Limit Exceeded</label>
                            <div className="label-entries w-45">
                                <textarea className="" placeholder="Enter Attachment  " name="message" onChange={fieldHandle} value={emailTempList.attachment}></textarea>
                            </div>
                        </div>

                        <div className="form-format ws-12 mtl">
                            <label className="control-label text-bold ws-12">Signature</label>
                            <div className="label-entries w-45">
                                <textarea className="" placeholder="Enter Signature" name="message" onChange={fieldHandle} value={emailTempList.signature}></textarea>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className="box-footer pull-right">
                <button className="btn btn-success btn-sm" onClick={saveAllDefaults}> Save </button>
                <button className="btn btn-pink btn-sm hide"><i className="icon-reload"></i> Reset</button>
            </div>
        </div>
    )
}
import React, { Suspense, useState, useEffect } from "react";
import clientProfileServices from "../clientProfileServices";
import SearchTerritory from "./search_Territory";
import { format } from "date-fns";
import DatePickerRange from "../../../PeriodSelection/DatePickerRange";
import PeriodSelectionForm from "../../../PeriodSelection/periodSelectionForm";
import ClientProfileServices from "../clientProfileServices";

export const Details_Template = () => {

    const [selectedInvoiceType, setSelectedInvoiceType] = useState<string>("");
    const [selectedTerritory,setSelectedTerritory] = useState<string>("");
    const [selectedTerritoryName, setSelectedTerritoryName] = useState<string>("");
    const [checked, setChecked] = useState<any>({
        negativeCycling: false,
        payLicenses: false,
        useCaReduced: false,
        generateBlankReportInStatement: false,
        generateExcelReportInStatement: false,
        only1CopyOf104PdfInStatement: false,
    });

    const [statementFilenameFormat, setStatementFilenameFormat] = useState<any>("1");

    //for dropdown
    const invoiceTypes = [
        {
            invoiceType: "Both",
            invoiceTypeIndex: "both"
        },

        {
            invoiceType: "Publishing",
            invoiceTypeIndex: "publisher"
        },

        {
            invoiceType: "Recording",
            invoiceTypeIndex: "recording"
        }

    ];


    const [detailslist, setDetailslist] = useState<any>([]);
    const [territoryList, setTerritoryList] = useState<any>([]);


    let [ ContractStatus , setContractStatus ] = useState<any>([]);

    let [ Reportings  , setReportings] = useState<any>([]);
    let [ Basis ,setBasic] = useState<any>([]);
    let [ contractParties , setcontractParties] = useState<any>([]);


    let [ StatusAccount  ,setStatusAccount] = useState<any>([]);
    let [ StatusAccountExt  ,setStatusAccountExt] = useState<any>([]);
    let [ StatusStatement  ,setStatusStatement] = useState<any>([]);

    useEffect(() => {
        clientProfileServices.clientDetails()
            .then((res) => {

                //for normal fields
                setDetailslist(res[0])

                //for dropdown box
                const matchingInvoiceType = invoiceTypes.find(inv => inv.invoiceTypeIndex === res[0].invoiceType);
                if (matchingInvoiceType) {
                    setSelectedInvoiceType(matchingInvoiceType.invoiceTypeIndex);
                }

                //for checkboxes
                setChecked({
                    negativeCycling: res[0].negativeCycling === 1,
                    autoGenContractRefNum: res[0].autoGenContractRefNum === 1,
                    payLicenses: res[0].payLicenses === true,
                    useCaReduced: res[0].useCaReduced === true,
                    generateBlankReportInStatement: res[0].generateBlankReportInStatement === true,
                    generateExcelReportInStatement: res[0].generateExcelReportInStatement === true,
                    only1CopyOf104PdfInStatement: res[0].only1CopyOf104PdfInStatement === true,
                });

                setStatementFilenameFormat(res[0].statementFilenameFormat);

                getTerritory();

                //$scope.statusEnum = $filter('filter')(data, { lookUpType: 'ContractStatus' }, true);

                ContractStatus = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'ContractStatus')
                setContractStatus(ContractStatus)
                Reportings = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'Reportings')
                setReportings(Reportings)
                Basis = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'Basis')
                setBasic(Basis)
                contractParties = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'contractParties')
                setcontractParties(contractParties)


                StatusAccount = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'StatusAccount')
                setStatusAccount(StatusAccount)
                StatusAccountExt = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'StatusAccountExt')
                setStatusAccountExt(StatusAccountExt)
                StatusStatement = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'StatusStatement')
                setStatusStatement(StatusStatement)


                console.log(ContractStatus)
    

                /*var temp = global.tagColor.filter((lookUpListItem: any) => lookUpListItem.lookUpType == lookUpType);
                
                $scope.reportings = $filter('filter')(data, { lookUpType: 'Reportings' });
                $scope.basis = $filter('filter')(data, { lookUpType: 'Basis' });
                $scope.contractParties = $filter('filter')(data, { lookUpType: 'ContractParties' });
    
                $scope.accountStatuses = $filter('filter')(data, { lookUpType: 'StatusAccount' }, true);
                $scope.accountStatusesExt = $filter('filter')(data, { lookUpType: 'StatusAccountExt' }, true);
                $scope.statementStatuses = $filter('filter')(data, { lookUpType: 'StatusStatement' }, true); */

            })



    }, []);

                
    ClientProfileServices.getClientImage()
    .then((res) => {
        console.log(res);

        global.portalLogo = res;
    })
    const saveClientDetails = () => {

        const updatedDetailslist = {
            ...detailslist,
            invoiceType: selectedInvoiceType,
            negativeCycling: checked.negativeCycling ? 1 : 0,
            autoGenContractRefNum: checked.autoGenContractRefNum ? 1 : 0,
            payLicenses: checked.payLicenses ? true : false,
            useCaReduced: checked.useCaReduced ? true : false,
            generateBlankReportInStatement: checked.generateBlankReportInStatement ? true : false,
            generateExcelReportInStatement: checked.generateExcelReportInStatement ? true : false,
            only1CopyOf104PdfInStatement: checked.only1CopyOf104PdfInStatement ? true : false,
            statementFilenameFormat: statementFilenameFormat

        };

        if(updatedDetailslist.contractStartDate == null  || updatedDetailslist.contractStartDate =='0001-01-01T00:00:00'){
            updatedDetailslist.contractStartDate = '2000-01-01T00:00:00' ;
        }

        if(updatedDetailslist.contractEndDate == null || updatedDetailslist.contractEndDate =='0001-01-01T00:00:00'){
            updatedDetailslist.contractEndDate = '2000-01-01T00:00:00' ;
        }

        if(updatedDetailslist.contractDate == null || updatedDetailslist.contractDate =='0001-01-01T00:00:00'){
            updatedDetailslist.contractDate = '2000-01-01T00:00:00' ;
        }

        var today = new Date()
        console.log(today)

        clientProfileServices.saveClientDetails(updatedDetailslist)
            .then(res => {
                console.log("data saved successfully", res);
        })

    }

    const handleFormSubmit = (e: any) => {
        e.preventDefault();
        saveClientDetails();
    }

    //dropdown box
    const handleSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedInvoiceType(e.target.value.toLowerCase());
    };

    //checkboxs
    const handleInputChange = (checkboxName: string) => {
        setChecked((prevChecked: any) => ({
            ...prevChecked,
            [checkboxName]: !prevChecked[checkboxName],
        }));
    };

    //normal fields
    const handleInputChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDetailslist((prevDetails: any) => ({
            ...prevDetails,
            [name]: value
        }));
    };

    //radio buttons
    const handleRadioChange = (value: any) => {
        setStatementFilenameFormat(value);
    }

    const handleTerritorySelection = (territoryName: string, territoryCode: string, territoryCurrency: string) => {
        setDetailslist((prevDetails: any) => ({
            ...prevDetails,
            homeTerritory: territoryCode,
            homeCurrency: territoryCurrency,
        }));
    };

    const getTerritory = () => {
        clientProfileServices.territoryCodes().then(res => {
            setTerritoryList(res);
        })

    }

    useEffect(() => {
        const matchingTerritory = territoryList.find((territory: any) => detailslist.homeTerritory == territory.territoryCode );
        if (matchingTerritory) {
            setSelectedTerritory(matchingTerritory.territoryName);
            setSelectedTerritoryName(matchingTerritory.territoryName);
        }
    }, [territoryList]);
    


    const dateSelectionStartDate =(e :any) =>{

        console.log(e)
        var date = format(new Date(e), 'yyyy-MM-dd')
        console.log( date + 'T20:00:00.000Z' )
        detailslist.contractStartDate =  date + 'T20:00:00.000Z'
        setDetailslist(detailslist)
        /*if(e != null){

            if(e[0] != null){
                console.log( format(new Date(e[0]), 'yyyyMMdd') )
            }

            if(e[1] != null ){
                console.log( format(new Date(e[1]), 'yyyyMMdd') )
            }
        }*/
    }

    const dateSelectionEndate =(e :any) =>{
        console.log(e)
        var date = format(new Date(e), 'yyyy-MM-dd')
        console.log( date + 'T20:00:00.000Z' )
        detailslist.contractEndDate =  date + 'T20:00:00.000Z'
        setDetailslist(detailslist)
    }

    const dateSelectionSignature =(e :any) =>{
        console.log(e)
        var date = format(new Date(e), 'yyyy-MM-dd')
        console.log( date + 'T20:00:00.000Z' )
        detailslist.contractDate =  date + 'T20:00:00.000Z'
        setDetailslist(detailslist)
    }




    const handlefieldChange = (event  :any) => {

        let  field_name = event.target.name ;let  field_value = event.target.value ;
        /*if(field_name.includes(".")){
            const regex = new RegExp(`\\b.\\b`, 'i');
            const match = field_name.match(regex);
    
            var main_obj = field_name.slice(0,match.index);
            var sub_obj = field_name.slice((match.index+1),field_name.length);
            setDetailslist({...detailslist , [main_obj]: { ...detailslist[main_obj],  [sub_obj]: field_value,  }}); 

        }else{}*/

        
        console.log(event)
            
        console.log(field_name)
        console.log(field_value)
        console.log(detailslist)
        if(event.target.type == 'checkbox'){
            field_value = !detailslist.searchFor;
        }

        
        setDetailslist({...detailslist ,  [field_name] :field_value });
        
        console.log(detailslist)
    }
    

    const onChange = (period: any) => {
        console.log(period);

       console.log( format(new Date(period.periodFrom.month), 'MM dd') ) ;
        if(period.periodFrom) {
            detailslist.reportingStartDate = Number( period.periodFrom.year + format(new Date(period.periodFrom.month), 'MMdd') );
            setDetailslist(detailslist);
        }          

        //{reportingEndDate}
        if(period.periodTo) {
            detailslist.reportingEndDate = Number(period.periodTo.year + format(new Date(period.periodTo.month), 'MMdd'));
            setDetailslist(detailslist);
        }


        setDetailslist({...detailslist})
        console.log(detailslist)
    }

    return (

        <div className="row-fluid sortable box box-header-fix form-box">

            <div className="box-header well">
                <h2><i className="icon-edit pt pr"></i>Details</h2>
            </div>

            <div className="box-content">
                <div className="form-horizontal form-condensed">
                    <div className="row-fluid">


                    <div className="ws-12 box mtl">
                        <div className="box-header">
                          <h1>Contact</h1>
                        </div>

                        <div className="box-content">
                            <div className="form-format ws-3 mbl">
                                <label className="control-label ws-3" >Address 1</label>
                                <div className="label-entries">
                                    <input type="text" className="ws-12" name="clientAddress1" value={detailslist.clientAddress1} onChange={handleInputChangeField} placeholder="Enter Address 1" />

                                </div>
                            </div>
                            <div className="form-format ws-3  mbl">
                                <label className="control-label ws-3">Address 2</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientAddress2} name="clientAddress2" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Address 2" />
                                </div>
                            </div>

                            <div className="form-format ws-3  mbl mt">
                                <label className="control-label ws-3" >Address 3</label>
                                <div className="label-entries">
                                    <input type="text" name="clientAddress3" value={detailslist.clientAddress3} onChange={handleInputChangeField} className="ws-12" placeholder="Enter Address 3"></input>
                                </div>
                            </div>

                            <div className="form-format ws-3  mbl mt">
                                <label className="control-label ws-3">Address 4</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientAddress4} name="clientAddress4" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Address 4" />
                                </div>
                            </div>

                            <div className="form-format ws-3  mbl mt">
                                <label className="control-label ws-3"> Number </label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientNumber} name="clientNumber" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Number" />
                                </div>
                            </div>

                            <div className="form-format ws-3 mbl mt">
                                <label className="control-label ws-3"> Contact </label>
                                <div className="label-entries">
                                    <input type="text" className="form-control ws-12" name="clientContact" onChange={handleInputChangeField} value={detailslist.clientContact} placeholder="Enter Contact" />
                                </div>
                            </div>


                        </div>

                    </div>

                    <div className="ws-12 box mtl">
                        <div className="box-header">
                          <h1>Contract</h1>
                        </div>
                        <div className="box-content">

                            
                           <div className="form-format ws-3">
                                <label className="control-label">Contract Party</label>
                                    <div className="label-entries">
                                        <select  onChange={handlefieldChange} name="contractParty" value={detailslist.contractParty }>
                                            <option value="">Choose contract Party</option>
                                            <option value="0" label="Artist">Artist</option>
                                            <option value="1" label="3rd Party">3rd Party</option>
                                            <option value="2" label="Licensor">Licensor</option>
                                            <option value="3" label="Licensee">Licensee</option>
                                            <option value="4" label="Publisher">Publisher</option>
                                        </select>
                                    </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Accounting Period</label>
                                    <div className="label-entries">
                                        <select name="accountingPeriod" onChange={handlefieldChange} value={detailslist.accountingPeriod }>
                                            <option value="">Choose Accounting Period</option>
                                            {
                                                Reportings?.map((values:any ) => {

                                                    return (
                                                        <option value={values.key} label={values.value}>{values.value}</option>
                                                    )

                                                })
                                            }
                                        </select>
                                    </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Contract Basis</label>
                                    <div className="label-entries">
                                        <select  name="contractType" onChange={handlefieldChange} value={detailslist.contractType }>
                                            <option value="">Choose contract Type</option>
                                            {
                                                Basis?.map((values:any ) => {

                                                    return (
                                                        <option value={values.key} label={values.value}>{values.value}</option>
                                                    )

                                                })
                                            }
                                        </select>
                                    </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Contract Status</label>
                                    <div className="label-entries">
                                        <select  name="contractStatus" onChange={handlefieldChange} value={detailslist.contractStatus }>
                                            <option value="">Choose contract Status</option>
                                            {
                                                ContractStatus?.map((values:any ) => {

                                                    return (
                                                        <option value={values.key} label={values.value}>{values.value}</option>
                                                    )

                                                })
                                            }
                                        </select>
                                    </div>
                            </div>



                            <div className="form-format ws-3">
                                <label className="control-label">Account Status</label>
                                    <div className="label-entries">
                                        <select  name="accountStatus" onChange={handlefieldChange} value={detailslist.accountStatus }>
                                            <option value="">Choose account Status</option>
                                            {
                                                StatusAccount?.map((values:any ) => {

                                                    return (
                                                        <option value={values.key} label={values.value}>{values.value}</option>
                                                    )

                                                })
                                            }
                                        </select>
                                    </div>
                            </div>


                            <div className="form-format ws-3">
                                <label className="control-label">Account Extented Status</label>
                                    <div className="label-entries">
                                        <select name="accountStatusExt" onChange={handlefieldChange} value={detailslist.accountStatusExt }>
                                            <option value="">Choose account Status Ext</option>
                                            {
                                                StatusAccountExt?.map((values:any ) => {

                                                    return (
                                                        <option value={values.key} label={values.value}>{values.value}</option>
                                                    )

                                                })
                                            }
                                        </select>
                                    </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Statement Status</label>
                                    <div className="label-entries">
                                        <select  name="statementCategory" onChange={handlefieldChange} value={detailslist.statementCategory }>
                                            <option value="">Choose statement Category</option>
                                            {
                                                StatusStatement?.map((values:any ) => {

                                                    return (
                                                        <option value={values.key} label={values.value}>{values.value}</option>
                                                    )

                                                })
                                            }
                                        </select>
                                    </div>
                            </div>


                            <div className='form-format ws-3'>
                                <label className="ws-12 text-bold">Start Date</label>
                                <div className="label-entries">
                                    <DatePickerRange onChange={dateSelectionStartDate} type ={ "single"} data={{"date" : detailslist.contractStartDate}}/>

                                </div>
                            </div>


                            <div className='form-format ws-3'>
                                <label className="ws-12 text-bold">End Date </label>
                                <div className="label-entries">
                                    <DatePickerRange onChange={dateSelectionEndate} type ={"single"} data={{"date" :  detailslist.contractEndDate}}/>
                                </div>                                
                            </div>

                            <div className='form-format ws-3'>
                                <label className="ws-12 text-bold">Signature Date</label>
                                <div className="label-entries">
                                    <DatePickerRange onChange={dateSelectionSignature} type ={"single"} data={{"date" :  detailslist.contractDate}}/>
                                </div>                                
                            </div>

                            <div className='form-format ws-6'>
                                <div className='form-format ws-4'>
                                    <label className="text-bold ws-12 forceSingleLine pr">Reporting Start Date </label>
                                    <div className="label-entries">
                                        <input className="ws-11" value = {detailslist.reportingStartDate} onChange={handlefieldChange}/>
                                    </div>

                                </div>

                                <div className='form-format ws-4'>
                                    <label className="ws-12 text-bold forceSingleLine">Reporting End Date </label>
                                    <div className="label-entries">
                                        <input className="ws-11" value = {detailslist.reportingEndDate} onChange={handlefieldChange}/>
                                    </div>
                                    
                                </div>

                                <div className="ptxl ws-1 mtl"> <PeriodSelectionForm onChange={onChange}/> </div>
                            </div>

                            <div className="form-format ws-3"> 
                                <div className="form-format ws-6">
                                    <label className="control-label ws-6">No End Date</label>
                                    <div className="ws-3">
                                        <input type="checkbox" value={detailslist.noEndDate}/>
                                    </div>
                                </div>
    
                                <div className="form-format ws-6">
                                    <label className="control-label ws-6">In Perpetuity</label>
                                    <div className="ws-3">
                                        <input className="m0 mr" type="checkbox" data-ng-true-value="1" data-ng-false-value="0" id="inPerpetuity" checked={detailslist.inPerpetuity}/>
                                        
                                    </div>
                                </div>
    

                            </div>

                            <div className="form-format ws-6">

                                <div className="form-format ws-3">
                                    <div className="label-entries">
                                        Royalty Contract
                                        <label className="switch m0 mr">
                                            <input type="checkbox" name="royaltyContract" checked={detailslist.royaltyContract} onChange={handlefieldChange} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="form-format ws-3">
                                    <div className="label-entries">
                                       collector Contract
                                        <label className="switch m0 mr">
                                            <input type="checkbox" name="collectorContract" checked={detailslist.collectorContract} onChange={handlefieldChange} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="form-format ws-3">
                                    <div className="label-entries">
                                        publishing  Contract
                                        <label className="switch m0 mr">
                                            <input type="checkbox" name="publishingContract" checked={detailslist.publishingContract} onChange={handlefieldChange} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="form-format ws-3">
                                    <div className="label-entries">
                                       mechanical Contract
                                        <label className="switch m0 mr">
                                            <input type="checkbox" name="mechanicalContract" checked={!detailslist.mechanicalContract} onChange={handlefieldChange} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <div className="label-entries">
                                    Auto Generate ContractRefNum
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.autoGenContractRefNum } onChange={() => handleInputChange("autoGenContractRefNum ")} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-format mtl ws-6">
                                <label className="control-label">Default Link Track</label>
                                <div className="label-entries mt">
                                    <div className="ws-3"><input type="radio" onClick={handlefieldChange} checked={detailslist.defaultLinkTrack==0} value="0" name="defaultLinkTrack"/>Contract Track</div>
                                    <div className="ws-3"><input type="radio" onClick={handlefieldChange} checked={detailslist.defaultLinkTrack==1} value="1" name="defaultLinkTrack"/>Unlinked Tracks</div>
                                    <div className="ws-3"><input type="radio" onClick={handlefieldChange} checked={detailslist.defaultLinkTrack==2} value="2" name="defaultLinkTrack"/>Tracks Already Linked</div>
                                    <div className="ws-3"><input type="radio" onClick={handlefieldChange} checked={detailslist.defaultLinkTrack==3} value="3" name="defaultLinkTrack"/>Release Track</div>
                                </div>
                            </div>

                        </div>

                        

                    </div>

                    <div className="ws-12 box mtl">
                        <div className="box-header">
                          <h1>Reporting</h1>
                        </div>
                        <div className="box-content">
                            <div className="form-format ws-3  mt">
                                <label className="control-label ws-3">Invoice Type</label>
                                <div className="label-entries ws-11">
                                    <select onChange={handleSelectionChange} value={selectedInvoiceType}>
                                        <option value="">Select Invoice Type</option>
                                        {invoiceTypes.map((inv) => <option key={inv.invoiceTypeIndex} value={inv.invoiceTypeIndex}>{inv.invoiceType}</option>

                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="form-format ws-3 mbl mt">
                                <label className="control-label ws-3"> Royalty CCs</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.royaltyCc} name="royaltyCc" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Email for Royalty" />
                                </div>
                            </div>

                            <div className="form-format ws-3 mbl mt">
                                <label className="control-label ws-3">Mechanical CCs</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.mechanicalCc} name="mechanicalCc" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Email for Mechanical" />
                                </div>
                            </div>

                            <div className="form-format ws-3 mbl mt">
                                <label className="control-label ws-3">Publisher CCs</label>
                                <div className="label-entries">
                                    <input type="text" className="form-control ws-12" value={detailslist.publisherCc} name="publisherCc" onChange={handleInputChangeField} placeholder="Enter Email for Publisher"></input>
                                </div>
                            </div>

                            <div className="form-format ws-3  mt">
                                <label className="control-label ws-3">Mail from</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.mailFrom} name="mailFrom" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Mail From" />
                                </div>
                            </div> 

                            <div className="form-format ws-3">
                                <div className="label-entries">
                                    Negative Cycling
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.negativeCycling} onChange={() => handleInputChange("negativeCycling")} />
                                        <span className="slider round text-right white pm pts sliderOff"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <div className="label-entries">
                                    Pay on Licenses only
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.payLicenses} onChange={() => handleInputChange("payLicenses")} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <div className="label-entries">
                                    Use reduced CA Mechanical Rate
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.useCaReduced} onChange={() => handleInputChange("useCaReduced")} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <div className="label-entries">
                                    Generate Blank Report In Statement
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.generateBlankReportInStatement} onChange={() => handleInputChange("generateBlankReportInStatement")} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <div className="label-entries">
                                    Generate Excel Report In Statement
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.generateExcelReportInStatement} onChange={() => handleInputChange("generateExcelReportInStatement")} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <div className="label-entries">
                                    Only 1 Copy of 104 Pdf in statement
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.only1CopyOf104PdfInStatement} onChange={() => handleInputChange("only1CopyOf104PdfInStatement")} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-format ws-3 mbl mtl">
                                <label className="control-label ws-12">Statement Filename Format</label>
                                <div className="label-entries mt">
                                    <div className="ws-12 m0">
                                        <input type="radio" name="a" value="1" checked={statementFilenameFormat === 1} onChange={() => handleRadioChange(1)} />
                                        <label className="ws-11">Period_ContractRef_ReportType (Default)</label>
                                    </div>

                                    <div className="ws-12 m0">
                                        <input type="radio" name="a" value="2" checked={statementFilenameFormat === 2} onChange={() => handleRadioChange(2)} />
                                        <label className="ws-11">ContractRef_ContractTitle_ReportType_Period</label>
                                    </div>
                                </div>
                            </div>
                        
                        </div>

                    </div>


                    <div className="ws-12 box mtl">
                        <div className="box-header">
                          <h1>Addition Info</h1>
                        </div>
                        <div className="box-content">
                            <div className="form-format ws-3 mbl mt">
                                <label className="control-label ws-3" >Website</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientWebsite} name="clientWebsite" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Website" />
                                </div>
                            </div>
                            <div className="form-format ws-3  mbl mt">
                                <label className="control-label ws-8" >Mech Costs Sales</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.mechCostsSales} name="mechCostsSales" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Mech Costs Sales" />
                                </div>
                            </div>

                            <div className="form-format ws-3  mbl mt">
                                <label className="control-label ws-8">DDEX Code</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.DDEXPartyId} name="DDEXPartyId" onChange={handleInputChangeField} className="form-control ws-12" placeholder="DDEX Code" />
                                </div>
                            </div>

                            <div className="form-format ws-3  mbl mt">
                                <label className="control-label ws-3 forceSingleLine">DDEX Full Name</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.DDEXFullName} name="DDEXFullName" onChange={handleInputChangeField} className="form-control ws-12" placeholder="DDEX Full Name" />
                                </div>
                            </div>

                            <div className="form-format ws-6 mbl mt">
                                <label className="control-label ws-6 mb"> Default Territory / Currency </label>

                                <div className="label-entries">

                                    <div className="ws-4">
                                        {detailslist.homeTerritory && <button type="button" className="btn noCursor infoText btn-primary">{detailslist.homeTerritory}</button> }
                                        {detailslist.homeCurrency && <button type="button" className="btn noCursor infoText btn-primary">{detailslist.homeCurrency}</button> }
                                    </div>

                                    <div className="ws-8">
                                    <SearchTerritory onSelectTerritory={handleTerritorySelection} selectedTerritory={selectedTerritory} />
                                    </div>

                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <div className="label-entries">
                                    Combined search for Common Search
                                    <label className="switch m0 mr">
                                        <input type="checkbox" name="searchFor" checked={detailslist.searchFor } onChange={handlefieldChange} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>


{/*                         <div className="ws-6">

                            <div className="form-format ws-11 mbl">
                                <label className="control-label ws-3" >Address 1</label>
                                <div className="label-entries">
                                    <input type="text" className="ws-12" name="clientAddress1" value={detailslist.clientAddress1} onChange={handleInputChangeField} placeholder="Enter Address 1" />

                                </div>
                            </div>

                            <div className="form-format ws-11 mbl mt">
                                <label className="control-label ws-3" >Address 3</label>
                                <div className="label-entries">
                                    <input type="text" name="clientAddress3" value={detailslist.clientAddress3} onChange={handleInputChangeField} className="ws-12" placeholder="Enter Address 3"></input>
                                </div>
                            </div>

                            <div className="form-format ws-11 mbl mt">
                                <label className="control-label ws-3" >Post Code</label>
                                <div className="label-entries">
                                    <input type="text" name="clientPostCode" onChange={handleInputChangeField} value={detailslist.clientPostCode} className="form-control ws-12" placeholder="Enter Post Code" />
                                </div>
                            </div>
                            <div className="form-format ws-11 mbl mt">
                                <label className="control-label ws-3" >Website</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientWebsite} name="clientWebsite" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Website" />
                                </div>
                            </div>
                            <div className="form-format ws-11 mbl mt">
                                <label className="control-label ws-3" >Mech Costs Sales</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.mechCostsSales} name="mechCostsSales" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Mech Costs Sales" />
                                </div>
                            </div>

                            <div className="form-format ws-11 mbl mt">
                                <label className="control-label ws-3">DDEX Code</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.DDEXPartyId} name="DDEXPartyId" onChange={handleInputChangeField} className="form-control ws-12" placeholder="DDEX Code" />
                                </div>
                            </div>

                            <div className="form-format ws-11 mbl mt">
                                <label className="control-label ws-3">DDEX Full Name</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.DDEXFullName} name="DDEXFullName" onChange={handleInputChangeField} className="form-control ws-12" placeholder="DDEX Full Name" />
                                </div>
                            </div>


                            <div className="form-format ws-11 mbl mt">
                                <label className="control-label ws-3">Invoice Type</label>
                                <div className="label-entries ws-11">
                                    <select onChange={handleSelectionChange} value={selectedInvoiceType}>
                                        <option value="">Select Invoice Type</option>
                                        {invoiceTypes.map((inv) => <option key={inv.invoiceTypeIndex} value={inv.invoiceTypeIndex}>{inv.invoiceType}</option>

                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="form-format mt">
                                <div className="label-entries">
                                    Negative Cycling
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.negativeCycling} onChange={() => handleInputChange("negativeCycling")} />
                                        <span className="slider round text-right white pm pts sliderOff"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-format">
                                <div className="label-entries">
                                    Pay on Licenses only
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.payLicenses} onChange={() => handleInputChange("payLicenses")} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-format">
                                <div className="label-entries">
                                    Use reduced CA Mechanical Rate
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.useCaReduced} onChange={() => handleInputChange("useCaReduced")} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-format">
                                <div className="label-entries">
                                    Generate Blank Report In Statement
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.generateBlankReportInStatement} onChange={() => handleInputChange("generateBlankReportInStatement")} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-format">
                                <div className="label-entries">
                                    Generate Excel Report In Statement
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.generateExcelReportInStatement} onChange={() => handleInputChange("generateExcelReportInStatement")} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-format">
                                <div className="label-entries">
                                    Only 1 Copy of 104 Pdf in statement
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.only1CopyOf104PdfInStatement} onChange={() => handleInputChange("only1CopyOf104PdfInStatement")} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="ws-6">

                            <div className="form-format ws-11 mbl">
                                <label className="control-label ws-3">Address 2</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientAddress2} name="clientAddress2" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Address 2" />
                                </div>
                            </div>

                            <div className="form-format ws-11 mbl mt">
                                <label className="control-label ws-3">Address 4</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientAddress4} name="clientAddress4" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Address 4" />
                                </div>
                            </div>

                            <div className="form-format ws-11 mbl mt">
                                <label className="control-label ws-3"> Number </label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientNumber} name="clientNumber" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Number" />
                                </div>
                            </div>
                            <div className="form-format ws-11 mbl mt">
                                <label className="control-label ws-3"> Contact </label>
                                <div className="label-entries">
                                    <input type="text" className="form-control ws-12" name="clientContact" onChange={handleInputChangeField} value={detailslist.clientContact} placeholder="Enter Contact" />
                                </div>
                            </div>

                            <div className="form-format ws-8 mbl mt">
                                <label className="control-label ws-6 mb"> Default Territory / Currency </label>

                                <div className="label-entries">

                                    <div className="ws-2">
                                        <button type="button" className="btn noCursor infoText btn-primary">{detailslist.homeTerritory}</button>
                                    </div>

                                    <div className="ws-2 mrl">
                                        <button type="button" className="btn noCursor infoText btn-primary">{detailslist.homeCurrency}</button>
                                    </div>

                                    <div className="ws-7">
                                    <SearchTerritory onSelectTerritory={handleTerritorySelection} selectedTerritory={selectedTerritory} />
                                    </div>

                                </div>
                            </div>


                            <div className="form-format ws-11 mbl mt">
                                <label className="control-label ws-3"> Royalty CCs</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.royaltyCc} name="royaltyCc" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Email for Royalty" />
                                </div>
                            </div>

                            <div className="form-format mbl mt">
                                <label className="control-label ws-3">Mechanical CCs</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.mechanicalCc} name="mechanicalCc" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Email for Mechanical" />
                                </div>
                            </div>

                            <div className="form-format mbl mt">
                                <label className="control-label ws-3">Publisher CCs</label>
                                <div className="label-entries">
                                    <input type="text" className="form-control ws-12" value={detailslist.publisherCc} name="publisherCc" onChange={handleInputChangeField} placeholder="Enter Email for Publisher"></input>
                                </div>
                            </div>

                            <div className="form-format mbl mt">
                                <label className="control-label ws-3">Mail from</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.mailFrom} name="mailFrom" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Mail From" />
                                </div>
                            </div>

                            <div className="form-format mbl mtl">
                                <label className="control-label ws-3">Statement Filename Format</label>
                                <div className="label-entries mt">
                                    <div className="ws-12 m0">
                                        <input type="radio" name="a" value="1" checked={statementFilenameFormat === 1} onChange={() => handleRadioChange(1)} /><label htmlFor="Period_ContractRef_ReportType">Period_ContractRef_ReportType (Default)</label>
                                    </div>

                                    <div className="ws-12 m0">
                                        <input type="radio" name="a" value="2" checked={statementFilenameFormat === 2} onChange={() => handleRadioChange(2)} /><label htmlFor="ContractRef_ContractTitle_ReportType_Period">ContractRef_ContractTitle_ReportType_Period</label></div>
                                </div>
                            </div>

                            <div className="form-format">
                                <div className="label-entries">
                                    Auto Generate ContractRefNum
                                    <label className="switch m0 mr">
                                        <input type="checkbox" checked={checked.autoGenContractRefNum} onChange={() => handleInputChange("autoGenContractRefNum")} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div> */}



                    </div>
                    <div className="box-footer pull-right">
                        <button className="btn btn-success btn-sm" onClick={handleFormSubmit}  type="submit"><i className="icon-save"></i> Update</button>
                    </div>
                </div>
            </div>


        </div>
    )

}
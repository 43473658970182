//import DatePicker from "react-datepicker";
import DatePicker from "react-datepicker"
//import "react-datepicker/dist/react-datepicker.css";
import React, { Suspense, useState, useEffect } from "react";
import { format } from "date-fns";

export default function TableDatePicker(param: any, data: any) {

  const [dateRange, setDateRange] = useState([null, null]);
  let [startDate, endDate] = dateRange;


  const [DOpen, setDOpen] = useState(false)

  //setStartDate(new format(new Date("Wed Oct 11 2023 00:00:00 GMT+0400"), 'yyyyMMdd') );
  useEffect(() => {

    //endDate()
    if (param.data) {
      if (param.data.date) {
        //startDate = param.data.contractEndDate;
        const date: any = new Date(param.data.date)
        setDateRange([date, null]);
      }
    }
  }, [])

  const dataReceive = (e: any) => {
    setDateRange(e)
    param.onChange(e)
  }

  const dataReceiveSingle = (e: any) => {
    setDateRange([e, null])
    param.onChange(e)
  }

  return (
    <div className="pull-left">
      {
        (() => {
          if (param.type == "icon") {
            return (
              <i className="icon-calendar4" >
                <DatePicker
                  startDate={startDate}
                  onChange={(update) => {
                    dataReceiveSingle(update);
                  }}
                  isClearable={true}>

                </DatePicker>
              </i>


            );
          } else if (param.type == "button") {

            return (
              <div className="ws-12 ">
                <div className="ws-10">
                  <DatePicker
                    className="ws-12"
                    placeholderText="Period Range"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    open={DOpen}
                    onChange={(update) => {
                      dataReceive(update);
                    }}
                    isClearable={true} />
                </div>


                <button className="btn-success btn-xs" onClick={() => setDOpen(!DOpen)}>
                  <i className="icon-calendar4" />
                </button>


              </div>

            )


          } else if (param.type == "noLabel") {

            return (
              <div className="ws-12 pull-left">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    dataReceive(update);
                  }}
                  isClearable={true}
                />

              </div>
            );
          } else if (param.type == "single") {

            return (
              <div className="ws-12 pull-left">
                <DatePicker
                  selected={startDate}
                  onChange={(date: any) => dataReceiveSingle(date)}
                />

              </div>
            );
          } else {

            return (
              <div className="pull-left">
                <strong className="ws-12"> Date From - Date To </strong>

                <DatePicker
                  className="ws-12"
                  placeholderText="Select Date From - To"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    dataReceive(update);
                  }}
                  isClearable={true}
                />



              </div>
            );
          }

        })()
      }



    </div>
  );
}


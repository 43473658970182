import { CommonUtilities }  from '../../commonUtils'

export default class UserService  {

    static Login(data: any) {
        return CommonUtilities.postData('login/', data);
    }

    static ChangePasswd(emailRequest: any){
        var url = 'changePassword';
        return CommonUtilities.postData(url,emailRequest);
    }

    static postUploadFile(file : any, data: any){
        var url = 'userAttributesReact/userProfileInfo';
        return CommonUtilities.postUploadFile(url, file, data);
    }

    static forgetPassword(sendPassword: any){
        var url = 'forgetPassword';
        return CommonUtilities.postData(url, sendPassword);
    }

    static changeDisplayName(email: any, firstName: any,lastName: any ){
        var url = 'displayName';
        return CommonUtilities.postData(url, {email, firstName,lastName});
    }


    static removeAvatarImage(data: any){
        var url = 'userAttributesReact/ProfileAvatarDelete/' +data;
        //return CommonUtilities.postData(url,{avatar,avatarTh});
        return CommonUtilities.deleteData(url);
    } 

    static deletePhotoUser(data: any){
        var url = 'userAttributesReact/userProfileInfo/'+data;
        return CommonUtilities.postData(url, data);
    }

}
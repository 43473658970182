import { format } from 'date-fns';
import { CommonUtilities } from '../../commonUtils';

export default class projectServices {

    static projectIndicators(data: any) {
        const dataList: any = data.projectDetails;
        var url = 'contracts/indicators/periodSummaryBalance?projectId=' + data.projectId;
        return CommonUtilities.postData(url, data);
    }

    //Projects in Contract Summary
    //static async ProjectListing(data : {})

    static ProjectListing(data: {}) {
        var url = 'licensing/projectList';
        return CommonUtilities.postData(url, data);
    }

    //search for a project
    static projectSelection(data: any) {
        var url = 'licensing/projectList';
        return CommonUtilities.postData(url, data);
    }


    //https://staging1.onkorrect.com/Api/common/tagColor/listAll
    static listAll() {
        var url = 'common/tagColor/listAll';
        return CommonUtilities.getData(url);
    }


    static lookUpTypeList() {
        var url = 'common/lookUpTypeList';
        return CommonUtilities.getData(url);
    }

    static projectGrid() {
        var url = 'metadata/globalSearch/list?type=projectGrid';
        return CommonUtilities.getData(url);
    }

    static projectFinancialPerformance(data: any) {
        var url = 'contracts/financialPerformance?' + CommonUtilities.buildQueryString(data, true, true); ///+ data;
        return CommonUtilities.getData(url);
    }


    //Projectstatus dropdown menu: "pending,completed,approved,revised,closed" in project contract
    static projectStatusOptionsSelect(data: any) {
        var url = 'synch/project/saveStatus';
        return CommonUtilities.postData(url, data);
    }

    //https://qa.onkorrect.com/Api/metadata/globalSearch/list?type=contractPerformance
    static contractPerformance(data: any) {
        var url = 'metadata/globalSearch/list?type=' + data;
        return CommonUtilities.getData(url);
    }
    //https://qa.onkorrect.com/Api/contracts/calculationContract/
    static calculationContract(data: any) {
        var url = 'contracts/calculationContract';
        return CommonUtilities.postData(url, data);
    }

    /**
     * project indicator slider
     */
    //https://staging.onkorrect.com/Api/contracts/indicator
    static paymentIndicator(data: any) {
        var url = 'contracts/indicator';
        return CommonUtilities.postData(url, data);
    }

    static paymentIndicatorExport(data: any) {
        var url = 'contracts/indicator?' + CommonUtilities.buildQueryString(data, true, true);
        //return CommonUtilities.getData(url);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'Export');
    }

    static recalcContractPayment(data: any) {
        var url = 'contracts/recalcContractPayment';
        return CommonUtilities.postData(url, data);
    }

    static deletePayment(data: any, paymentId: any) {
        var url = 'contracts/payments/delete/' + paymentId.paymentId + '?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    }

    static indicatorLevel2(data: any) {
        var url = 'contracts/indicatorLevel2';
        return CommonUtilities.postData(url, data);
    }

    static indicatorLevel2Export(data: any) {
        var url = 'contracts/indicatorLevel2?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'Export');
    }

    /*
    Description
     */
    static descriptionList(data: any) {
        var url = 'synch/project/description/list?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    }

    /*
   Project Setting
    */
    static attributeList(data: any) {
        var url = 'synch/project/attribute/list?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    }


    ///https://qa.onkorrect.com/Api/contracts/financialPerformance?projectCode=M00046_COLD&type=projectAnalysisApproval&pageNumber=1&pageSize=10

    static financialPerformance(data: any) {
        var url = 'contracts/financialPerformance?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    }
    //https://qa.onkorrect.com/Api/approval/statementApproval/action?actionCode=1&kontactCode=&period=20190930&contactType=&searchText=&emailText=&biannual=&quarterly=&monthly=&statementId=&comment=&queueId=4b07c94a-a222-47d6-a5bc-de0217b8fc17&param=queue
    static statementApproval(data: any) {
        var url = 'approval/statementApproval/action?' + CommonUtilities.buildQueryString(data, true, true);
        console.log(url)
        console.log(data)
        return CommonUtilities.getData(url);
    }

    static projectFinancialPerformanceExport(data: any) {
        var url = 'contracts/financialPerformance?' + CommonUtilities.buildQueryString(data, true, true);
        //return CommonUtilities.getData(url);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', "financialPerformance")
    }

    //https://qa.onkorrect.com/Api/contracts/indicators/projectAffiliateBalanceDetail?pageNumber=1&pageSize=10&PeriodFrom=&PeriodTo=&contractRefNum=001test&HeaderType=
    static projectAffiliateBalanceDetail(data: any) {
        var url = 'contracts/indicators/projectAffiliateBalanceDetail?' + CommonUtilities.buildQueryString(data, true, true);
        console.log(url)
        console.log(data)
        return CommonUtilities.getData(url);
    }

    //https://qa.onkorrect.com/Api/contracts/indicators/projectAffiliateBalance?pageNumber=1&pageSize=10
    static projectAffiliateBalance(payload: any, data: any) {
        var url = 'contracts/indicators/projectAffiliateBalance?' + CommonUtilities.buildQueryString(payload, true, true);
        console.log(url)
        console.log(data)
        return CommonUtilities.postData(url, data);
    }

    static getContactsByContractRefNum(data: any) {
        var url = 'contacts/' + data + '/getContactsByContractRefNum';
        return CommonUtilities.getData(url);
    }
    //https://qa.onkorrect.com/Api/contracts/indicators/periodSummaryBalance
    static projectSummaryBalanceIndicators(data: any) {
        var url = 'contracts/indicators/periodSummaryBalance';
        return CommonUtilities.postData(url, data);
    }

    static overview(data: any) {
        var url = 'contracts/overview/' + data + '?contractRefNum=' + data;
        return CommonUtilities.getData(url);
    }

    static contracts(data: any) {
        var url = 'contracts/' + data;
        return CommonUtilities.getData(url);
    }

    static getExcludedContractStatus = function (data: any) {
        var url = 'contract/tag/list?area=ProjectContractStatusExclude&' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    };


    static getExcludedProductStatus = function (data: any) {
        var url = 'contract/tag/list?area=ProductStatusExclude&' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    };

    static getContractStatus = function (data: any) {
        var url = 'contracts/contractStatus/list' + '?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    };

    static setProjectSettingsDetails(data: any) {
        var url = 'synch/project/attribute/save';
        return CommonUtilities.postData(url, data);
    }

    static tagTypeList() {
        var url = 'common/tagColor/listAll';
        return CommonUtilities.getData(url);
    };

    static getProductStatus(data: any) {
        var url = 'common/statusProduct/list' + '?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    };

    static addProjectTag(data: any) {
        var url = 'contract/tag/save';
        return CommonUtilities.putData(url, data);
    };

    static removeProjectTag(data: any) {
        var url = 'contract/tag/delete/' + data;
        return CommonUtilities.deleteData(url);
    };

    static getProjectSettingsDetails(data: any) {
        var url = 'synch/project/attribute/list' + '?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    };

    /*static getProjectTag = function (data :any) {
        var url = 'contract/tag/list?area=' + tagRequest.area + '&referenceCode=' + tagRequest.referenceCode;
        return CommonUtilities.getData(url);
    };
*/

    static createStatementQueue(data: any) {
        var url = 'approval/statementQueue/queue/creation' + '?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    };


    static financialPerformanceCompare(data: any) {
        var url = 'contracts/financialPerformanceCompare' + '?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    };


    static financialPerformanceCompareExport(data: any) {
        var url = 'contracts/financialPerformanceCompare?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.exportWithToken('GET', url, {}, '_blank', data.type)
    }



    static getProjectDescription(data: any) {
        var url = 'synch/project/description/list' + '?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    };

    static saveProjectDescription(data: any) {
        var url = 'synch/project/description/save';
        return CommonUtilities.postData(url, data);
    };

    static generateProjectStatement(data: any) {
        var url = 'contract/statement/project/generate';
        return CommonUtilities.postData(url, data);
    };

    static getClientDefaultValues = function () {
        var url = 'client/getEmailUserTemplate';
        return CommonUtilities.getData(url);
    };

    static saveClientDefaultValues(data: any) {
        var url = 'client/saveEmailUserTemplate';
        return CommonUtilities.postData(url, data);
    };

    static publishStatements(data: any) {
        var url = 'Contract/statementSummary/publishToAffiliate';
        return CommonUtilities.postData(url, data);
    };

    static emailQueueStatements(data: any) {
        var url = 'contract/statement/project/email';
        return CommonUtilities.postData(url, data);
    };

    static getProjectStatementList(data: any) {
        /*if (data && data.export) {
            var url = 'contract/project/statement/list?' +  CommonUtilities.buildQueryString(data , true , true);
            CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'ProjectStatementDetails');
        } else {*/
        var url = 'contract/statement/project/list' + '?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
        //}
    };


    static financialPerformation(data: any) {

        var url = 'contracts/financialPerformance' + '?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);

    };

    static approveAllContracts(data: any) {
        var url = 'approval/statementApproval/project/action';
        return CommonUtilities.postData(url, data);
    };

    static projectDetailDownload(data: any) {
        var url = 'contract/statement/project/download';
        return CommonUtilities.exportWithToken('POST', url, data, '_self', data.projectCode);
    };

    static loadDefaultStatmentContractListing (data: any) {
        var url = 'approval/statementQueue/queue/validation' + '?' + CommonUtilities.buildQueryString(data, true, true);
        return CommonUtilities.getData(url);
    };

    static copyContractProjects (data: any) {
        var url = 'catalogue/projectAsset/saveProjectList';
        return CommonUtilities.postData(url, data);
    };

    static unlinkProjectContract (data: any) {
        var url = 'catalogue/projectAsset/unlinkProject?assetType=contract';
        return CommonUtilities.deleteData(url);
    };

    static unlinkProjectContact = function (data: any) {
        var url = 'catalogue/projectAsset/unlinkProjectByContact?assetType=contract';
        return CommonUtilities.deleteData(url);
    };

}
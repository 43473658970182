import React, { useEffect, useState } from "react";
import clientProfileServices from "../clientProfileServices";
import Pagination from "../../../Header/Pagination";

export default function  StatementApproval_Template(param:any) {

    const [users, initUsers] = useState<any[]>([]);
    const [statementApprover, initstatementApprover] = useState<any[]>([]);
    const [totalRows ,setTotalRows] = useState(1);
    var dataLink = "pageSize=10&pageNumber=1"; 
    const [saveAlert , setsaveAlert] = useState(false);
    const [saveSus , setsaveSus] = useState(false);
    const [value, setValue] = React.useState('');
    const [valueLevel, setValueLevel] = React.useState('');
    let [displayName, initdisplayName] = useState<any>([]);

    var payload  = {
        "pageNumber": 1 ,
        "pageSize": 10
    }

    const Data:any =  {
        pageSize : 10,
        pageNumber : 1 
    }

    useEffect(() => {
        Api();
    }, []);

    const Api  = () => {
        clientProfileServices.users({"usertype": "superadmin"}).then((res) => {
            initUsers(res)
        })

        clientProfileServices.statementApprover(payload).then((res) => {
            initstatementApprover(res)
            setTotalRows(res[0].totalRows)
        })
    }

    const handleNameOnChange = (n: any) => {  
        Data.pageNumber = n.pageNumber;
        Data.pageSize =n.pageSize;
    
        clientProfileServices.statementApprover({"pageNumber": Data.pageNumber ,"pageSize": Data.pageSize}).then((res) => {
            initstatementApprover(res)
            setTotalRows(res[0].totalRows)
        })

    }     

    const editUser = (e:any) => {
        for(var i=0 ; i < users.length ; i++){
            var fullName = ( users[i].firstName + " "+ users[i].lastName)
            if( fullName ==  e.name){
                initdisplayName(e)
                setValue(users[i].email)
            } 
        }
        setValueLevel(e.level)
    }

    const saveUser = ()=> {
        console.log(valueLevel)
        console.log(displayName.userId)

        if(valueLevel  && displayName.userId){
            clientProfileServices.statementApproverSave( {"level" : valueLevel ,"statementApproverId" :displayName.statementApproverId, "userId" : displayName.userId})
           
            clientProfileServices.statementApprover({"pageNumber": 1 ,"pageSize": 10}).then((res) => {
                initstatementApprover(res)
                setTotalRows(res[0].totalRows)
            })
            setsaveSus(true)
        }else{
            setsaveAlert(true)
        }

        setTimeout(function(){
            setsaveAlert(false)
            setsaveSus(false)
        }, 3000);

    }

    return (
    <div className="box box-add-region" >
    <div className="box-header well">
        <h2><i className="icon-file-add pt pr"></i> Statement Approval </h2>
    </div>
    <div className="box-content form-box">
        <div className="form-horizontal form-condensed">
            {saveAlert && <div className="alert alert-danger">There was a problem submitting your request, one or more fields are missing or incorrect.</div>  }
            {saveSus && <div className="alert alert-danger">saved successfully.</div>  }
            <div className="row-fluid form-condensed">

                <div className="form-format ws-4 m0 mt">
                    <label className="control-label ws-12 text-bold">User*</label>
                    <div className="label-entries ">
                        <select value={value} onChange={(e:any)=>  {setValue(e.target.value) ; console.log(e.target.key)}}>
                            <option value="">Choose User</option>
                            {
                                users.map( (values :any) => (
                                    <option value={values.email} key={values.userId}>{values.email}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className="form-format ws-3 m0 mt">
                    <label className="control-label text-bold">Level*</label>
                    <div className="label-entries">
                        <input type="number" placeholder="Enter Level" min="0" name="level" value={valueLevel} onChange={(e:any) => {setValueLevel(e.target.value) }}/>                        
                    </div>
                </div>

                <div className="form-format ws-12 m0 mt hide">
                    <label className="control-label"></label>
                    <div className="label-entries">
                        <button className="btn btn-success btn-sm" ><i className="icon-plus1"></i> Add level</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div className="box-footer pull-right">
        <button className="btn btn-success btn-sm" onClick={saveUser}> Save </button>
        <button className="btn btn-pink btn-sm " onClick={() => {Api() ; setValue("") ; setValueLevel("")}}><i className="icon-reload"></i> Reset</button>

    </div>

    <div className="">
        <div className="box-header well">
            <h2><i className="icon-info pt"></i> {totalRows} record(s) found </h2>
        </div>

        <div className="box-content">
            
            <div className="table-responsive">
                {(totalRows > 0) && <table className="table">
                    <thead>
                    <tr>
                        <th>User</th>
                        <th>Level</th>
                        <th>Operation</th>
                    </tr>
                    </thead>
                    <tbody>

                        {
                            statementApprover.map( (value) => {
                                return (
                                    <tr>
                                        <td >{value.name}</td>
                                        <td >{value.level}</td>
                                        <td className="text-center"><a className="btn btn-small btn-success" onClick={() => editUser(value)}><i className="icon-edit"></i></a></td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>}
            </div>
           <div className="clearfix">
            
                {     
                    (() => {
                        if(totalRows>1 && totalRows>10){
                            return <Pagination onChange={handleNameOnChange} totalRows={totalRows} />
                        }
                                                        
                    })()
                }
            </div>
        </div>
    </div>
</div>


    )
}
import React, { useEffect, useState } from "react";
import CommonSearchSlider from "./commonSearchSlider";


export default function CommonSearch(param: any) {

    var [commonSearchSliderOpen, setcommonSearchSliderOpen] = React.useState(false);
    const [SliderField, setSliderField] = React.useState<any[]>([]);
    let [conAdditional, setcontractAdditional] = useState<any>([]);
    var [searchFor, setsearchFor] = React.useState("");

    //console.log(param)
    var pickerColors = [

        '#EF534F', '#C62928', '#980A0B',

        '#BA69C8', '#7B1FA2', '#4A148C',

        '#F57C02', '#E65100',

        '#81C784', '#2F7D31', '#1A5E20',

        '#7FDEEA', '#00838F', '#004D41',

        '#90CAF9', '#029BE5', '#01579B',

        '#DBDBDB', '#000000'

    ];

    const closeSlider = (e: any) => {

        //console.log(e)
        setcommonSearchSliderOpen(false)
    }
    const onchangeTag = (e: any) => {
        //console.log(e)
        setSliderField(e.SliderField)
        setcontractAdditional(e.conAdditional)
    }

    let [temp, settemp] = useState<any>([]);
    const removeTag = (fieldName: any, value: any) => {

        //var temp : any;
        Object.keys(conAdditional).map((key, index) => {

            for (var i = 0; i < conAdditional[key].length; i++) {

                if (conAdditional[key][i] == value) {

                    setcontractAdditional((prevUser: any) => ({
                        ...prevUser,
                        [fieldName]: conAdditional[fieldName].filter((hobby: any) => hobby !== value),
                        //settemp(prevUser)
                    }));
                    console.log(temp)
                    // param.onchangeTag( {SliderField , conAdditional})
                }
            }
        })
    }


    const [loopSearch , setLoopSearch] = useState(0)
    const searchForTag = (e: any) => {

        Object.keys(conAdditional).map(key => {

            if (key == 'searchFor' && searchFor.length > 0) {
                var valueTest = false;
                Object.keys(conAdditional['searchFor']).forEach(function (key) {
                    if (conAdditional['searchFor'][key] == searchFor) {
                        valueTest = true
                    }
                }) 

                if (valueTest == false) {
                    setcontractAdditional((values: any) => ({ ...values, ...{ 'searchFor': [...conAdditional['searchFor'], searchFor] } }));
                    //param.SearchForHandle(conAdditional, true);
                }

                //param.SearchForHandle(conAdditional, true);
            } else if (searchFor.length > 0) {
                setcontractAdditional({ ...conAdditional, ...{ 'searchFor': searchFor } })
                //param.SearchForHandle(conAdditional, true);
            }

        })

        if (conAdditional.length == 0 && searchFor.length > 0) {
            setcontractAdditional({ ...conAdditional, ...{ 'searchFor': [searchFor] } });
           // param.SearchForHandle(conAdditional, true);
        }
        console.log(conAdditional)


        //setcontractAdditional(conAdditional)
        setLoopSearch(loopSearch+1)
    }

    useEffect(() => {
        // This effect will run whenever conAdditional changes
        console.log('State changed:', conAdditional);
        param.SearchForHandle(conAdditional, true);
    }, [conAdditional,loopSearch]);


    const onchangeSearch = (e: any) => {
        console.log(e)
        if (e && e == true) {
            param.SearchForHandle(conAdditional, true);
        }
    }

    return (

        <>

            <div className={"mtl " + param.width} >
                <div className="ws-9 pr">
                    <button className="btn-purple pLarge m0 ws-2" onClick={searchForTag}>
                        Search
                    </button>
                    <input type="text" value={searchFor} onChange={(e) => setsearchFor(e.target.value)}
                        className="pLarge ws-10 m0 heightReset" placeholder="Type to search" />
                </div>

                <div className="ws-3">
                    <button className="btn-purple pLarge m0 ws-12" onClick={() => { setcommonSearchSliderOpen(true); console.log(commonSearchSliderOpen) }}>
                        Advanced Search <i className="icon-params1 pull-right"></i>
                    </button>
                </div>



                {
                    (() => {

                        return SliderField.map((value: any) => {
                            return (

                                Object.keys(conAdditional).map((key: any, index: any) => {

                                    if (key == value.filterName && conAdditional[key].length > 0) {

                                        return (
                                            <div className="tagging-container" style={{ color: pickerColors[index] }} >
                                                <div className="tagging">
                                                    <div className="pull-left pts mrs" style={{ color: pickerColors[index] }}>{value.displayName}:</div>

                                                    {(() => {

                                                        return (
                                                            (conAdditional[key]).map((value: any) => {
                                                                return (<>
                                                                    <span className="mrs" style={{ background: pickerColors[index] }} > {value}
                                                                        <i className="icon-cancel2 pls pull-right" onClick={() => removeTag(key, value)}></i>
                                                                    </span>
                                                                </>)
                                                            }))
                                                    })()
                                                    }
                                                </div>

                                            </div>

                                        )

                                    }

                                })
                            )
                        })

                    })()
                }


                {
                    Object.keys(conAdditional).map((key: any, index: any) => {

                        if (key == 'searchFor' && conAdditional["searchFor"].length > 0) {

                            return (
                                <div className="tagging-container" style={{ color: pickerColors[index] }} >
                                    <div className="tagging">
                                        <div className="pull-left pts mrs" style={{ color: pickerColors[index] }}>{'SearchFor'}:</div>

                                        {(() => {

                                            return (
                                                (conAdditional[key]).map((value: any) => {
                                                    return (<>
                                                        <span className="mrs" style={{ background: pickerColors[index] }} > {value}
                                                            <i className="icon-cancel2 pls pull-right" onClick={() => removeTag(key, value)}></i>
                                                        </span>
                                                    </>)
                                                }))
                                        })()
                                        }
                                    </div>

                                </div>

                            )

                        }

                    })
                }

            </div>

            {
                (() => {
                    if (commonSearchSliderOpen) {
                        return <CommonSearchSlider open={commonSearchSliderOpen} SliderField={SliderField} conAdditional={conAdditional}
                            onchange={closeSlider} onchangeTag={onchangeTag} onchangeSearch={onchangeSearch} type={param.type} title={param.title} />
                    }
                })()

            }

        </>

    )

}
import { CommonUtilities }  from '../../commonUtils';

export default class ContainerServices  {

    static globalSearch(param: any) {
        var url = 'metadata/globalSearch/list?'+ CommonUtilities.buildQueryString(param, true, true);
        return CommonUtilities.getData(url);
    }





    //https://qa.onkorrect.com/Api/contracts/freeText/?formArea=ShortLabelCode
    static freeText(data :any) {
        var url = 'contracts/freeText/?'+ CommonUtilities.buildQueryString(data , true , true);
        return CommonUtilities.getData(url);
    }

    static freeTextSave(data :any) {
        var url = 'contracts/freeText';
        return CommonUtilities.postData(url , data);
    }

    static freeTextDelete(data :any) {
        var url = 'contracts/freeText/'+data;
        return CommonUtilities.deleteData(url);
    }

    //https://qa.onkorrect.com/Api/Contract/kontactImprintType/save
    static kontactImprintTypeSave(data :any) {
        var url = 'Contract/kontactImprintType/save';
        return CommonUtilities.postData(url , data);
    }
}
import { CommonUtilities } from '../../commonUtils';
import React, { useState } from 'react';

export default function Dashboard (param:any) {
    var userAccessContents = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string);

    const setVal = (e:any) => {
        param.onChangePage(e);
    }


    const SubEntities = (SubEntities : any) => {
        return (
            <div className='ws-12 mbl'>
                {SubEntities.subEntities.map(function(subEntity : any, index : number){
    
                    if(subEntity.subEntityType ==1){
                        return (
                            CommonUtilities.portalSelection(subEntity)
                        );
                    }else if(!subEntity.subEntityType && subEntity.subEntityType !=1){
                        return (
                            <div className="box-primary text-center iconLogo pull-left" key={ index }>
                                {<a href={CommonUtilities.iconUrlAssignment(subEntity.subEntity)?CommonUtilities.iconUrlAssignment(subEntity.subEntity) : "" } >
                                    <div className="ws-12" onClick={()=>setVal(subEntity.subEntity)}>
                                        <i className={'box icon-' + subEntity.subEntityCode} />
                                        <span className="m0 pts" >{subEntity.subEntity} </span>
                                    </div>
                                </a>}
                            </div>
                        );
                    }
                })}
            </div>
        )
    }

    return (
            <div className='ws-12'>
                {userAccessContents.userEntityRight?.map(function(userEntityRight : any, index : number){
                    return (
                    <div className='box mb'>
                        <div className='box-header bg-level0'>
                            {userEntityRight.entityName}
                        </div>
                        <div className='box-content'>
                            <SubEntities subEntities={userEntityRight.subEntities}/>
                        </div>
                    </div>
                    );
                })}    

                <div className={(( JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string).email == 'andy@kartelsolutions.com') || ( JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string).email == 'prithivi@kartelsolutions.com' )) ? 'box mb' : 'hide'}>
                    <div className='box-header bg-level0'>
                        Migration
                    </div>
                    <div className='box-content'>
                        <SubEntities subEntities={[
                            {subEntityID: 32, subEntityCode: "Portal_Directories", subEntity: "Directories", subEntityRef: 111,entityType:4,isActive:1,subEntityType:1},
                            {subEntityID: 32, subEntityCode: "download", subEntity: "Uploader", subEntityRef: 111,entityType:1,isActive:1,subEntityType:1},
                            {subEntityID: 32, subEntityCode: "TrackSplit", subEntity: "TrackSplit", subEntityRef: 111,entityType:128,isActive:1,subEntityType:1}]}/>
                    </div>
                </div>

            </div>      
        );
}

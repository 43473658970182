import React, { useState, useEffect } from 'react';
import commonServices from "./commonServices"
import { CommonUtilities } from "../../commonUtils";
import MonitoringSlider from "./monitoringSlider";

export default function Monitoring() {


    let [monitoringListing, setmonitoringListing] = useState<any>([]);
    let [execListing, setexecListing] = useState<any>([]);
    const [MainButton, setMainButton] = useState('Contract');
    const [SubButton, setSubButton] = useState('Basic');
    const [hideLayout, sethideLayout] = useState(false);
    const [exceLayout, setexceLayout] = useState(false);
    const [excutionStatus, setexcutionStatus] = useState(false);
    const [sqlExec, setsqlExec] = useState('');
    const [exportName, setexportName] = useState('monitoring');
    const substringsToCheck = ['drop', 'insert', 'delete' , 'update'];

    const monitoringList = (type: any, forE: any) => {
        sethideLayout(true)
        setexceLayout(false)
        commonServices.MonitorListing({ MonitoringType: type, MonitoringFor: forE }).then((values) => {
            setmonitoringListing(CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
            console.log(CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
        })
    }

    let [execHeader, setexecHeader] = useState<any>([]);
    const executionListing = (forE: any) => {        
        const sqlExecLower = sqlExec.toLowerCase();

        if (substringsToCheck.some(substring => sqlExecLower.includes(substring))) {
            console.log("error");
            setexcutionStatus(true);
            
        }else {
            setexcutionStatus(false);
            commonServices.MonitorListing({ MonitoringType: 'Execution', Execution: sqlExec }).then((values) => {
                setexecListing(CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
                execHeader = [];
                Object.entries(values.headers.original).forEach(([key, value]) => { execHeader.push(value) });
                setexecHeader(execHeader)
                //setexecHeader(CommonUtilities.relativeDataRemapping(values.headers.original, values.headers.original) )
                //console.log(CommonUtilities.relativeDataRemapping(values.headers.fieldType, values.headers.original))
            })
            setexceLayout(true)
            sethideLayout(false)
        }        

        setTimeout(function(){
            setexcutionStatus(false);
        }, 3000);
    }


    const executionListingExport = (forE: any) => {

        const sqlExecLower = sqlExec.toLowerCase();
        if (substringsToCheck.some(substring => sqlExecLower.includes(substring))) {
            console.log("error");
            setexcutionStatus(true);
        }else {
            console.log(sqlExec)
            commonServices.MonitorListingExport({ MonitoringType: 'Execution', Execution: sqlExec }, exportName ? exportName : '').then((values) => { })

        }

        setTimeout(function(){
            setexcutionStatus(false);
        }, 3000);
    }

    const [openDetail, setOpenDetail] = useState(false)
    const [errorName, seterrorName] = useState('')

    const CloseDetail = (event: any) => {
        console.log(event)
        setOpenDetail(!event)
    }


    return (<>

        <div className="box-header  noBorderRadius bg-blue">
            <h1 style={{ textAlign: "center", color: "White" }}>Monitoring</h1>
        </div>
        <div className="box-content">
            <div className="pLarge box">
                <div className={MainButton == 'Contract' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Contract'); setSubButton('Basic'); monitoringList('Contract', 'Basic'); }}> Contract</div>
                <div className={MainButton == 'Project' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Project'); setSubButton('Basic'); monitoringList('Project', 'Basic'); }}> Project</div>
                <div className={MainButton == 'Contact' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Contact'); setSubButton('Basic'); monitoringList('Contact', 'Basic'); }}> Contact</div>
                <div className={MainButton == 'Release' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Release'); setSubButton('Basic'); monitoringList('Release', 'Basic'); }}> Release</div>
                <div className={MainButton == 'Track' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Track'); setSubButton('Basic'); monitoringList('Track', 'Basic'); }}> Tracks</div>
                <div className={MainButton == 'Song' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Song'); setSubButton('Basic'); monitoringList('Song', 'Basic'); }}> Song</div>
                <div className={MainButton == 'Transaction' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Transaction'); setSubButton('Basic'); monitoringList('Transaction', 'Basic'); }}> Transaction</div>
                <div className={MainButton == 'Report' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Report'); setSubButton('Basic'); monitoringList('Report', 'Basic'); }}> Report</div>
            </div>

            <div className="pLarge box ptl mtl">
                {hideLayout && <div className="ws-9 pull-left box pll">
                    <div className="pLarge ws-12">
                        <div className={SubButton == 'Basic' ? "btn-innactive" : "btn-purple"} onClick={() => { setSubButton('Basic'); monitoringList(MainButton, 'Basic'); }} > Basic</div>
                        <div className={SubButton == 'Advance' ? "btn-innactive" : "btn-purple"} onClick={() => { setSubButton('Advance'); monitoringList(MainButton, 'Advance'); }}> Advance</div>
                    </div>

                    <div className="table-responsive mrl">
                        <table className="table mbxl">
                            <tbody>
                                {
                                    monitoringListing.map((values: any) => {
                                        return (
                                            <>
                                                <tr   >
                                                    <th style={{ width: "25%" }} className="text-right ">{values.error}</th>
                                                    <td style={{ width: "25%" }} >
                                                        <div >
                                                            <span style={{ color: values.count > 0 ? "red" : "black" }}
                                                                onClick={() => { seterrorName(values.error); setOpenDetail(true) }}>{values.count}</span>
                                                        </div>
                                                    </td>
                                                </tr></>
                                        )

                                    })
                                }
                            </tbody></table>
                    </div>

                </div>}

                {exceLayout && <div className="ws-9 pull-left box pll">

                    <div className="table-responsive mrl allowVscroll">

                        <table className="table mbxl mtl">
                            <tr>
                                {
                                    execHeader.map((values: any, index: any) => { return (<> <th> {values} </th> </>) })
                                }
                            </tr>

                            {
                                execListing.map((value: any) => {
                                    return (<>
                                        <tr>
                                            {
                                                execHeader.map((Hvalues: any, index: any) => {
                                                    return (<> <td> {value[Hvalues]} </td> </>)
                                                })

                                            }

                                        </tr>
                                    </>)
                                })
                            }
                        </table>
                    </div>
                </div>}

                <div className="ws-3 pull-right">

                    {excutionStatus && <div className="alert alert-success" > Avoid having 'drop', 'insert', 'delete' , 'update' in Excution </div>}
                    <div className="box mbl pbl mll ws-12">
                        <div className="mtl prl  pll mbl ws-12">
                            <textarea placeholder="Enter SQL" id="NoteDetails" className='mrl' style={{ width: "100%", height: "200px" }}
                                onChange={(e) => setsqlExec(e.target.value)}></textarea></div>
                        <div className="ws-12">
                            <div className="btn-innactive mll ws-3" onClick={() => executionListing(sqlExec)}>Execute</div>
                            <div className="ws-5"> <input style={{ width: "100%" }} onChange={e => { setexportName(e.target.value) }} value={exportName} placeholder={'Export FileName'} ></input></div>
                            <div className="btn-innactive mll  ws-3" onClick={() => executionListingExport(sqlExec)}>Export</div>
                        </div>

                    </div>

                </div>
            </div >

        </div >



        {
            (() => {
                if (openDetail) {
                    return <MonitoringSlider errorName={errorName} openDetail={openDetail} MainButton={MainButton} CloseDetail={CloseDetail} />;
                }

            })()
        }


    </>)

}
import React, { useState, useEffect } from 'react';
import { CommonUtilities } from '../../commonUtils';
import Modal from 'react-responsive-modal';
import Header from '../Header/header';
import UploadTemplate from './uploadTemplate';

export default function DataLoader(subEntity: any) {

    let [activeTab, setactiveTab] = useState("Contacts");
    const [activeSubTab, setactiveSubTab] = useState("");
    const menu = [
        { label: 'Menu', value: 'Contacts' },
        { label: 'Menu', value: 'Contracts' },
        { label: 'Menu', value: 'Contract Links' },
        { label: 'Menu', value: 'Upload Mechanicals' },
        { label: 'Menu', value: 'Mappings' },
        { label: 'Menu', value: 'Data Transfer' },
        { label: 'Menu', value: 'Bulk Update' }
    ];

    useEffect(() => {
        if(activeTab == "Contacts"){ setactiveSubTab("BasicContactDetails") }

        if(activeTab == "Contracts"){ setactiveSubTab("rateBasis") }

        if(activeTab == "Contract Links"){ setactiveSubTab("") }

        if(activeTab == "Upload Mechanicals"){ setactiveSubTab("mechanicalContract") }

        if(activeTab == "Mappings"){ setactiveSubTab("mappingTerritory") }

        if(activeTab == "Data Transfer"){ setactiveSubTab("Artist Contracts") }

        if(activeTab == "Bulk Update"){ setactiveSubTab("") }
    }, [activeTab]);


    return (

        <>       
         <div>
            {(subEntity && !subEntity.subEntity) && <div>

                <div className="pull-left 12">

                    <div className='ws-12 bgWhite'> <Header  /> </div>

                    <div className="box">
                        <div className="box-header noBorderRadius bg-blue text-bold"><h2 className="bg-blue ws-8">Data Loader</h2></div>
                    </div>

                    <div className="ws-2 pr pm">
                        <div className="main-menu-span m0 box">
                            <div className="sidebar-nav">
                                <ul className="nav nav-tabs full-width" id="mainMenu">
                                {
                                    menu.map((values:any)=>{

                                        if(values.label == 'Menu')
                                        {
                                            return (<>
                                                <li className={activeTab==values.value?  "active pull-left ws-12 mb hidden-tablet": "pull-left ws-12 mb hidden-tablet" } 
                                                    onClick={()=>{activeTab= values.value ;setactiveTab(activeTab)}}> 
                                                    <a className="ws-12" > {values.value}  </a> 
                                                </li>
                                            </>)
                                        }
                                    })
                                }
                                </ul>
                            </div>

                        </div>

                    </div>

                    <div className="ws-10 pr pm">

                        {(activeTab == "Contacts" || activeTab == "Contracts" || activeTab == "Upload Mechanicals" || activeTab == "Mappings" || activeTab == "Data Transfer") && <div className='box'>
                            <ul className="nav nav-tabs nav-justified">
                                {(activeTab == "Contacts") && <li onClick={()=>{setactiveSubTab("BasicContactDetails");}}           className={activeSubTab == "BasicContactDetails" ? "active" : ""}><a>Basic Contact Details</a></li>}
                                {(activeTab == "Contacts") && <li onClick={()=>{setactiveSubTab("AdditionalContactRecipients");}}   className={activeSubTab == "AdditionalContactRecipients" ? "active" : ""}><a>Contact Additional recipients</a></li>}
                                {(activeTab == "Contacts") && <li onClick={()=>{setactiveSubTab("ContactStatuses");}}               className={activeSubTab == "ContactStatuses" ? "active" : ""}><a>Contact Statuses</a></li>}
                            
                        
                                {(activeTab == "Contracts") && <li onClick={()=>{setactiveSubTab("rateBasis");}}             className={activeSubTab == "rateBasis" ? "active" : ""}><a>Rates/Basis</a></li>}
                                {(activeTab == "Contracts") && <li onClick={()=>{setactiveSubTab("regions");}}               className={activeSubTab == "regions" ? "active" : ""}><a>Regions</a></li>}
                                {(activeTab == "Contracts") && <li onClick={()=>{setactiveSubTab("adjustments");}}           className={activeSubTab == "adjustments" ? "active" : ""}><a>Adjustments</a></li>}
                                {(activeTab == "Contracts") && <li onClick={()=>{setactiveSubTab("reserves");}}              className={activeSubTab == "reserves" ? "active" : ""}><a>Reserves</a></li>}
                                {(activeTab == "Contracts") && <li onClick={()=>{setactiveSubTab("reducingRoyalties");}}     className={activeSubTab == "reducingRoyalties" ? "active" : ""}><a>Reducing Royalties</a></li>}
                                {(activeTab == "Contracts") && <li onClick={()=>{setactiveSubTab("contractStatus");}}        className={activeSubTab == "contractStatus" ? "active" : ""}><a>Contract Statuses</a></li>}
                                {(activeTab == "Contracts") && <li onClick={()=>{setactiveSubTab("contractGrouping");}}      className={activeSubTab == "contractGrouping" ? "active" : ""}><a>Contract Grouping</a></li>} 

                                 
                                {(activeTab == "Upload Mechanicals") && <li onClick={()=>{setactiveSubTab("mechanicalContract");}}     className={activeSubTab == "mechanicalContract" ? "active" : ""}><a>Contracts</a></li>}
                                {(activeTab == "Upload Mechanicals") && <li onClick={()=>{setactiveSubTab("mechanicalTrackSplits");}}  className={activeSubTab == "mechanicalTrackSplits" ? "active" : ""}><a>Track Splits</a></li>}
                                {(activeTab == "Upload Mechanicals") && <li onClick={()=>{setactiveSubTab("mechanicalLicenses");}}      className={activeSubTab == "mechanicalLicenses" ? "active" : ""}><a>Licenses</a></li>}

                                {(activeTab == "Mappings") && <li onClick={()=>{setactiveSubTab("mappingTerritory");}}          className={activeSubTab == "mappingTerritory" ? "active" : ""}><a>Territories</a></li>}
                                {(activeTab == "Mappings") && <li onClick={()=>{setactiveSubTab("mappingFormat");}}             className={activeSubTab == "mappingFormat" ? "active" : ""}><a>Formats/Source Codes </a></li>}
                                {(activeTab == "Mappings") && <li onClick={()=>{setactiveSubTab("mappingCatalogue");}}          className={activeSubTab == "mappingCatalogue" ? "active" : ""}><a>Catalogs</a></li>}
                                {(activeTab == "Mappings") && <li onClick={()=>{setactiveSubTab("mappingRaw");}}                className={activeSubTab == "mappingRaw" ? "active" : ""}><a>Raw</a></li>}
                                {(activeTab == "Mappings") && <li onClick={()=>{setactiveSubTab("mappingAudit");}}              className={activeSubTab == "mappingAudit" ? "active" : ""}><a>Audit </a></li>}
                                {(activeTab == "Mappings") && <li onClick={()=>{setactiveSubTab("mappingRawPub");}}             className={activeSubTab == "mappingRawPub" ? "active" : ""}><a>Raw Pub</a></li>}
                                {(activeTab == "Mappings") && <li onClick={()=>{setactiveSubTab("mappingAuditPub");}}           className={activeSubTab == "mappingAuditPub" ? "active" : ""}><a>Audit Pub</a></li>}
                                {(activeTab == "Mappings") && <li onClick={()=>{setactiveSubTab("mappingAnalysisCode");}}       className={activeSubTab == "mappingAnalysisCode" ? "active" : ""}><a>Analysis Codes </a></li>}
                                {(activeTab == "Mappings") && <li onClick={()=>{setactiveSubTab("mappingClassList.excel");}}    className={activeSubTab == "mappingClassList.excel" ? "active" : ""}><a>Class List</a></li>}
                                {(activeTab == "Mappings") && <li onClick={()=>{setactiveSubTab("mappingInvoice");}}            className={activeSubTab == "mappingInvoice" ? "active" : ""}><a>Invoice</a></li>}
                                {(activeTab == "Mappings") && <li onClick={()=>{setactiveSubTab("mappingSong");}}               className={activeSubTab == "mappingSong" ? "active" : ""}><a>Songs</a></li>}

                                {(activeTab == "Data Transfer") && <li onClick={()=>{setactiveSubTab("Artist Contracts");}}             className={activeSubTab == "Artist Contracts" ? "active" : ""}><a>Artist Contracts</a></li>}
                                {(activeTab == "Data Transfer") && <li onClick={()=>{setactiveSubTab("Artist Payees");}}                className={activeSubTab == "Artist Payees" ? "active" : ""}><a>Artist Payees</a></li>}
                                {(activeTab == "Data Transfer") && <li onClick={()=>{setactiveSubTab("Transactions License");}}         className={activeSubTab == "Transactions License" ? "active" : ""}><a>Transactions License</a></li>}
                                {(activeTab == "Data Transfer") && <li onClick={()=>{setactiveSubTab("Transactions Publisher");}}       className={activeSubTab == "Transactions Publisher" ? "active" : ""}><a>Transactions Publisher</a></li>}
                                
                            </ul>
                        </div>}

                        <UploadTemplate type={activeTab} subType={activeSubTab}/>
                    </div>                    

                </div>
            </div>}            
         </div>
        </>
    )
}
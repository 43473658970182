import { CommonUtilities }  from '../../commonUtils';

export default class PeriodService  {

    static PeriodListing(Data:{}) {
        
         var url = 'common/periodCount?accountingPeriod=1&all=1&userEmail=' + Data;
         return CommonUtilities.getData(url);
    }

     static PeriodCount() {
        
        var url = 'common/periodCount';
        return CommonUtilities.getData(url);
    }

    static PeriodListingSelection(Data:{}) {
        
        var url = 'common/periodCount?accountingPeriod=' + Data;
        return CommonUtilities.getData(url);
   }
}
import React, { useEffect } from 'react';
import Navbar from './navbar';
import korrectLogo from "../../assets/images/korrectLogo.png";

export default function Header(param: any) {

    return (
        <div className='ws-12'>
            <div className='ws-12' >
                <a className='mtl mll pull-left' href='../../'><img src={korrectLogo} height='49px' /></a>
                <img className='mtl pull-left' src={global.portalLogo ? global.portalLogo : '/assets/images/logo.png'} height='49px' />

                <Navbar />

            </div>

            {(param.Title) && <div className='box'>
                <div className='box-header-main'>
                    <h2 className='ws-8'>{param.Title}</h2>

                    
                {(param.Title == 'Contacts' || param.Title == 'Contracts' || param.Title == 'Projects') &&   <div className='pull-right'>

                    {(param.Title != 'Projects') && 
                        <a href="../../apps/contracts/#/projectContracts" className="btn-info btn-s" >Projects <i className="icon-Grid_ProjectContract"/> 
                    </a>}                  

                    {(param.Title != 'Contracts') && 
                        <a href="../../apps/contracts/#/projectContracts" className="btn-info btn-s" >Contracts <i className="icon-Grid_Contracts"/>
                    </a>}

                    {(param.Title != 'Contacts') && 
                        <a href="../../apps/contracts/#/projectContracts" className="btn-info btn-s"> Contacts <i className="icon-Grid_Contacts"/>
                    </a>}
                </div>                    
                }
                </div>

            </div>}
        </div>
    )


}
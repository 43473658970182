import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import globalSearchService from './globalSearchServices';


export default function CommonSearchSlider(param: any) {

    var [commonSearchSliderOpen, setcommonSearchSliderOpen] = useState(param.open);
    var [lookUpTypeList, setlookUpTypeList] = React.useState<any[]>([]);
    const [SliderField, setSliderField] = React.useState<any[]>([]);
    let [conAdditional, setcontractAdditional] = useState<any>([]);

    var pickerColors = [

        '#EF534F', '#C62928', '#980A0B',

        '#BA69C8', '#7B1FA2', '#4A148C',

        '#F57C02', '#E65100',

        '#81C784', '#2F7D31', '#1A5E20',

        '#7FDEEA', '#00838F', '#004D41',

        '#90CAF9', '#029BE5', '#01579B',

        '#DBDBDB', '#000000'

    ];

    useEffect(() => {

        //console.log(param)

        lookUpTypeList = global.lookUpTypeList
        setlookUpTypeList(lookUpTypeList);

        if (param.conAdditional) {
            conAdditional = param.conAdditional
            setcontractAdditional(conAdditional)
        }

        if (param.open) {
            globalSearchService.globalGrid(param.type)
                .then((res) => {
                    setSliderField(res);
                })
        }

    }, []);

    const closePage = () => {

        setcommonSearchSliderOpen(false);
        param.onchange(false)
        //return false;
    }

    const formHandler = (event: any) => {

        const field_name = event.target.name; let field_value = event.target.value
        console.log(event)
        console.log(field_name)
        console.log(field_value)

        if (event.target.type == "checkbox") {
            field_value = (event.target.value == 'on' ? 1 : 0);
        }

        var varLen = field_value.length;
        console.log(typeof field_value)

        if (typeof field_value == 'number') {
            varLen = field_value.toString().length
            ///console.log(conAdditional.hasOwnProperty('PublisherContractType'));

            console.log("enter")
            console.log(conAdditional.hasOwnProperty(field_name));
            if (conAdditional.hasOwnProperty(field_name)) {
                console.log(conAdditional[field_name])
                var temp = conAdditional[field_name]
                delete conAdditional[field_name]
                console.log(conAdditional);
                if (temp == 1) {
                    field_value = 0;
                } else {
                    field_value = 1;
                }

            } else {
                field_value = 0;
            }
        }

        console.log(varLen)
        Object.keys(conAdditional).map(key => {

            if (key == event.target.name && varLen > 0) {

                //console.log(conAdditional[field_name])
                //var valueTest = Object.values(conAdditional[field_name]).includes(field_value);
                var valueTest = false;
                Object.keys(conAdditional[field_name]).forEach(function (key) {
                    if (conAdditional[field_name][key] == field_value) {
                        valueTest = true
                    }
                })
                //console.log(valueTest)

                if (valueTest == false) {
                    setcontractAdditional((values: any) => ({ ...values, ...{ [field_name]: [...conAdditional[field_name], [field_value]] } }));
                }

            } else {
                setcontractAdditional({ ...conAdditional, ...{ [field_name]: [field_value] } })

            }

        })

        if (conAdditional.length == 0 && varLen > 0) {
            setcontractAdditional({ ...conAdditional, ...{ [field_name]: [field_value] } });
        }


        // console.log( conAdditional);
    }

    useEffect(() => {
        // This effect will run whenever conAdditional changes
        //console.log('State changed:', conAdditional);
        param.onchangeTag({ SliderField, conAdditional })
    }, [conAdditional]);


    const removeTag = (fieldName: any, value: any) => {

        Object.keys(conAdditional).map((key, index) => {

            for (var i = 0; i < conAdditional[key].length; i++) {

                if (conAdditional[key][i] == value) {

                    setcontractAdditional((prevUser: any) => ({
                        ...prevUser,
                        [fieldName]: conAdditional[fieldName].filter((hobby: any) => hobby !== value)
                    }));
                    param.onchangeTag({ SliderField, conAdditional })
                }
            }
        })
    }

    const onchangeSearch = () => {

        param.onchangeSearch(conAdditional)
        closePage();
    }

    return (
        <div>

            <Drawer open={param.open}
                onClose={closePage}
                direction='right'
                className='react-drawer-left full-height-vh allowHscroll' style={{ width: "100%"}}>

                <div className="box ws-6 pull-right" style={{ height: "100%"}}>
                    <div className="box-header-main">
                        <h2 className="pull-left"> Search {param.title} </h2>
                    </div>

                    <div className="box-content">

                        {
                            (() => {
                                return (
                                    SliderField.map((value) => {


                                        if (value.fieldType == 'Text' && value.filterName != "ProjectTag") {
                                            return (
                                                <div className='form-format ws-6'>
                                                    <label>{value.displayName}</label>
                                                    <input name={value.filterName} type="text" placeholder={value.displayName} onClick={(e) => formHandler(e)}></input>
                                                </div>
                                            )
                                        }


                                        if (value.fieldType == 'DropDown' && value.filterName != "ProjectTag") {
                                            return (
                                                <div className='form-format ws-6'>
                                                    <label>{value.displayName}</label>

                                                    <div className='label-entries'>
                                                        <select name={value.filterName} onChange={(e) => formHandler(e)}>
                                                            <option value="" >Choose {value.displayName}</option>
                                                            {/**/} {lookUpTypeList.map((lookUpListItem: any) => {

                                                                if (lookUpListItem.lookUpType == value.filterName) {
                                                                    return (
                                                                        <option >{lookUpListItem.value}</option>
                                                                    )
                                                                }
                                                            })}
                                                        </select>
                                                    </div>

                                                </div>
                                            )
                                        }


                                        if (value.fieldType == 'Slider') {
                                            return (
                                                <div className='form-format ws-6'>
                                                    <label>{value.displayName}</label>

                                                    <label className="switch">
                                                        <input type="checkbox" name={value.filterName} checked onChange={(e) => formHandler(e)} />
                                                        {<span className={(conAdditional[value.filterName] == 1 || !(conAdditional[value.filterName])) ? "slider text-left on" : "hide"}> Yes </span>}
                                                        {(conAdditional[value.filterName] == 0) && <span className="slider off text-right prl ">No</span>}
                                                    </label>

                                                </div>
                                            )
                                        }



                                        //return()
                                    }))
                            })()
                        }

                    </div>

                    <div className="box-footer">

                        {
                            SliderField.map((value: any) => {
                                return (

                                    Object.keys(conAdditional).map((key: any, index: any) => {

                                        if (key == value.filterName && conAdditional[key].length > 0) {

                                            return (
                                                <div className="tagging-container" style={{ color: pickerColors[index] }} >
                                                    <div className="tagging">
                                                        <div className="pull-left pts mrs" style={{ color: pickerColors[index] }}>{value.displayName}:</div>

                                                        {(() => {

                                                            return (
                                                                (conAdditional[key]).map((value: any) => {
                                                                    return (<>
                                                                        <span className="mrs" style={{ background: pickerColors[index] }} > {value}
                                                                            <i className="icon-cancel2 pls pull-right" onClick={() => removeTag(key, value)}></i>
                                                                        </span>
                                                                    </>)
                                                                }))
                                                        })()
                                                        }
                                                    </div>

                                                </div>

                                            )

                                        }

                                    })
                                )
                            })
                        }



                        {
                            (() => {
                                if (conAdditional['searchFor']) {
                                    return (
                                        Object.keys(conAdditional).map((key: any, index: any) => {

                                            if (key == 'searchFor' && conAdditional[key].length > 0) {

                                                return (
                                                    <div className="tagging-container" style={{ color: pickerColors[index] }} >
                                                        <div className="tagging">
                                                            <div className="pull-left pts mrs" style={{ color: pickerColors[index] }}>{'SearchFor'}:</div>

                                                            {(() => {

                                                                return (
                                                                    (conAdditional[key]).map((value: any) => {
                                                                        return (<>
                                                                            <span className="mrs" style={{ background: pickerColors[index] }} > {value}
                                                                                <i className="icon-cancel2 pls pull-right" onClick={() => removeTag(key, value)}></i>
                                                                            </span>
                                                                        </>)
                                                                    }))
                                                            })()
                                                            }
                                                        </div>

                                                    </div>

                                                )

                                            }

                                        })
                                    )

                                }

                            })()


                        }

                        <button className="btn-danger pull-right" onClick={closePage}><i className="icon-close" ></i> Close</button>
                        <button className="btn-success pull-right" onClick={onchangeSearch}><i className="icon-search" ></i> Search</button>
                    </div>
                </div>
            </Drawer>

        </div>
    )

}
import React, { Suspense, useState, useEffect, useRef } from "react";
import Pagination from "../../components/Header/Pagination";
import { Modal } from 'react-responsive-modal';
import commonServices from "./commonServices";

export default function MasterReleaseForm(param: any) {

    let [masterReleaseListing, setmasterReleaseListing] = useState<any[]>([]);
    let [masterReleaseTotalRow, setmasterReleaseTotalRow] = useState(0);
    const [searchFor, setsearchFor] = useState("");

    const returnValue = (e: any) => {
        param.closeMasterRelease(e);
    }

    useEffect(() => {
        ApiCall();
    }, [param.open]);

    var payload = {
        pageNumber: 1,
        pageSize: 10,
        IsReleaseCount: 1,
        searchText: ""
    }

    const ApiCall = () => {

        if (searchFor.length > 0) {
            payload.searchText = searchFor;
        }
        commonServices.masterReleaseListing(payload).then((value: any) => {
            setmasterReleaseListing(value)
            setmasterReleaseTotalRow(value[0] ? value[0].totalRows : 0)
        })
    }

    const handleNameOnChange = (n: any) => {
        payload.pageNumber = n.pageNumber;
        payload.pageSize = n.pageSize;
        ApiCall()
    }

    const SelectContactName = (n: any) => {
        console.log(n)
        param.selectedMaster(n);
        returnValue(0)
    }

    return (
        <div style={{ width: "50%", height: "120%" }}>

            <Modal open={param.open} onClose={() => returnValue(0)} >

                <div className="box-header well ws-12">
                    <h2 className="ws-10"><i className="fa fa-question-circle"></i>Search Master Form</h2>
                </div>

                <div className="box-content">
                    <div className="form-horizontal form-condensed clearfix ws-12">
                        <div className="row-fluid">
                            <div className="form-format ws-12">
                                <input type="text" className="form-control pLarge ws-6" placeholder="Enter Filename" value={searchFor}
                                    onChange={(e => setsearchFor(e.target.value))} id="filename" />

                                <div className="ws-1">
                                    <button className="btn btn-success mtl" onClick={ApiCall}><i className="fa fa-search"></i>Search </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row-fluid sortable">
                    <div className="box span12">
                        {<div className="box-header well">
                            <h2> <i className="fa fa-table"></i> <span>{masterReleaseTotalRow} record(s) found</span>  </h2>
                        </div>}
                        <div className="box-content">
                            {(masterReleaseTotalRow == 0) && <div className="alert alert-info"> No records to display</div>}
                            {(masterReleaseTotalRow > 0) && <table className="table">

                                <thead>
                                    <tr>
                                        <th >Code</th>
                                        <th >Title</th>
                                        <th >Operations</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {masterReleaseListing?.map((values) => {
                                        return (
                                            <tr>
                                                <td >{values.masterRelease}</td>
                                                <td >{values.masterTitle}</td>
                                                <td><a onClick={() => SelectContactName(values)} className="btn btn-primary mtl"><i className="icon-tick"></i>Select</a></td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>

                            </table>}


                            {
                                (() => {
                                    if (masterReleaseTotalRow && masterReleaseTotalRow > 10) {
                                        return <Pagination onChange={handleNameOnChange} totalRows={masterReleaseTotalRow} />
                                    }
                                })()
                            }

                        </div>

                    </div>
                </div>

            </Modal>
        </div>
    )
}
import PeriodSelectionForm from "../../../components/PeriodSelection/periodSelectionForm";
import Drawer from 'react-modern-drawer';
import React, { useEffect } from "react";
import { useState } from "react";
import format from "date-fns/format";
import commonServices from "../../../components/Common/commonServices";
import ItemAnalysisFormatsTerritory from "../../../components/Transaction/ItemAnalysisFormatsTerritory";
import ContainerServices from "../../../components/container/containerServices";


export default function ContainerSlider(param: any) {

    const [selectedTransactionType, setSelectedTransactionType] = useState('');
    let [Transaction, setNewTransactionData] = useState<any>([]);
    const [isTransactionSaved, setIsTransactionSaved] = useState(false);

    const projectTransactionType = global.lookUpTypeList.filter((event: any) => event.lookUpType === "ProjectTransactionType");
    var lookUpContainerType = global.lookUpTypeList.filter((event: any) => event.lookUpType === "ContainerType");
    const formHandler = (event: any) => {
        var field_name = event.target.name;
        var field_value = event.target.value;
        setNewTransactionData({ ...Transaction, ...{ [field_name]: field_value } });
    }

    const handleTransactionTypeChange = (event: any) => {
        setSelectedTransactionType(event.target.value);
    };

    const onChange = (period: any) => {
        //console.log(period);
        //console.log(format(new Date(period.periodFrom.month), 'MM dd'));

        if (period.periodFrom) {
            Transaction.periodFrom = Number(period.periodFrom.year + format(new Date(period.periodFrom.month), 'MMdd'));
            setNewTransactionData(Transaction);
        }

        if (period.periodTo) {
            Transaction.periodTo = Number(period.periodTo.year + format(new Date(period.periodTo.month), 'MMdd'));
            setNewTransactionData(Transaction);
        }

        setNewTransactionData({ ...Transaction })
        //console.log(Transaction)
    }

    var listPayload = {
        projectId: "",
        userId: 8039,
        containerArea: "Performance"
    }

    const transactionSave = () => {

        //console.log(lookUpContainerType)
        var payload = { ...{ containerArea: "Performance" }, ...Transaction }
        //console.log(payload)
        setIsTransactionSaved(true);

        commonServices.saveTransaction(payload).then((res) => {

            //second save api call
            /*commonServices.saveProjectMap(projectMapSave).then((res) => {
                //thrid listing api call
                const listingData = {
                    projectId: 246,
                    userId: '',
                    pageNumber: 1,
                    pageSize: 10,
                    excludeProject: 0,
                    currentProject: 1,
                    containerArea: "ProjectMap",
                };

            });*/

            commonServices.getProjectMaps(listPayload).then((res) => {

                setIsTransactionSaved(true);

            })

        });
    };

    let [transSettingListing, setTransSettingListing] = useState<any>([]);
    let [transSettingTotalRow, setTransSettingTotalRow] = useState(0);
    const apiCall = () => {

        commonServices.getContainerDetails(listPayload).then((res) => {
            //console.log(res)

            transSettingListing = res;
            setTransSettingListing(transSettingListing);
            if (res[0] && res[0].totalRows) {
                setTransSettingTotalRow(res[0].totalRows);
                TransSettingListingIndex("");
                setIsTransactionSaved(true);
            }
        });
        //console.log(lookUpContainerType)
    }


    let [currentIndex, setcurrentIndex] = useState(0);
    const TransSettingListingIndex = (e: any) => {
        setNewTransactionData(transSettingListing[0]);

        if (e == "Next" && (currentIndex < (transSettingTotalRow - 1))) {
            currentIndex = currentIndex + 1;
            setcurrentIndex(currentIndex)
            setNewTransactionData(transSettingListing[currentIndex])
        }

        if (e == "Prev" && (currentIndex > 0)) {
            currentIndex = currentIndex - 1;
            setcurrentIndex(currentIndex)
            setNewTransactionData(transSettingListing[currentIndex])
        }

    }

    const [ContainerField, setContainerField] = useState<any>([]);

    useEffect(() => {
        apiCall();

        ContainerServices.globalSearch({ type: "Performance" }).then((res) => {
            setContainerField(res)
        })
    }, [param.open]);

    let [dialogueOpenView, setdialogueOpenView] = useState(false)
    let [containerTypeName, setcontainerTypeName] = useState("")
    const onChangeClose = (e: any) => {
        setdialogueOpenView(false)
    }

    const returnBack = (response: any) => {
        param.onChangeClose("");
    }
    const onLinkContainer = (response: any) => {
        //console.log(response);
    };

    const Existance = (param: any, value: any) => {

        var find = false;
        var finding = param?.filter((event: any) => event.containerType === value);

        if (finding) {
            if (finding.length > 0)
                find = true;

            if (finding.length == 0)
                find = false;
        }


        return find;
    }

    const closePage = () => {
        setpanelOpen(false)
    }
    const [Code, setCode] = useState("");
    const [panelOpen, setpanelOpen] = useState(false);
    const ItemSelected = (value: any) => {

        console.log(Code)
        var korTransContainerSave = {
            assetType: "Code",
            containerCode: value.code,
            containerTypeid: 1,
            transSettingsId: Transaction.transSettingsId,
            transaction_Ref: Transaction.transaction_Ref
        }

        commonServices.linkContainer(korTransContainerSave).then((res: any) => {
            console.log(res)
            if (Code == "AssetCode") {
                Transaction = { ...Transaction, "assetCodeSel": 1, "analysisCodeSel": 0, "formatsSel": 0, "territorySel": 0, "contractRefNumSel": 0, "accountCodeSel": 0, "subProviderSel": 0 }
               // Transaction = { ...Transaction, "assetCode": value.code }
                Transaction.containerSet = {...Transaction.containerSet ,containerCode : value.code , containerType :  "AssetCode" , "transContainersId" :  res.transContainersId}
            }
            if (Code == "AnalysisCode") {
                Transaction = { ...Transaction, "assetCodeSel": 0, "analysisCodeSel": 1, "formatsSel": 0, "territorySel": 0, "contractRefNumSel": 0, "accountCodeSel": 0, "subProviderSel": 0 }
                //Transaction = { ...Transaction, "analysisCode": value.code }
                Transaction.containerSet = {...Transaction.containerSet ,containerCode : value.code , containerType :  "AnalysisCode" , "transContainersId" :  res.transContainersId}
            }
            if (Code == "Formats") {
                Transaction = { ...Transaction, "assetCodeSel": 0, "analysisCodeSel": 0, "formatsSel": 1, "territorySel": 0, "contractRefNumSel": 0, "accountCodeSel": 0, "subProviderSel": 0 }
                //Transaction = { ...Transaction, "formats": value.code }
                Transaction.containerSet = {...Transaction.containerSet ,containerCode : value.code , containerType :  "Formats" , "transContainersId" :  res.transContainersId}
            }
            if (Code == "Territories") {
                Transaction = { ...Transaction, "assetCodeSel": 0, "analysisCodeSel": 0, "formatsSel": 0, "territorySel": 1, "contractRefNumSel": 0, "accountCodeSel": 0, "subProviderSel": 0 }
                //Transaction = { ...Transaction, "territory": value.code }
                Transaction.containerSet = {...Transaction.containerSet ,containerCode : value.code , containerType :  "Territories" , "transContainersId" :  res.transContainersId}
            }
            if (Code == "Contract") {
                Transaction = { ...Transaction, "assetCodeSel": 0, "analysisCodeSel": 0, "formatsSel": 0, "territorySel": 0, "contractRefNumSel": 1, "accountCodeSel": 0, "subProviderSel": 0 }
                //Transaction = { ...Transaction, "contractRefNum": value.code }
                Transaction.containerSet = {...Transaction.containerSet ,containerCode : value.code , containerType :  "Contract" , "transContainersId" :  res.transContainersId}
            }
            if (Code == "AccountCode") {
                Transaction = { ...Transaction, "assetCodeSel": 0, "analysisCodeSel": 0, "formatsSel": 0, "territorySel": 0, "contractRefNumSel": 0, "accountCodeSel": 1, "subProviderSel": 0 }
               // Transaction = { ...Transaction, "accountCode": value.code }
                Transaction.containerSet = {...Transaction.containerSet ,containerCode : value.code , containerType :  "AccountCode" , "transContainersId" :  res.transContainersId}
            }
            if (Code == "SubProvider") {
                Transaction = { ...Transaction, "assetCodeSel": 0, "analysisCodeSel": 0, "formatsSel": 0, "territorySel": 0, "contractRefNumSel": 0, "accountCodeSel": 0, "subProviderSel": 1 }
                //Transaction = { ...Transaction, "subProvider": value.code }
                Transaction.containerSet = {...Transaction.containerSet ,containerCode : value.code , containerType :  "SubProvider" , "transContainersId" :  res.transContainersId}
                
            }

            Transaction = { ...Transaction, "containerType": "Performance" }

            console.log(Transaction)
           /* commonServices.saveTransaction(Transaction).then((data: any) => {

                Transaction = { ...Transaction, ...{ "transaction_Ref": data.transaction_Ref } }
                Transaction = { ...Transaction, ...{ "transSettingsId": data.transSettingsId } }

                console.log(Transaction)
                commonServices.saveProjectMap(Transaction).then((res: any) => {
                    apiCall();
                })

            })*/

        });

    }

    return (
        <>
            <Drawer open={param.open}
                onClose={() => returnBack(false)}
                direction='right'
                className='react-drawer-left full-height-vh allowVscroll '
                style={{ width: "100%" }}>

                <div className="box ws-6 pull-right">

                    <div className="box-header  bg-blue pLarge hide">
                        Add/Edit Transaction
                    </div>

                    <div className="box-header  bg-blue pLarge">
                        <h2>Container Codes - {transSettingListing.length} record(s)</h2>
                    </div>

                    <div className="box-content">

                        <div className="ws-12">
                            <div className="ws-3 mb pull-right">
                                <button className="btn-success pull-right"> Add New </button>
                            </div>

                            <div className="ws-7 mb pull-right" style={{ marginLeft: "16%;" }}>
                                <div className="ws-3">
                                    <button className="btn btn-icon btn-primary pull-left ml" disabled={currentIndex == 0} onClick={() => TransSettingListingIndex("Prev")}>
                                        <i className="icon-arrow-left m0"></i>
                                    </button>
                                    <div className="tooltip hide" style={{ top: "23px", left: "893.903px", display: "block;" }}>
                                        <div className="tooltip-arrow"></div>
                                        <div className="tooltip-inner">Previous</div>
                                    </div>
                                </div>
                                <div className="ws-4 ml mt">
                                    <h2>Prev/Next</h2>
                                </div>
                                <div className="ws-3">
                                    <button className="btn btn-icon btn-primary mr" disabled={currentIndex == (transSettingTotalRow - 1)}
                                        onClick={() => TransSettingListingIndex("Next")}>
                                        <i className="icon-arrow-right m0"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="form-format">
                            <label className="control-label"> Transaction Reference* </label>

                            <div className="label-entries">
                                <input type="text" name="transaction_Ref" placeholder="Transaction Reference" value={Transaction.transaction_Ref} onChange={formHandler} />
                            </div>
                        </div>

                        <div className="form-format ws-5 mtl">
                            <label>Period From</label>
                            <div className="label-entries">
                                <input type="text" className="form-control" placeholder="Period From" value={Transaction.periodFrom} onChange={formHandler} />
                            </div>
                        </div>

                        <div className="form-format ws-5 mtl">
                            <label >Period To</label>
                            <div className="label-entries">
                                <input type="text" className="form-control" placeholder="Period To" value={Transaction.periodTo} onChange={formHandler} />
                            </div>
                        </div>

                        <div className="ws-2" style={{ marginTop: "38px" }}>
                            <PeriodSelectionForm onChange={onChange} />
                        </div>

                        <div className="form-format mtl">
                            <label className="control-label">Transaction Type</label>

                            <div className="label-entries">
                                <select value={selectedTransactionType} onChange={handleTransactionTypeChange}>
                                    <option value="">Choose Transaction Type</option>
                                    {projectTransactionType.map((transaction: any) => (
                                        <option key={transaction.key} value={transaction.key}>
                                            {transaction.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="form-format mtl">
                            <label className="control-label"> Description </label>
                            <div className="label-entries">
                                <textarea placeholder="Description" name="transaction_description" value={Transaction.transaction_description} onChange={formHandler}></textarea>
                            </div>
                        </div>

                        <div className="form-format mtl">
                            <label className="control-label">Percentage</label>

                            <div className="label-entries">
                                <div className="ws-11">
                                    <input type="number" className="text-right" name="trans_Percentage_amount" placeholder="Percentage" value={Transaction.trans_Percentage_amount}
                                        onChange={formHandler} />
                                </div>
                                <div className="ws-1 mt pl"> % </div>
                            </div>
                        </div>


                        {ContainerField?.map((values: any) => {
                            return (
                                <div className="form-format mtl">
                                    <label className="text-left">{values.displayName} </label>
                                    <div className="label-entries ws-12">
                                        <div className="ws-8">

                                            {

                                                Transaction.containerSet?.map((dataSet: any, index: any) => {

                                                    return (
                                                        (() => {
                                                            if ((values.filterName) == (dataSet.containerType)) {

                                                                return (
                                                                    dataSet.containerData?.map((values: any) => {

                                                                        return <>
                                                                            <div className="tagging-container">
                                                                                {<div className="tagging">
                                                                                    <span className="mrs hide" style={{ background: "#C62928" }}> {values.filterName} </span>
                                                                                    <span className="mrs" style={{ background: "#C62928" }}> {values.containerCode} </span>
                                                                                </div>}
                                                                            </div>
                                                                        </>
                                                                    }))
                                                            }
                                                        })()
                                                    )
                                                })
                                            }

                                            {
                                                (() => {

                                                    if (!Existance(Transaction.containerSet, values.filterName)) {
                                                        return <><div className="tagging-container">
                                                            {<div className="tagging"> <span className="mrs" style={{ background: "#C62928" }}> {"All"} </span> </div>}
                                                        </div></>
                                                    }
                                                })()
                                            }
                                        </div>

                                        <div className="ws-3 pull-right">
                                            <button type="button" className="btn-success btn-sm" onClick={() => { setCode(values.filterName); setpanelOpen(true) }}>
                                                <i className="icon-plus1"></i>
                                            </button>

                                            <button type="button" className="btn-danger btn-sm">
                                                <i className="icon-refresh"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )


                        })}
                    </div>
                    <div className="box-footer mtl">
                        <button className="btn-danger pull-right" onClick={() => returnBack(false)}>
                            <i className="icon-close"></i> Close
                        </button>

                        <button className="btn-success pull-right" onClick={transactionSave}>
                            <i className="icon-save"></i> Save
                        </button>
                    </div>

                </div>

            </Drawer >

            {
                (() => {

                    if (Code.length > 0) {
                        return (<ItemAnalysisFormatsTerritory open={panelOpen} type={Code} closePage={closePage} ItemSelected={ItemSelected} />)
                    }
                })()
            }
        </ >
    )
}
import React, { Suspense, useState, useEffect } from "react";
import UserService from './UserService';
import { CommonUtilities } from '../../commonUtils';
import Tooltip from 'rc-tooltip';
import { ResetPassword_Template } from "./ResetPasswordonLoginScreen";
import siteLogo from "assets/images/1.png"

export default class NameForm extends React.Component<{}, {email: string, password : string ,error : boolean, loginResetToggle : string }> {

  constructor(props  : any) {
    super(props);
    this.state = {email: '', password : ''  , error:false, loginResetToggle: '0'};
    //const [showtab , setShowTab] = useState(1);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.errorHandling = this.errorHandling.bind(this);
  }

  loginReset(event : any) {
    console.log(event);
    this.setState({
      ...this.state,
      loginResetToggle: event
    });
    //this.state.loginResetToggle = event;
    //this.showtab = event;
    // className={(showtab == 0) ? 'hide':'paddingCenterBox ws-8'}
  }

  handleChange(event : any) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    });
  }

  async handleSubmit(event : any){
    event.preventDefault();
    UserService.Login(this.state).then( (response) => {
      console.log(response);
      //sessionStorage.setItem('loggedUserRight', JSON.stringify(response));

      if(response && response.token && response.userEntityRight && response.userEntityRight.length > 0){
        console.log('ok');
        CommonUtilities.setAuthToken(response);      
        this.errorHandling (1);
       
      }else{
        console.log('!ok');
        CommonUtilities.clearAuthToken();
      }
    });
  }

  errorHandling (errorValue : any){
    if(errorValue == 1){
      this.setState({
        error: false
      });
    }else if (errorValue == 0){
      this.setState({
        error: true
      });
    }
  }


  render() {
    return (
      <div className='ws-12'>
        <div className="ws-6 verticalCentering full-height zFront positionAbsolute loginForm">
          <div className={ this.state.loginResetToggle == '0' ? 'paddingCenterBox ws-8' : 'hide' }>
            <h1 className="text-center ws-12 mtl">
              Login to your portal
            </h1>
            <div className="box">
              <form className="form-horizontal form-login">
                <div className="box-content mtl">
                   
                  <div className="ws-12 mtl ptl">
                      <input placeholder="Username" type="text" className='ws-12 pull-none' name='email' value={this.state.email} 
                        onKeyDown={(e) => {if(e.code == "Enter"){this.handleChange(e)} }}
                        onChange={this.handleChange} />
                  </div>                       
                  <div className="ws-12 mtl ptl">
                      <span className="ws-12 mb text-bold">Password<span className=''>*</span></span>
                      <input placeholder="Password" type="password" className='ws-12 pull-none' name='password' value={this.state.password}
                        onKeyDown={(e) => {if(e.code == "Enter"){this.handleChange(e)} }}
                        onChange={this.handleChange} />
                  </div>
                  <div className="ws-12 mtl ptl">
                    
                    {this.state.error && <div className='errorMessage'>
                      We were unable to log you in. Please check your details and try again
                    </div>}

                  </div>
                </div>
                <div className="box-footer">

                  <a onClick= {() => this.loginReset('1')} className='text-underline mtl pull-left pt'>Forgotten Password ?</a>
                  <button onClick={this.handleSubmit} className='btn-search pull-right pm plx prx mtl'>
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className={ this.state.loginResetToggle == '1' ? 'paddingCenterBox ws-8' : 'hide' }>
            <ResetPassword_Template/>
            {/* <h1 className="text-center text-xl ws-12 mtl">Reset your password</h1>
              <div className='grey pLarge text-center'>
                Enter your username and we’ll send a password reset link to the recovery email address for this account.
              </div>
              <div className="box">
                  <div className="box-content mtl  ">
                      <form className="form-horizontal form-login">

                          <div className="ws-12 pbl">
                              <span className="ws-12 mb text-bold">Username<span className='turquoise'>*</span></span>
                              <input placeholder="Username" type="text" className='ws-12 pull-none' name='email' value={this.state.email} onChange={this.handleChange} />
                          </div>
                          <div className="ws-12 mtl ptl">

                              <div className='grey pLarge text-left'>
                                Remember your password? Go and <a className="turquoise text-underline mtl pt" onClick= {() => this.loginReset('0')}>sign in</a>
                              </div>

                              <hr className='ws-12 mtl'></hr>

                              <button className='btn-search btn-xxl pull-right plx prx mtl'>
                                Send password reset email
                              </button>
                          </div>
                      </form>
                  </div>

              </div>*/}
          </div> 
        </div>

        {/*<img className='mtl pull-left' src="/assets/images/logo.png?160085660"  height='49px'/>*/}
        <div className='ws-6 loginWelcome pull-right zBack' style={{backgroundImage: 'url(../../../assets/images/loginWelcomeKorrect.png)'}}>
          <div className="verticalCentering positionRelative ws-12 backgroundTransparence pLarge">
            <div className="centerWindow ws-9">
              <img height={65} src="/static/media/korrectLogo.118820e9ab10f0dd0609.png" />
              <img height={65} src="/assets/images/logo.png?160085660"/>
            </div>
            
          </div>
          
        </div>
      </div>
    );
  }
}
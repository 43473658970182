
import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import contactService from './contactService';
import Pagination from '../Header/Pagination';
import { ImprintFom } from '../Imprint/imprintForm';

export function SelectContact (param : any) {
    const [showTab , setShowTab] = useState(1);
    const toggleDrawer = () => {
        setIsOpen(!isOpen) ; 
        param.onChangeContact({} , 'close')
        //param.closeSelection(false)
    }
    const [isOpen, setIsOpen] = React.useState(false);   
    const [emptyContact, setemptyContact] = React.useState(false);  
  
    let [contactList, initcontactList] = useState<any[]>([]);
    let [contactListPag, initcontactListPag] = useState<any[]>([]);
    const [totalRow, inittotalRow] = useState(0);
    const [totalRowImprint, inittotalRowImprint] = useState(0);
    let [imprintList, initimprintList] = useState<any[]>([]);
    //let [search , setSearch] = useState({ "contactNameOrCode": "","digitalOnly":Boolean(null),"creator": true});
    // let [search , setSearch] = useState<any[]>([]);

    let [search , setSearch] = useState("");
    var loop = 1 ;


    const searchField = (e:any) => {
   
    }

    const Search = () => {
        console.log(search)
        if(search.length > 0){
            contactService.SelectContact ({"contactNameOrCode": search , "digitalOnly": "null"}).then((res)=>{
                initcontactList(res)
                initcontactListPag(res)
                inittotalRow(res.length)
                contactList = res;
                contactList = res.slice((1 -1 ) * 10,(10*1 ))   
                initcontactList(contactList)  
            })
        }else {
            setemptyContact(true)
            setTimeout(function(){
                setemptyContact(false)
            }, 5000);
    
        } 
    

    }

    const handleNameOnChange = (n: any) => {

        contactList = contactListPag.slice((n.pageNumber -1 ) * n.pageSize ,(n.pageSize *n.pageNumber  ))   
        initcontactList(contactList)  
    }

    var data ={ 
        "pageSize" : 10 ,
        "pageNumber" : 1
    }
    const handleNameOnChangeImprint = (n: any) => {
        data.pageNumber = n.pageNumber;
        data.pageSize = n.pageSize;
        api() ; 
    }
    const api = () => {

        contactService.kontactCompanyInfo({"imprint": "label","pageNumber": data.pageNumber,"pageSize": data.pageSize}).then((res)=>{
            initimprintList(res); 
            inittotalRowImprint(res[0].totalRows?res[0].totalRows : 0)
        })
    }

    const SelectContactName = (n : any) => {
        console.log(n)
        var tempType = ""
        tempType = showTab==1?"kontact" : "Imprint"
        param.onChangeContact({data:n , type:tempType}, 'close')             

    }
    return (
        <>
            
            <Drawer 
                open={param.open}
                onClose={toggleDrawer}
                direction='right'
                className='react-drawer-left full-height-vh allowHscroll' style={{ width: "100%" }}>

                <div className="ws-8 box pull-right box" style={{ height: "100%" }}>                   

                    <div className="ws-12 ">
                            <ul className="nav nav-tabs pm pl">
                                {( !param.hide && !param.hide== true) && <></>}
                                <li className={showTab==1? "btn btn-l btn-info active" :"btn-white"}onClick={()=>setShowTab(1)}><a>Contact</a></li>
                                <li className={showTab==2? "btn btn-l btn-info active" :"btn-white"} onClick={()=>{setShowTab(2);}}><a>Imprint</a></li>
                                 
                            </ul>
                            <i className="icon-close pull-right pLarge pull-right" onClick={()=>toggleDrawer()}></i> 
                            <div className={showTab==1?"ws-12  pm":"hide"}> 

                                <div className='box'>                           
                                    
                                    <div className="box-header well ps pll ">
                                        <h2 >Search Contact Form</h2>
                                    </div>

                                    <div className="box-content">
                                        { emptyContact && <div className="alert alert-info" >Try entering a Contact Code to search</div>}

                                        <div className="ws-6">

                                            <div className="form-format ws-12">
                                                <label className="control-label " >Contact</label>
                                                <div className="label-entries">
                                                    <input type="text" className="form-control ws-12" name="contactNameOrCode"
                                                    onChange={(e) =>setSearch(e.target.value)} placeholder="Enter Contact Code/Display/Name" id="contact.code"/>
                                                </div>
                                            </div>

                                            <div className="form-format ws-2 mtxl hide">
                                                <label>Creator</label>
                                                <label className="switch">
                                                    <input type="checkbox" name="creator" onChange={(e)=>searchField(e)}/>
                                                        <span className="slider round text-left white pm pts" >Yes</span>
                                                        <span className="slider round text-right white pm pts sliderOff hide">No</span>                    
                                                </label>
                                            </div>

                                            <div className="form-format ws-1 mtxl hide">
                                                <label className="control-label"></label>
                                                 <div className="label-entries">
                                                    <input type="radio" value="null" name="digitalOnly"  onChange={(e)=>searchField(e)}/>All
                                                </div>
                                            </div>

                                            <div className="form-format ws-3 mtxl hide">

                                                <label className="control-label" ></label>
                                                    <div className="label-entries ">
                                                        <input type="radio" value="true" name="digitalOnly" onChange={(e)=>searchField(e)}/>Digital Provider Only
                                                </div>

                                            </div>

                                            <div className="form-format ws-3 mtxl hide">
                                                <label className="control-label"></label>
                                                    <div className="label-entries ">
                                                        <input type="radio" value="false" name="digitalOnly"/>Non digital Provider
                                                </div>

                                            </div>

                                        </div>   

                                        <div className="ws-4 ptxl pull-right">                                 
                                            <a  className="btn btn-small btn-success mr" onClick={Search}>
                                                <i className="icon-search"></i>Search</a>

                                            <a className="btn btn-small btn-success">
                                                <i className="icon-tick"></i> New Contact</a>
                                    
                                        </div>

                                    </div>
                                </div>

                                <div className="box ws-12 mt">
                                        <div className="box-header well">
                                            <h2> <i className="fa fa-table"></i> <span>{totalRow} record(s) found</span>  </h2>
                                        </div>

                                        <div className="alert alert-success  hide" > Contact  has been deleted successfully</div>

                                        <div className="box-content">
                                            
                                            {(totalRow > 0) && <div className="table-responsive">
                                                <table className="box table">
                                                    <thead>
                                                        <tr>
                                                            <th>Contact code</th>
                                                            <th>Contact display</th>
                                                            <th>Contact name</th>
                                                            <th style={{width:200}}>Operation</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            contactList.map((data)=> {
                                                                return(
                                                                <tr >
                                                                    <td> {data.kontactCode} </td>
                                                                    <td> {data.kontactDisplay} </td>
                                                                    <td> {data.kontactName} </td>
                                                                    <td> 
                                                                        <div className='ws-12'>
                                                                            <a  className="btn-xs btn-success ws-2" onClick={()=>SelectContactName(data)}>
                                                                                <i className="icon-tick1"></i></a>

                                                                            <a className="btn-xs btn-success ws-2" >
                                                                                <i className="icon-edit"></i></a>
                                                                        </div>
                                                                        
                                                                    </td>
                                                                </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>}
                                        </div>

                                        {(totalRow == 0)&& <div className="box-footer">
                                            <div className="alert alert-info ws-12 " >No records to display</div>
                                            <button className="btn btn-danger btn-sm pull-right hide "><i className="icon-close"></i>Close </button>
                                        </div>}
                                </div>

                                <div className="ws-12 pt">
                                {     
                                    (() => {
                                        if(totalRow && totalRow > 10){
                                            return <Pagination onChange={handleNameOnChange} totalRows={totalRow} />
                                        }                                                
                                    })()
                                }</div>      

                            </div>

                            <div className={showTab==2?"ws-12 pm":"hide"}> 

                                    <div className='box'>
                                    
                                        <div className="ps box-header">
                                            <h2><i className="icon-info pl pt"></i> Hierarchy of Record Label(s) </h2>
                                        </div>

                                        <div className="pLarge ">

                                            <div className="form-format ws-4 hide">
                                                <div className="verticalMediumWide hover-box text-center pull-left">
                                                    <ul className="hover-block">
                                                        <li className="hover-box text-left"><input type="checkbox"/> Sequence</li>

                                                        <li className="hover-box text-left">
                                                            <input type="checkbox" />Name
                                                        </li>

                                                        <li className="hover-box text-left">
                                                            <input type="checkbox" /> Code
                                                        </li>
                                                    </ul>
                                                    <button className="btn btn-primary btn-sm">
                                                        <i className="icon-search1"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="ws-12">
                                                <div className="form-format ws-4">
                                                    <label className="control-label" >Sequence</label>
                                                    <div className="label-entries">
                                                        <input type="text"  name="sequence" placeholder="Enter Sequence" />

                                                    </div>
                                                </div>
                                                <div className="form-format ws-4">
                                                    <label className="control-label" >Name</label>
                                                    <div className="label-entries">
                                                        <input type="text"  name="companyName" placeholder="Enter Name" />

                                                    </div>
                                                </div>
                                                <div className="form-format ws-4" ng-show="codeR || true">
                                                    <label className="control-label" >Code</label>
                                                    <div className="label-entries">
                                                        <input type="text"  name="code" placeholder="Enter Code" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="pull-right ptl pbl">
                                                <button className="btn btn-success" onClick={api}> Search</button>
                                                <div className='ws-2'><ImprintFom data={param.data}/></div>
                                                
                                            </div>  

                                        </div>

                                    </div>
                                    {(totalRowImprint == 0) && <div className="alert mtl" >No data to display</div>}
                                    {totalRowImprint > 0 && <table className="table box mt pm">
                                        <thead>
                                            <tr>
                                                <th>Sequence</th>
                                                <th>Name</th>
                                                <th>Code</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>


                                            {
                                                imprintList.map((data) => {
                                                    return (
                                                        <tr className="">

                                                            <td className="pLarge ">{data.imprintSequence}</td>
                                                            <td className="pLarge ">{data.companyName}</td>
                                                            <td className="pLarge ">{data.code}</td>
                                                            <td className="pLarge">
                                                                <div>
                                                                    <button type="button" className="btn-success block">{data.step} </button>
                                                                </div>
                                                            </td>
                                                            <td className="pLarge">
                                                                <div className="verticalMediumWide hover-box text-center pull-left">
                                                                    <ul className="hover-block textboxDropdownList">
                                                                        <li><button className="btn btn-primary mbs" onClick={()=>SelectContactName(data)}><i className="icon-tick"></i> Select</button></li>
                                                                        <li className="hover-box">
                                                                            <button className="btn btn-success mbs"><i className="icon-edit1"></i> Edit</button>
                                                                        </li>
                                                                        <li className="hover-box">
                                                                            <button className="btn btn-danger"><i className="icon-close"></i> Delete</button>
                                                                        </li>
                                                                    </ul>
                                                                    <button className="btn-success">
                                                                        <i className="icon-tag2"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr> 
                                                    )
                                                })
                                            }
                                           

                                        </tbody>
                                    </table>}

                                    <div className="box" >
                                    {     
                                        (() => {
                                            if(totalRowImprint && totalRowImprint > 10){
                                                return <Pagination onChange={handleNameOnChangeImprint} totalRows={totalRowImprint} />
                                            }                                                
                                        })()
                                    }  
                                    </div>
                                
                            </div>

                    </div>
                </div>

            </Drawer>
        </>
    )
}